import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import BillingFormModal from "./BillingFormModal";

const RightSidebar = ({ data, onBillingSubmit }) => {
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      const response = await axios.delete(
        `${conf.API_BASE_URL}/billings/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Billing Deleted:", response.data);
      navigate("/setup/billing");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching billing:", error);
      }
    }
  };

  const openBillingModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsBillingModalOpen(true);
  };

  const closeBillingModal = () => {
    setIsBillingModalOpen(false);
  };
  const handleCustomBillingSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeBillingModal();
      onBillingSubmit(response.data); // Pass the new billing to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Billing
          </a>
        </li>
        <li>
          <a href="#" onClick={openBillingModal}>
            Clone Billing
          </a>
        </li>
      </ul>
      <BillingFormModal
        isOpen={isBillingModalOpen}
        onRequestClose={closeBillingModal}
        billing={null} // Pass the project data if needed
        onSubmit={handleCustomBillingSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
