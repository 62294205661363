import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import CloudConfigFormModal from "./CloudConfigFormModal";
import DockerImageFormModal from "../dockerimage/DockerImageFormModal";

const RightSidebar = ({ data, onDockerImageSubmit, onCloudConfigSubmit }) => {
  const [isDockerImageModalOpen, setIsDockerImageModalOpen] = useState(false);
  const [isCloudConfigModalOpen, setIsCloudConfigModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated dockerimages
      const dockerimageResponse = await axios.get(
        `${conf.API_BASE_URL}/search/dockerimage?query=${data.objid}&field=dockerimage2cloudconfig`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (dockerimageResponse.data.length > 0) {
        // If there are associated dockerimages, set the message
        setMessage(
          "This cloudconfig has associated dockerimages and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/cloudconfigs/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("CloudConfig Deleted:", response.data);
      navigate("/setup/cloudconfig");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching cloudconfig:", error);
      }
    }
  };

  const openDockerImageModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDockerImageModalOpen(true);
  };

  const closeDockerImageModal = () => {
    setIsDockerImageModalOpen(false);
  };

  const handleCustomDockerImageSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeDockerImageModal();
      onDockerImageSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching dockerimage:", error);
      }
    }
  };
  const openCloudConfigModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsCloudConfigModalOpen(true);
  };

  const closeCloudConfigModal = () => {
    setIsCloudConfigModalOpen(false);
  };
  const handleCustomCloudConfigSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeCloudConfigModal();
      onCloudConfigSubmit(response.data); // Pass the new cloudconfig to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete CloudConfig
          </a>
        </li>
        <li>
          <a href="#" onClick={openCloudConfigModal}>
            Clone CloudConfig
          </a>
        </li>
        <li>
          <a href="# " onClick={openDockerImageModal}>
            Add Test Image
          </a>
        </li>
      </ul>
      <DockerImageFormModal
        isOpen={isDockerImageModalOpen}
        onRequestClose={closeDockerImageModal}
        dockerimage={{ dockerimage2cloudconfig: data?.objid }} // Pass the dockerimage data if needed
        onSubmit={handleCustomDockerImageSubmit}
        cloneData={null}
      />
      <CloudConfigFormModal
        isOpen={isCloudConfigModalOpen}
        onRequestClose={closeCloudConfigModal}
        cloudconfig={null} // Pass the project data if needed
        onSubmit={handleCustomCloudConfigSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
