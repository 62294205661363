import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditTasks.css";
import TableTasks from "./TableTasks";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import CommentsList from "../comments/CommentsList";
import SubTaskList from "../subtask/SubTaskList";
import TaskDocumentList from "../taskdocument/TaskDocumentList";
import TimesheetList from "../timesheet/TimesheetList";

const EditTasks = ({ tasksId: propTasksId }) => {
  const [tasks, setTasks] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableTasks, setShowTableTasks] = useState(false);
  const [commentss, setCommentss] = useState([]);
  const [subtasks, setSubTasks] = useState([]);
  const [taskdocuments, setTaskDocuments] = useState([]);
  const [timesheets, setTimesheets] = useState([]);

  const { id } = useParams();
  const tasksId = id || propTasksId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchTasks = async () => {
        if (!tasksId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/taskss/searchById/${tasksId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setTasks(response.data);
            setEditLoad(true);
            console.log("Fetched tasks data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching tasks:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchTasks();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/comments?query=${tasksId}&field=comments2tasks`,
        setCommentss,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/subtask?query=${tasksId}&field=subtask2tasks`,
        setSubTasks,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/taskdocument?query=${tasksId}&field=taskdocument2tasks`,
        setTaskDocuments,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/timesheet?query=${tasksId}&field=timesheet2tasks`,
        setTimesheets,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, tasksId]);

  const handleTasksSubmit = (newTasks) => {
    navigate(`/tasks/${newTasks.objid}`);
  };

  const handleCommentsSubmit = (newComments) => {
    setCommentss((commentss) => [newComments, ...commentss]);
    setShowTableTasks(true);
  };
  const handleSubTaskSubmit = (newSubTask) => {
    setSubTasks((subtasks) => [newSubTask, ...subtasks]);
    setShowTableTasks(true);
  };
  const handleTaskDocumentSubmit = (newTaskDocument) => {
    setTaskDocuments((taskdocuments) => [newTaskDocument, ...taskdocuments]);
    setShowTableTasks(true);
  };
  const handleTimesheetSubmit = (newTimesheet) => {
    setTimesheets((timesheets) => [newTimesheet, ...timesheets]);
    setShowTableTasks(true);
  };
  return (
    <div className="edit-tasks-container">
      <div className="main-content">
        <h1>Edit Tasks</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, tasks)}
        {editLoad ? (
          <TableTasks
            data={tasks}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <CommentsList commentss={commentss} />
        <SubTaskList subtasks={subtasks} />
        <TaskDocumentList taskdocuments={taskdocuments} />
        <TimesheetList timesheets={timesheets} />
      </div>
      <RightSidebar
        data={tasks}
        onCommentsSubmit={handleCommentsSubmit}
        onSubTaskSubmit={handleSubTaskSubmit}
        onTaskDocumentSubmit={handleTaskDocumentSubmit}
        onTimesheetSubmit={handleTimesheetSubmit}
        onTasksSubmit={handleTasksSubmit}
      />
    </div>
  );
};

export default EditTasks;
