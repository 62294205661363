import React from "react";
import "./About.css"; // Import the CSS file

function About() {
  return (
    <div>
      <div className="banner">
        <span>Welcome to ProjexLight Cloud Platform</span>
      </div>
      <div className="about-container">
        <div className="about-left-section">
          Project Management & Test Execution Platform
        </div>
        <div className="about-right-section">
          <div className="about-box">
            Create Project Management tasks, write BDD test cases, execute 100s
            of test suites in parallel for any test environment using the choice
            of your cloud and docker, and report within a single platform
          </div>
          <div className="about-box">
            Create and connect with developers and testers for any open-source
            projects
          </div>
          <div className="about-box">
            Manage and test your freelancing projects using this cloud-based
            application for free
          </div>
          <div className="about-box">
            Use your own private cloud account, develop and test your projects
          </div>
          <div className="about-box">
            Hire resources who are eligible based on their skill set, assign
            tasks, and collaborate with any developer all over the world
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
