import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditTestReport.css";
import TableTestReport from "./TableTestReport";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import TestFailureList from "../testfailure/TestFailureList";
import TestLogList from "../testlog/TestLogList";
import TestResultList from "../testresult/TestResultList";

const EditTestReport = ({ testreportId: propTestReportId }) => {
  const [testreport, setTestReport] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableTestReport, setShowTableTestReport] = useState(false);
  const [testfailures, setTestFailures] = useState([]);
  const [testlogs, setTestLogs] = useState([]);
  const [testresults, setTestResults] = useState([]);

  const { id } = useParams();
  const testreportId = id || propTestReportId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchTestReport = async () => {
        if (!testreportId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/testreports/searchById/${testreportId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setTestReport(response.data);
            setEditLoad(true);
            console.log("Fetched testreport data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching testreport:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchTestReport();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testfailure?query=${testreportId}&field=testfailure2testreport`,
        setTestFailures,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testlog?query=${testreportId}&field=testlog2testreport`,
        setTestLogs,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testresult?query=${testreportId}&field=testresult2testreport`,
        setTestResults,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, testreportId]);

  const handleTestReportSubmit = (newTestReport) => {
    navigate(`/testreport/${newTestReport.objid}`);
  };

  const handleTestFailureSubmit = (newTestFailure) => {
    setTestFailures((testfailures) => [newTestFailure, ...testfailures]);
    setShowTableTestReport(true);
  };
  const handleTestLogSubmit = (newTestLog) => {
    setTestLogs((testlogs) => [newTestLog, ...testlogs]);
    setShowTableTestReport(true);
  };
  const handleTestResultSubmit = (newTestResult) => {
    setTestResults((testresults) => [newTestResult, ...testresults]);
    setShowTableTestReport(true);
  };
  return (
    <div className="edit-testreport-container">
      <div className="main-content">
        <h1>Edit TestReport</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, testreport)}
        {editLoad ? (
          <TableTestReport
            data={testreport}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <TestFailureList testfailures={testfailures} />
        <TestLogList testlogs={testlogs} />
        <TestResultList testresults={testresults} />
      </div>
      <RightSidebar
        data={testreport}
        onTestFailureSubmit={handleTestFailureSubmit}
        onTestLogSubmit={handleTestLogSubmit}
        onTestResultSubmit={handleTestResultSubmit}
        onTestReportSubmit={handleTestReportSubmit}
      />
    </div>
  );
};

export default EditTestReport;
