import React, { createContext, useState, useEffect, useContext } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [tokenExpiryMessage, setTokenExpiryMessage] = useState("");

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        console.log("User loaded from localStorage:", parsedUser);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }
  }, []);

  const login = (user) => {
    localStorage.setItem("user", JSON.stringify(user)); // Convert object to string
    setUser(user);
    //console.log("User logged in:", user);
  };

  const logout = (message = "") => {
    localStorage.removeItem("user");
    setUser(null);
    setTokenExpiryMessage(message);
    console.log("User logged out");
  };

  const switchProject = (project) => {
    // console.log("Before User=", JSON.stringify(user));
    //console.log("Project=", JSON.stringify(project));
    setUser((prevUser) => {
      const updatedUser = {
        ...prevUser,
        currentProject: project.objid,
        roles:
          prevUser.my_groupuseid === project.groupuserid
            ? "Admin"
            : project.permission,
        x_groupuserid:
          prevUser.my_groupuseid === project.groupuserid
            ? prevUser.my_groupuseid
            : project.groupuserid,
        x_poolindex:
          prevUser.my_groupuseid === project.groupuserid
            ? prevUser.my_poolindex
            : project.poolindex,
      };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      // console.log("After User=", JSON.stringify(updatedUser));
      return updatedUser;
    });
  };

  const switchToUser = () => {
    // console.log("Before User=", JSON.stringify(user));
    //console.log("Project=", JSON.stringify(project));
    setUser((prevUser) => {
      const updatedUser = {
        ...prevUser,
        //  currentProject: project.objid,
        roles: "Admin",

        x_groupuserid: prevUser.my_groupuseid,
        x_poolindex: prevUser.my_poolindex,
      };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      // console.log("After User=", JSON.stringify(updatedUser));
      return updatedUser;
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        switchProject,
        switchToUser,
        tokenExpiryMessage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuth };
