import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Account Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "serverip",
    label: "Server IP",
    type: "text",
    maxLength: 20,
    required: true,
  },
  {
    name: "licensekey",
    label: "License Key Supplied",
    type: "text",
    maxLength: 500,
    required: true,
  },
  {
    name: "liccountkey",
    label: "License Count Key",
    type: "text",
    maxLength: 500,
    required: false,
  },
  {
    name: "licensecount",
    label: "License Count",
    type: "integer",
    maxLength: 16,
    required: false,
  },
  {
    name: "wbskey",
    label: "WBS Key",
    type: "text",
    maxLength: 500,
    required: false,
  },
  {
    name: "wbsno",
    label: "WBS Count",
    type: "integer",
    maxLength: 16,
    required: false,
  },
  {
    name: "expirykey",
    label: "Expiry Key",
    type: "text",
    maxLength: 500,
    required: false,
  },
  {
    name: "expirydate",
    label: "Expiry Days",
    type: "date",
    maxLength: 10,
    required: false,
  },
];
const InstallLicenseFormContainer = ({
  propInstallLicense,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propInstallLicense?.objid || null,
    name: propInstallLicense?.name || null,
    serverip: propInstallLicense?.serverip || null,
    licensekey: propInstallLicense?.licensekey || null,
    liccountkey: propInstallLicense?.liccountkey || null,
    licensecount: propInstallLicense?.licensecount || null,
    wbskey: propInstallLicense?.wbskey || null,
    wbsno: propInstallLicense?.wbsno || null,
    expirykey: propInstallLicense?.expirykey || null,
    expirydate: propInstallLicense?.expirydate || null,
    customdata: propInstallLicense?.customdata || null,
    groupuserid: propInstallLicense?.x_groupuserid || user.x_groupuserid,
    createdbyid: propInstallLicense?.createdbyid || user.id,
    createdby: propInstallLicense?.createdby || user.fullname,
    createddate: propInstallLicense?.createddate
      ? formatDate(propInstallLicense.createddate)
      : today,
    modifiedby: propInstallLicense?.modifiedby || user.fullname,
    modifieddate: propInstallLicense?.modifieddate
      ? formatDate(propInstallLicense.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "InstallLicense", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="installlicense"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default InstallLicenseFormContainer;
