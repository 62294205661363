import React from "react";

const SetupDetail = () => {
  return (
    <div>
      <h1>Setup Instruction:</h1>
      <p>
        Welcome to the setup page. Here you will find all the necessary details
        to get started with your new project.
      </p>
      <ul>
        <li>Step 1: Setup your product</li>
        <li>Step 2: Setup Project & Release.</li>
        <li>
          Step 3: Setup AWS cloud for S3 Bucket and to run Docker container.
        </li>
        <li>
          Step 4: If you want to publish your test results you should customize
          <a href="https://github.com/srimanta1968/testresult" target="_blank">
            {" "}
            this script{" "}
          </a>
          as you need.
        </li>
      </ul>
      <h3>AWS Account:</h3>
      <p>
        Please use this{" "}
        <a
          href="https://github.com/srimanta1968/testresult/blob/main/SecurityPolicy.json"
          target="_blank"
        >
          security policy{" "}
        </a>
        for AWS account which will allow to run the container using farget
        container service and store test results and documents in S3 bucket and
        publish to ProjectX account. Your test results will not be visible to
        anybody except you unless you add any project member to your project
        team and give access using member role.
      </p>

      <p>
        Please create proper role and security policy as per your organization.
        Using CloudConfig link provide accessKeyId, secretAccessKey, bucket name
        and region. Your AWS account details will not be displayed either to you
        or anybody once this record is saved. If you need to change then delete
        the existing record and create again new.
      </p>
      <h3>Custom Fields:</h3>
      <p>
        We allow custom fields for certain object. Please look at custom filed
        link and see if you need to add any custom field for any of these
        objects which are in the dropdown.
      </p>
    </div>
  );
};

export default SetupDetail;
