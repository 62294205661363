import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Member Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "sunday",
    label: "Sunday",
    type: "float",
    maxLength: 7,
    required: false,
  },
  {
    name: "monday",
    label: "Monday",
    type: "float",
    maxLength: 7,
    required: false,
  },
  {
    name: "tuesday",
    label: "Tuesday",
    type: "float",
    maxLength: 7,
    required: false,
  },
  {
    name: "wednesday",
    label: "Wednesday",
    type: "float",
    maxLength: 7,
    required: false,
  },
  {
    name: "thursday",
    label: "Thursday",
    type: "float",
    maxLength: 7,
    required: false,
  },
  {
    name: "friday",
    label: "Friday",
    type: "float",
    maxLength: 7,
    required: false,
  },
  {
    name: "saturday",
    label: "Saturday",
    type: "float",
    maxLength: 7,
    required: false,
  },
  {
    name: "timesheet2tasks",
    maxLength: 100,
    label: "Tasks",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/tasks`,
  },
  {
    name: "timesheet2week",
    maxLength: 100,
    label: "Week",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/week`,
  },
  {
    name: "timesheet2user",
    maxLength: 100,
    label: "User",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/user`,
  },
];
const TimesheetFormContainer = ({
  propTimesheet,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propTimesheet?.objid || null,
    name: propTimesheet?.name || user.fullname,
    sunday: propTimesheet?.sunday || "0",
    monday: propTimesheet?.monday || "0",
    tuesday: propTimesheet?.tuesday || "0",
    wednesday: propTimesheet?.wednesday || "0",
    thursday: propTimesheet?.thursday || "0",
    friday: propTimesheet?.friday || "0",
    saturday: propTimesheet?.saturday || "0",
    timesheet2tasks: propTimesheet?.timesheet2tasks,
    timesheet2week: propTimesheet?.timesheet2week,
    timesheet2user: propTimesheet?.timesheet2user,
    customdata: propTimesheet?.customdata || null,
    groupuserid: propTimesheet?.x_groupuserid || user.x_groupuserid,
    createdbyid: propTimesheet?.createdbyid || user.id,
    createdby: propTimesheet?.createdby || user.fullname,
    createddate: propTimesheet?.createddate
      ? formatDate(propTimesheet.createddate)
      : today,
    modifiedby: propTimesheet?.modifiedby || user.fullname,
    modifieddate: propTimesheet?.modifieddate
      ? formatDate(propTimesheet.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Timesheet", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="timesheet"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default TimesheetFormContainer;
