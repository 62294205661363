import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Rule Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "tablename",
    label: "Table Name",
    type: "text",
    maxLength: 100,
    required: false,
  },
  {
    name: "propertystring",
    label: "Property String",
    type: "text",
    maxLength: 50,
    required: true,
  },
  {
    name: "propertyvalue",
    label: "Property Value",
    type: "text",
    maxLength: 50,
    required: true,
  },
  {
    name: "scope",
    label: "Scope",
    type: "select",
    maxLength: 100,
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Scope&table=ListProperty`,
  },
  {
    name: "propindex",
    label: "Index",
    type: "text",
    maxLength: 16,
    required: true,
  },
  {
    name: "listproperty2object",
    maxLength: 100,
    label: "Object",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/object`,
  },
  {
    name: "listproperty2attribute",
    maxLength: 100,
    label: "Attribute",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/attribute`,
  },
];
const ListPropertyFormContainer = ({
  propListProperty,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propListProperty?.objid || null,
    name: propListProperty?.name || "Name,PropertyValue",
    tablename: propListProperty?.tablename || null,
    propertystring: propListProperty?.propertystring || null,
    propertyvalue: propListProperty?.propertyvalue || null,
    scope: propListProperty?.scope || null,
    propindex: propListProperty?.propindex || null,
    listproperty2object: propListProperty?.listproperty2object,
    listproperty2attribute: propListProperty?.listproperty2attribute,
    customdata: propListProperty?.customdata || null,
    groupuserid: propListProperty?.x_groupuserid || user.x_groupuserid,
    createdbyid: propListProperty?.createdbyid || user.id,
    createdby: propListProperty?.createdby || user.fullname,
    createddate: propListProperty?.createddate
      ? formatDate(propListProperty.createddate)
      : today,
    modifiedby: propListProperty?.modifiedby || user.fullname,
    modifieddate: propListProperty?.modifieddate
      ? formatDate(propListProperty.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "ListProperty", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="listproperty"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default ListPropertyFormContainer;
