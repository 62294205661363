import React from "react";
import Modal from "react-modal";
import CustomFieldsFormContainer from "./CustomFieldsFormContainer";
import "./CustomFieldsForm.css";
const CustomFieldsFormModal = ({
  isOpen,
  onRequestClose,
  customfields,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="CustomFields Form"
    >
      <div className="modal-content">
        <CustomFieldsFormContainer
          propCustomFields={customfields}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default CustomFieldsFormModal;
