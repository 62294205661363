import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditTestCycle.css";
import TableTestCycle from "./TableTestCycle";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import DefectList from "../defect/DefectList";
import RunScheduleList from "../runschedule/RunScheduleList";
import RunTagList from "../../common/RunTagList";
import TestReportList from "../testreport/TestReportList";

const EditTestCycle = ({ testcycleId: propTestCycleId }) => {
  const [testcycle, setTestCycle] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableTestCycle, setShowTableTestCycle] = useState(false);
  const [defects, setDefects] = useState([]);
  const [runschedules, setRunSchedules] = useState([]);
  const [runtags, setRunTags] = useState([]);
  const [testreports, setTestReports] = useState([]);

  const { id } = useParams();
  const testcycleId = id || propTestCycleId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchTestCycle = async () => {
        if (!testcycleId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/testcycles/searchById/${testcycleId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setTestCycle(response.data);
            setEditLoad(true);
            console.log("Fetched testcycle data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching testcycle:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchTestCycle();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/defect?query=${testcycleId}&field=defect2testcycle`,
        setDefects,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/runschedule?query=${testcycleId}&field=runschedule2testcycle`,
        setRunSchedules,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/runtag?query=${testcycleId}&field=runtag2testcycle`,
        setRunTags,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testreport?query=${testcycleId}&field=testreport2testcycle`,
        setTestReports,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, testcycleId]);

  const handleTestCycleSubmit = (newTestCycle) => {
    navigate(`/testcycle/${newTestCycle.objid}`);
  };

  const handleDefectSubmit = (newDefect) => {
    setDefects((defects) => [newDefect, ...defects]);
    setShowTableTestCycle(true);
  };
  const handleRunScheduleSubmit = (newRunSchedule) => {
    setRunSchedules((runschedules) => [newRunSchedule, ...runschedules]);
    setShowTableTestCycle(true);
  };
  const handleRunTagSubmit = (newRunTag) => {
    setRunTags((runtags) => [newRunTag, ...runtags]);
    setShowTableTestCycle(true);
  };
  const handleTestReportSubmit = (newTestReport) => {
    setTestReports((testreports) => [newTestReport, ...testreports]);
    setShowTableTestCycle(true);
  };
  return (
    <div className="edit-testcycle-container">
      <div className="main-content">
        <h1>Edit Test Cycle</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, testcycle)}
        {editLoad ? (
          <TableTestCycle
            data={testcycle}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <DefectList defects={defects} />
        <RunScheduleList runschedules={runschedules} />
        <RunTagList runtags={runtags} />
        <TestReportList testreports={testreports} />
      </div>
      <RightSidebar
        data={testcycle}
        onDefectSubmit={handleDefectSubmit}
        onRunScheduleSubmit={handleRunScheduleSubmit}
        onRunTagSubmit={handleRunTagSubmit}
        onTestReportSubmit={handleTestReportSubmit}
        onTestCycleSubmit={handleTestCycleSubmit}
      />
    </div>
  );
};

export default EditTestCycle;
