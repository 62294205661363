import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import conf from "../config/config";
import "./ProjectSwitcher.css";

function ProjectSwitcher() {
  const { user, switchProject, switchToUser } = useAuth();
  const [assignedProjects, setAssignedProjects] = useState([]);
  const [myProjects, setMyProjects] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const [currentPageAssigned, setCurrentPageAssigned] = useState(1);
  const [totalPagesAssigned, setTotalPagesAssigned] = useState(1);
  const [currentPageMy, setCurrentPageMy] = useState(1);
  const [totalPagesMy, setTotalPagesMy] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchAssignedProjects = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/dashboard/getAssignedProject`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              my_groupuser_id: `${user.my_groupuseid}`,
              my_poolindex: `${user.my_poolindex}`,
            },
            params: {
              assignee: user.id, // Assuming user.id is the member2assignee value
              limit: itemsPerPage,
              offset: (currentPageAssigned - 1) * itemsPerPage,
            },
          }
        );
        setAssignedProjects(response.data.projects || []);
        setTotalPagesAssigned(
          Math.ceil((response.data.total || 0) / itemsPerPage)
        );
      } catch (error) {
        console.error("Error fetching assigned projects:", error);
      }
    };

    const fetchMyProjects = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/dashboard/getMyProjects`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              my_groupuser_id: `${user.my_groupuseid}`,
              my_poolindex: `${user.my_poolindex}`,
            },
            params: {
              userId: user.id, // Assuming user.id is the user ID
              limit: itemsPerPage,
              offset: (currentPageMy - 1) * itemsPerPage,
            },
          }
        );
        setMyProjects(response.data.projects || []);
        setTotalPagesMy(Math.ceil((response.data.total || 0) / itemsPerPage));
      } catch (error) {
        console.error("Error fetching my projects:", error);
      }
    };

    fetchAssignedProjects();
    fetchMyProjects();
  }, [user, currentPageAssigned, currentPageMy]);

  const handleProjectSwitch = (project) => {
    switchProject(project);
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedProjects = (projects) => {
    return [...projects].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  };

  return (
    <div>
      <h2>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            switchToUser();
          }}
        >
          My Projects
        </a>
      </h2>
      {myProjects.length === 0 ? (
        <p>No projects found</p>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th onClick={() => requestSort("name")}>Name</th>
                <th onClick={() => requestSort("startdate")}>Start Date</th>
                <th onClick={() => requestSort("testcomplete")}>End Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortedProjects(myProjects).map((project) => (
                <tr key={project.objid}>
                  <td>{project.name}</td>
                  <td>{new Date(project.startdate).toLocaleDateString()}</td>
                  <td>{new Date(project.testcomplete).toLocaleDateString()}</td>
                  <td>
                    <button onClick={() => handleProjectSwitch(project)}>
                      Switch
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button
              onClick={() => setCurrentPageMy((prev) => Math.max(prev - 1, 1))}
              disabled={currentPageMy === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPageMy} of {totalPagesMy}
            </span>
            <button
              onClick={() =>
                setCurrentPageMy((prev) => Math.min(prev + 1, totalPagesMy))
              }
              disabled={currentPageMy === totalPagesMy}
            >
              Next
            </button>
          </div>
        </>
      )}

      <h2>Assigned Projects</h2>
      {assignedProjects.length === 0 ? (
        <p>No projects assigned</p>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th onClick={() => requestSort("name")}>Name</th>
                <th onClick={() => requestSort("startdate")}>Start Date</th>
                <th onClick={() => requestSort("enddate")}>End Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortedProjects(assignedProjects).map((project) => (
                <tr key={project.objid}>
                  <td>{project.name}</td>
                  <td>{new Date(project.startdate).toLocaleDateString()}</td>
                  <td>{new Date(project.enddate).toLocaleDateString()}</td>
                  <td>
                    <button onClick={() => handleProjectSwitch(project)}>
                      Switch
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button
              onClick={() =>
                setCurrentPageAssigned((prev) => Math.max(prev - 1, 1))
              }
              disabled={currentPageAssigned === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPageAssigned} of {totalPagesAssigned}
            </span>
            <button
              onClick={() =>
                setCurrentPageAssigned((prev) =>
                  Math.min(prev + 1, totalPagesAssigned)
                )
              }
              disabled={currentPageAssigned === totalPagesAssigned}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default ProjectSwitcher;
