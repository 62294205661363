import React from "react";
import Modal from "react-modal";
import SubscriptionFormContainer from "./SubscriptionFormContainer";
import "./SubscriptionForm.css";
const SubscriptionFormModal = ({
  isOpen,
  onRequestClose,
  subscription,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Subscription Form"
    >
      <div className="modal-content">
        <SubscriptionFormContainer
          propSubscription={subscription}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default SubscriptionFormModal;
