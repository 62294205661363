import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditTestSuite.css";
import TableTestSuite from "./TableTestSuite";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import DefectList from "../defect/DefectList";
import SuiteTagList from "../../common/SuiteTagList";
import TestFailureList from "../testfailure/TestFailureList";
import TestLogList from "../testlog/TestLogList";
import TestResultList from "../testresult/TestResultList";

const EditTestSuite = ({ testsuiteId: propTestSuiteId }) => {
  const [testsuite, setTestSuite] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableTestSuite, setShowTableTestSuite] = useState(false);
  const [defects, setDefects] = useState([]);
  const [suitetags, setSuiteTags] = useState([]);
  const [testfailures, setTestFailures] = useState([]);
  const [testlogs, setTestLogs] = useState([]);
  const [testresults, setTestResults] = useState([]);

  const { id } = useParams();
  const testsuiteId = id || propTestSuiteId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchTestSuite = async () => {
        if (!testsuiteId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/testsuites/searchById/${testsuiteId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setTestSuite(response.data);
            setEditLoad(true);
            console.log("Fetched testsuite data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching testsuite:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchTestSuite();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/defect?query=${testsuiteId}&field=defect2testsuite`,
        setDefects,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/suitetag?query=${testsuiteId}&field=suitetag2testsuite`,
        setSuiteTags,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testfailure?query=${testsuiteId}&field=testfailure2testsuite`,
        setTestFailures,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testlog?query=${testsuiteId}&field=testlog2testsuite`,
        setTestLogs,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testresult?query=${testsuiteId}&field=testresult2testsuite`,
        setTestResults,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, testsuiteId]);

  const handleTestSuiteSubmit = (newTestSuite) => {
    navigate(`/testsuite/${newTestSuite.objid}`);
  };

  const handleDefectSubmit = (newDefect) => {
    setDefects((defects) => [newDefect, ...defects]);
    setShowTableTestSuite(true);
  };
  const handleSuiteTagSubmit = (newSuiteTag) => {
    setSuiteTags((suitetags) => [newSuiteTag, ...suitetags]);
    setShowTableTestSuite(true);
  };
  const handleTestFailureSubmit = (newTestFailure) => {
    setTestFailures((testfailures) => [newTestFailure, ...testfailures]);
    setShowTableTestSuite(true);
  };
  const handleTestLogSubmit = (newTestLog) => {
    setTestLogs((testlogs) => [newTestLog, ...testlogs]);
    setShowTableTestSuite(true);
  };
  const handleTestResultSubmit = (newTestResult) => {
    setTestResults((testresults) => [newTestResult, ...testresults]);
    setShowTableTestSuite(true);
  };
  return (
    <div className="edit-testsuite-container">
      <div className="main-content">
        <h1>Edit Test Suite</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, testsuite)}
        {editLoad ? (
          <TableTestSuite
            data={testsuite}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <DefectList defects={defects} />
        <SuiteTagList suitetags={suitetags} />
        <TestFailureList testfailures={testfailures} />
        <TestLogList testlogs={testlogs} />
        <TestResultList testresults={testresults} />
      </div>
      <RightSidebar
        data={testsuite}
        onDefectSubmit={handleDefectSubmit}
        onSuiteTagSubmit={handleSuiteTagSubmit}
        onTestFailureSubmit={handleTestFailureSubmit}
        onTestLogSubmit={handleTestLogSubmit}
        onTestResultSubmit={handleTestResultSubmit}
        onTestSuiteSubmit={handleTestSuiteSubmit}
      />
    </div>
  );
};

export default EditTestSuite;
