import React from "react";
import Modal from "react-modal";
import RunScheduleFormContainer from "./RunScheduleFormContainer";
import "./RunScheduleForm.css";
const RunScheduleFormModal = ({
  isOpen,
  onRequestClose,
  runschedule,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="RunSchedule Form"
    >
      <div className="modal-content">
        <RunScheduleFormContainer
          propRunSchedule={runschedule}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default RunScheduleFormModal;
