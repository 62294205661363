import React from "react";
import Modal from "react-modal";
import MemberFormContainer from "./MemberFormContainer";
import "./MemberForm.css";
const MemberFormModal = ({
  isOpen,
  onRequestClose,
  member,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Member Form"
    >
      <div className="modal-content">
        <MemberFormContainer
          propMember={member}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default MemberFormModal;
