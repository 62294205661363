import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import FeatureFormModal from "./FeatureFormModal";
import FeatureDocumentFormModal from "../featuredocument/FeatureDocumentFormModal";
import ScenarioFormModal from "../scenario/ScenarioFormModal";
import TasksFormModal from "../tasks/TasksFormModal";
import TestFailureFormModal from "../testfailure/TestFailureFormModal";
import TestSuiteFormModal from "../testsuite/TestSuiteFormModal";

const RightSidebar = ({
  data,
  onFeatureDocumentSubmit,
  onScenarioSubmit,
  onTasksSubmit,
  onTestFailureSubmit,
  onTestSuiteSubmit,
  onFeatureSubmit,
}) => {
  const [isFeatureDocumentModalOpen, setIsFeatureDocumentModalOpen] =
    useState(false);
  const [isScenarioModalOpen, setIsScenarioModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [isTestFailureModalOpen, setIsTestFailureModalOpen] = useState(false);
  const [isTestSuiteModalOpen, setIsTestSuiteModalOpen] = useState(false);
  const [isFeatureModalOpen, setIsFeatureModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated featuredocuments
      const featuredocumentResponse = await axios.get(
        `${conf.API_BASE_URL}/search/featuredocument?query=${data.objid}&field=featuredocument2feature`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (featuredocumentResponse.data.length > 0) {
        // If there are associated featuredocuments, set the message
        setMessage(
          "This feature has associated featuredocuments and cannot be deleted.",
        );
        return;
      }
      // Fetch associated scenarios
      const scenarioResponse = await axios.get(
        `${conf.API_BASE_URL}/search/scenario?query=${data.objid}&field=scenario2feature`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (scenarioResponse.data.length > 0) {
        // If there are associated scenarios, set the message
        setMessage(
          "This feature has associated scenarios and cannot be deleted.",
        );
        return;
      }
      // Fetch associated taskss
      const tasksResponse = await axios.get(
        `${conf.API_BASE_URL}/search/tasks?query=${data.objid}&field=tasks2feature`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (tasksResponse.data.length > 0) {
        // If there are associated taskss, set the message
        setMessage("This feature has associated taskss and cannot be deleted.");
        return;
      }
      // Fetch associated testfailures
      const testfailureResponse = await axios.get(
        `${conf.API_BASE_URL}/search/testfailure?query=${data.objid}&field=testfailure2feature`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (testfailureResponse.data.length > 0) {
        // If there are associated testfailures, set the message
        setMessage(
          "This feature has associated testfailures and cannot be deleted.",
        );
        return;
      }
      // Fetch associated testsuites
      const testsuiteResponse = await axios.get(
        `${conf.API_BASE_URL}/search/testsuite?query=${data.objid}&field=testsuite2feature`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (testsuiteResponse.data.length > 0) {
        // If there are associated testsuites, set the message
        setMessage(
          "This feature has associated testsuites and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/features/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Feature Deleted:", response.data);
      navigate("/setup/feature");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching feature:", error);
      }
    }
  };

  const openFeatureDocumentModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsFeatureDocumentModalOpen(true);
  };

  const closeFeatureDocumentModal = () => {
    setIsFeatureDocumentModalOpen(false);
  };

  const handleCustomFeatureDocumentSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeFeatureDocumentModal();
      onFeatureDocumentSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching featuredocument:", error);
      }
    }
  };

  const openScenarioModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsScenarioModalOpen(true);
  };

  const closeScenarioModal = () => {
    setIsScenarioModalOpen(false);
  };

  const handleCustomScenarioSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeScenarioModal();
      onScenarioSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching scenario:", error);
      }
    }
  };

  const openTasksModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTasksModalOpen(true);
  };

  const closeTasksModal = () => {
    setIsTasksModalOpen(false);
  };

  const handleCustomTasksSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTasksModal();
      onTasksSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching tasks:", error);
      }
    }
  };

  const openTestFailureModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestFailureModalOpen(true);
  };

  const closeTestFailureModal = () => {
    setIsTestFailureModalOpen(false);
  };

  const handleCustomTestFailureSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestFailureModal();
      onTestFailureSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testfailure:", error);
      }
    }
  };

  const openTestSuiteModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestSuiteModalOpen(true);
  };

  const closeTestSuiteModal = () => {
    setIsTestSuiteModalOpen(false);
  };

  const handleCustomTestSuiteSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestSuiteModal();
      onTestSuiteSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testsuite:", error);
      }
    }
  };
  const openFeatureModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsFeatureModalOpen(true);
  };

  const closeFeatureModal = () => {
    setIsFeatureModalOpen(false);
  };
  const handleCustomFeatureSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeFeatureModal();
      onFeatureSubmit(response.data); // Pass the new feature to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Feature
          </a>
        </li>
        <li>
          <a href="#" onClick={openFeatureModal}>
            Clone Feature
          </a>
        </li>
        <li>
          <a href="# " onClick={openFeatureDocumentModal}>
            Add Document
          </a>
        </li>
        <li>
          <a href="# " onClick={openScenarioModal}>
            Add Scenario
          </a>
        </li>
        <li>
          <a href="# " onClick={openTasksModal}>
            Add Tasks
          </a>
        </li>
        <li>
          <a href="# " onClick={openTestFailureModal}>
            Add Test Failure
          </a>
        </li>
        <li>
          <a href="# " onClick={openTestSuiteModal}>
            Add Test Suite
          </a>
        </li>
      </ul>
      <FeatureDocumentFormModal
        isOpen={isFeatureDocumentModalOpen}
        onRequestClose={closeFeatureDocumentModal}
        featuredocument={{ featuredocument2feature: data?.objid }} // Pass the featuredocument data if needed
        onSubmit={handleCustomFeatureDocumentSubmit}
        cloneData={null}
      />
      <ScenarioFormModal
        isOpen={isScenarioModalOpen}
        onRequestClose={closeScenarioModal}
        scenario={{ scenario2feature: data?.objid }} // Pass the scenario data if needed
        onSubmit={handleCustomScenarioSubmit}
        cloneData={null}
      />
      <TasksFormModal
        isOpen={isTasksModalOpen}
        onRequestClose={closeTasksModal}
        tasks={{ tasks2feature: data?.objid }} // Pass the tasks data if needed
        onSubmit={handleCustomTasksSubmit}
        cloneData={null}
      />
      <TestFailureFormModal
        isOpen={isTestFailureModalOpen}
        onRequestClose={closeTestFailureModal}
        testfailure={{ testfailure2feature: data?.objid }} // Pass the testfailure data if needed
        onSubmit={handleCustomTestFailureSubmit}
        cloneData={null}
      />
      <TestSuiteFormModal
        isOpen={isTestSuiteModalOpen}
        onRequestClose={closeTestSuiteModal}
        testsuite={{ testsuite2feature: data?.objid }} // Pass the testsuite data if needed
        onSubmit={handleCustomTestSuiteSubmit}
        cloneData={null}
      />
      <FeatureFormModal
        isOpen={isFeatureModalOpen}
        onRequestClose={closeFeatureModal}
        feature={null} // Pass the project data if needed
        onSubmit={handleCustomFeatureSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
