import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import ObjectAccessFormModal from "./ObjectAccessFormModal";
import AttrAccessFormModal from "../attraccess/AttrAccessFormModal";

const RightSidebar = ({ data, onAttrAccessSubmit, onObjectAccessSubmit }) => {
  const [isAttrAccessModalOpen, setIsAttrAccessModalOpen] = useState(false);
  const [isObjectAccessModalOpen, setIsObjectAccessModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated attraccesss
      const attraccessResponse = await axios.get(
        `${conf.API_BASE_URL}/search/attraccess?query=${data.objid}&field=attraccess2objectaccess`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (attraccessResponse.data.length > 0) {
        // If there are associated attraccesss, set the message
        setMessage(
          "This objectaccess has associated attraccesss and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/objectaccesss/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("ObjectAccess Deleted:", response.data);
      navigate("/setup/objectaccess");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching objectaccess:", error);
      }
    }
  };

  const openAttrAccessModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsAttrAccessModalOpen(true);
  };

  const closeAttrAccessModal = () => {
    setIsAttrAccessModalOpen(false);
  };

  const handleCustomAttrAccessSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeAttrAccessModal();
      onAttrAccessSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching attraccess:", error);
      }
    }
  };
  const openObjectAccessModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsObjectAccessModalOpen(true);
  };

  const closeObjectAccessModal = () => {
    setIsObjectAccessModalOpen(false);
  };
  const handleCustomObjectAccessSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeObjectAccessModal();
      onObjectAccessSubmit(response.data); // Pass the new objectaccess to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete ObjectAccess
          </a>
        </li>
        <li>
          <a href="#" onClick={openObjectAccessModal}>
            Clone ObjectAccess
          </a>
        </li>
        <li>
          <a href="# " onClick={openAttrAccessModal}>
            Add AttrAccess
          </a>
        </li>
      </ul>
      <AttrAccessFormModal
        isOpen={isAttrAccessModalOpen}
        onRequestClose={closeAttrAccessModal}
        attraccess={{ attraccess2objectaccess: data?.objid }} // Pass the attraccess data if needed
        onSubmit={handleCustomAttrAccessSubmit}
        cloneData={null}
      />
      <ObjectAccessFormModal
        isOpen={isObjectAccessModalOpen}
        onRequestClose={closeObjectAccessModal}
        objectaccess={null} // Pass the project data if needed
        onSubmit={handleCustomObjectAccessSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
