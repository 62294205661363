import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "startdate",
    label: "Start Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "enddate",
    label: "End Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "testenv",
    label: 'Test Param JSON {"testenv":"test", ...}',
    type: "text",
    maxLength: 4000,
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 300,
    required: false,
  },
  {
    name: "testcycle2release",
    maxLength: 100,
    label: "Release",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/release`,
  },
];
const TestCycleFormContainer = ({
  propTestCycle,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propTestCycle?.objid || null,
    name: propTestCycle?.name || null,
    startdate: propTestCycle?.startdate || null,
    enddate: propTestCycle?.enddate || null,
    testenv: propTestCycle?.testenv || '{"testenv":"test"}',
    description: propTestCycle?.description || null,
    testcycle2release: propTestCycle?.testcycle2release,
    customdata: propTestCycle?.customdata || null,
    groupuserid: propTestCycle?.x_groupuserid || user.x_groupuserid,
    createdbyid: propTestCycle?.createdbyid || user.id,
    createdby: propTestCycle?.createdby || user.fullname,
    createddate: propTestCycle?.createddate
      ? formatDate(propTestCycle.createddate)
      : today,
    modifiedby: propTestCycle?.modifiedby || user.fullname,
    modifieddate: propTestCycle?.modifieddate
      ? formatDate(propTestCycle.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "TestCycle", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="testcycle"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default TestCycleFormContainer;
