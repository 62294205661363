import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Suite Name",
    type: "text",
    maxLength: 200,
    required: true,
  },
  {
    name: "starttime",
    label: "Start Time",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "endtime",
    label: "End Time",
    type: "date",
    maxLength: 10,
    required: false,
  },
  {
    name: "testresult2testreport",
    maxLength: 100,
    label: "TestReport",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/testreport`,
  },
  {
    name: "testresult2testsuite",
    maxLength: 100,
    label: "TestSuite",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/testsuite`,
  },
];
const TestResultFormContainer = ({
  propTestResult,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propTestResult?.objid || null,
    name: propTestResult?.name || null,
    starttime: propTestResult?.starttime || null,
    endtime: propTestResult?.endtime || null,
    status: propTestResult?.status || null,
    resulturl: propTestResult?.resulturl || null,
    totaltest: propTestResult?.totaltest || "0",
    passed: propTestResult?.passed || "0",
    failed: propTestResult?.failed || "0",
    skipped: propTestResult?.skipped || "0",
    testenv: propTestResult?.testenv || "0",
    testresult2testreport: propTestResult?.testresult2testreport,
    testresult2testsuite: propTestResult?.testresult2testsuite,
    customdata: propTestResult?.customdata || null,
    groupuserid: propTestResult?.x_groupuserid || user.x_groupuserid,
    createdbyid: propTestResult?.createdbyid || user.id,
    createdby: propTestResult?.createdby || user.fullname,
    createddate: propTestResult?.createddate
      ? formatDate(propTestResult.createddate)
      : today,
    modifiedby: propTestResult?.modifiedby || user.fullname,
    modifieddate: propTestResult?.modifieddate
      ? formatDate(propTestResult.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "TestResult", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="testresult"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default TestResultFormContainer;
