import React from "react";
import ProjectSwitcher from "../common/ProjectSwitcher";
import MyTestSuite from "../common/MyTestSuite";

function Home() {
  return (
    <div>
      <h2>Dashboard</h2>
      <ProjectSwitcher />
      <MyTestSuite />
    </div>
  );
}

export default Home;
