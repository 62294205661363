import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Suite Name",
    type: "text",
    maxLength: 200,
    required: true,
  },
  {
    name: "giturl",
    label: "Project GitURL",
    type: "text",
    maxLength: 200,
    required: true,
  },
  {
    name: "containername",
    label: "Container Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "action",
    label: "Run Action",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Action&table=TestSuite`,
  },
  {
    name: "testenv",
    label: 'Test Param JSON {"testenv":"test", ...}',
    type: "text",
    maxLength: 4000,
    required: true,
  },
  {
    name: "runcommand",
    label: "Run Command",
    type: "text",
    maxLength: 300,
    required: true,
  },
  {
    name: "cpu",
    label: "CPU (i.e 512,1024)",
    type: "integer",
    maxLength: 16,
    required: true,
  },
  {
    name: "memory",
    label: "Memory (i.e 512,1024)",
    type: "integer",
    maxLength: 16,
    required: true,
  },
  {
    name: "resultfile",
    label: "Result Filename Path (relative)",
    type: "text",
    maxLength: 200,
    required: true,
  },
  {
    name: "resultjsonfile",
    label: "Result JSON File Path (relative)",
    type: "text",
    maxLength: 200,
    required: true,
  },
  {
    name: "resultgiturl",
    label: "Result Update GIT URL",
    type: "text",
    maxLength: 200,
    required: true,
  },
  {
    name: "scriptfile",
    label: "Result Update Script File",
    type: "text",
    maxLength: 200,
    required: true,
  },
  {
    name: "isactive",
    label: "Is Active",
    type: "checkbox",
    maxLength: 3,
    required: true,
  },
  {
    name: "testsuite2dockerimage",
    maxLength: 100,
    label: "DockerImage",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/dockerimage`,
  },
  {
    name: "testsuite2feature",
    maxLength: 100,
    label: "Feature",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/feature`,
  },
  {
    name: "testsuite2assignee",
    maxLength: 100,
    label: "Assignee",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/assignee`,
  },
];
const TestSuiteFormContainer = ({
  propTestSuite,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propTestSuite?.objid || null,
    name: propTestSuite?.name || null,
    giturl: propTestSuite?.giturl || null,
    containername: propTestSuite?.containername || null,
    action: propTestSuite?.action || null,
    testenv: propTestSuite?.testenv || '{"testenv":"test"}',
    runcommand: propTestSuite?.runcommand || null,
    cpu: propTestSuite?.cpu || null,
    memory: propTestSuite?.memory || null,
    resultfile: propTestSuite?.resultfile || null,
    resultjsonfile: propTestSuite?.resultjsonfile || null,
    resultgiturl:
      propTestSuite?.resultgiturl || "github.com/srimanta1968/testresult.git",
    scriptfile: propTestSuite?.scriptfile || "cloudwatchlog.js",
    isactive: propTestSuite?.isactive || "true",
    taskarn: propTestSuite?.taskarn || null,
    statusmessage: propTestSuite?.statusmessage || null,
    testsuite2dockerimage: propTestSuite?.testsuite2dockerimage,
    testsuite2feature: propTestSuite?.testsuite2feature,
    testsuite2assignee: propTestSuite?.testsuite2assignee,
    customdata: propTestSuite?.customdata || null,
    groupuserid: propTestSuite?.x_groupuserid || user.x_groupuserid,
    createdbyid: propTestSuite?.createdbyid || user.id,
    createdby: propTestSuite?.createdby || user.fullname,
    createddate: propTestSuite?.createddate
      ? formatDate(propTestSuite.createddate)
      : today,
    modifiedby: propTestSuite?.modifiedby || user.fullname,
    modifieddate: propTestSuite?.modifieddate
      ? formatDate(propTestSuite.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "TestSuite", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="testsuite"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default TestSuiteFormContainer;
