import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 200, required: true },
  {
    name: "featuretag",
    label: "Feature Tag",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 4000,
    required: false,
  },
  {
    name: "priority",
    label: "Priority",
    type: "select",
    maxLength: 50,
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Priority&table=Feature`,
  },
  {
    name: "isautomated",
    label: "Is Automated",
    type: "select",
    maxLength: 50,
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=IsAutomated&table=Feature`,
  },
  {
    name: "filename",
    label: "Feature File Name",
    type: "text",
    maxLength: 200,
    required: false,
  },
  {
    name: "feature2epic",
    maxLength: 100,
    label: "Epic",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/epic`,
  },
];
const FeatureFormContainer = ({
  propFeature,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propFeature?.objid || null,
    name: propFeature?.name || null,
    featuretag: propFeature?.featuretag || null,
    description: propFeature?.description || null,
    priority: propFeature?.priority || null,
    isautomated: propFeature?.isautomated || null,
    filename: propFeature?.filename || null,
    feature2epic: propFeature?.feature2epic,
    customdata: propFeature?.customdata || null,
    groupuserid: propFeature?.x_groupuserid || user.x_groupuserid,
    createdbyid: propFeature?.createdbyid || user.id,
    createdby: propFeature?.createdby || user.fullname,
    createddate: propFeature?.createddate
      ? formatDate(propFeature.createddate)
      : today,
    modifiedby: propFeature?.modifiedby || user.fullname,
    modifieddate: propFeature?.modifieddate
      ? formatDate(propFeature.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Feature", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="feature"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default FeatureFormContainer;
