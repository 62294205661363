import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 4000,
    required: true,
  },
  {
    name: "startdate",
    label: "Start Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "duedate",
    label: "Due Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "storypoint",
    label: "Story Point",
    type: "integer",
    maxLength: 16,
    required: true,
  },
  {
    name: "estimatedhr",
    label: "Estimated Hr",
    type: "float",
    maxLength: 7,
    required: true,
  },
  {
    name: "taskstatuscode",
    label: "Status",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=TaskStatusCode`,
  },
  {
    name: "taskcode",
    label: "Task Type",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=TaskCode`,
  },
  {
    name: "tasks2feature",
    maxLength: 100,
    label: "Feature",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/feature`,
  },
  {
    name: "tasks2assignee",
    maxLength: 100,
    label: "Assignee",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/assignee`,
  },
];
const TasksFormContainer = ({ propTasks, onSubmit, onCancel, cloneData }) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propTasks?.objid || null,
    name: propTasks?.name || null,
    description: propTasks?.description || null,
    startdate: propTasks?.startdate || null,
    duedate: propTasks?.duedate || null,
    storypoint: propTasks?.storypoint || "0",
    estimatedhr: propTasks?.estimatedhr || "0",
    remaininghr: propTasks?.remaininghr || "0",
    taskstatuscode: propTasks?.taskstatuscode || null,
    taskcode: propTasks?.taskcode || null,
    tasks2feature: propTasks?.tasks2feature,
    tasks2assignee: propTasks?.tasks2assignee,
    customdata: propTasks?.customdata || null,
    groupuserid: propTasks?.x_groupuserid || user.x_groupuserid,
    createdbyid: propTasks?.createdbyid || user.id,
    createdby: propTasks?.createdby || user.fullname,
    createddate: propTasks?.createddate
      ? formatDate(propTasks.createddate)
      : today,
    modifiedby: propTasks?.modifiedby || user.fullname,
    modifieddate: propTasks?.modifieddate
      ? formatDate(propTasks.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Tasks", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="tasks"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default TasksFormContainer;
