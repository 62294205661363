import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import SetupDetail from "./SetupDetail";
import MyTasks from "../common/MyTasks";
import MyFeatures from "../common/MyFeatures";
import MyDefects from "../common/MyDefects";
import TestResultGraph from "../common/TestResultGraph";
import TestSummary from "../common/TestSummary";
import EditAccount from "../components/account/EditAccount";
import SearchAccount from "../components/account/SearchAccount";
import EditActionQuery from "../components/actionquery/EditActionQuery";
import SearchActionQuery from "../components/actionquery/SearchActionQuery";
import EditAttrAccess from "../components/attraccess/EditAttrAccess";
import SearchAttrAccess from "../components/attraccess/SearchAttrAccess";
import EditAttribute from "../components/attribute/EditAttribute";
import SearchAttribute from "../components/attribute/SearchAttribute";
import EditBilling from "../components/billing/EditBilling";
import SearchBilling from "../components/billing/SearchBilling";
import EditBoard from "../components/board/EditBoard";
import SearchBoard from "../components/board/SearchBoard";
import EditCloudConfig from "../components/cloudconfig/EditCloudConfig";
import SearchCloudConfig from "../components/cloudconfig/SearchCloudConfig";
import EditCodeAttribute from "../components/codeattribute/EditCodeAttribute";
import SearchCodeAttribute from "../components/codeattribute/SearchCodeAttribute";
import EditComments from "../components/comments/EditComments";
import SearchComments from "../components/comments/SearchComments";
import EditCustomFields from "../components/customfields/EditCustomFields";
import SearchCustomFields from "../components/customfields/SearchCustomFields";
import EditDefect from "../components/defect/EditDefect";
import SearchDefect from "../components/defect/SearchDefect";
import EditDefectDocument from "../components/defectdocument/EditDefectDocument";
import SearchDefectDocument from "../components/defectdocument/SearchDefectDocument";
import EditDockerImage from "../components/dockerimage/EditDockerImage";
import SearchDockerImage from "../components/dockerimage/SearchDockerImage";
import EditEmailSetting from "../components/emailsetting/EditEmailSetting";
import SearchEmailSetting from "../components/emailsetting/SearchEmailSetting";
import EditEpic from "../components/epic/EditEpic";
import SearchEpic from "../components/epic/SearchEpic";
import EditFeature from "../components/feature/EditFeature";
import SearchFeature from "../components/feature/SearchFeature";
import EditFeatureDocument from "../components/featuredocument/EditFeatureDocument";
import SearchFeatureDocument from "../components/featuredocument/SearchFeatureDocument";
import EditGenericCode from "../components/genericcode/EditGenericCode";
import SearchGenericCode from "../components/genericcode/SearchGenericCode";
import EditGitRepo from "../components/gitrepo/EditGitRepo";
import SearchGitRepo from "../components/gitrepo/SearchGitRepo";
import EditGroupLicense from "../components/grouplicense/EditGroupLicense";
import SearchGroupLicense from "../components/grouplicense/SearchGroupLicense";
import EditInstallLicense from "../components/installlicense/EditInstallLicense";
import SearchInstallLicense from "../components/installlicense/SearchInstallLicense";
import EditIssues from "../components/issues/EditIssues";
import SearchIssues from "../components/issues/SearchIssues";
import EditListProperty from "../components/listproperty/EditListProperty";
import SearchListProperty from "../components/listproperty/SearchListProperty";
import EditMember from "../components/member/EditMember";
import SearchMember from "../components/member/SearchMember";
import EditModule from "../components/module/EditModule";
import SearchModule from "../components/module/SearchModule";
import EditModuleLicense from "../components/modulelicense/EditModuleLicense";
import SearchModuleLicense from "../components/modulelicense/SearchModuleLicense";
import EditNotes from "../components/notes/EditNotes";
import SearchNotes from "../components/notes/SearchNotes";
import EditObject from "../components/object/EditObject";
import SearchObject from "../components/object/SearchObject";
import EditObjectAccess from "../components/objectaccess/EditObjectAccess";
import SearchObjectAccess from "../components/objectaccess/SearchObjectAccess";
import EditObjectRule from "../components/objectrule/EditObjectRule";
import SearchObjectRule from "../components/objectrule/SearchObjectRule";
import EditProduct from "../components/product/EditProduct";
import SearchProduct from "../components/product/SearchProduct";
import EditProfile from "../components/profile/EditProfile";
import SearchProfile from "../components/profile/SearchProfile";
import EditProject from "../components/project/EditProject";
import SearchProject from "../components/project/SearchProject";
import EditRelease from "../components/release/EditRelease";
import SearchRelease from "../components/release/SearchRelease";
import EditRunSchedule from "../components/runschedule/EditRunSchedule";
import SearchRunSchedule from "../components/runschedule/SearchRunSchedule";
import EditRunTag from "../components/runtag/EditRunTag";
import SearchRunTag from "../components/runtag/SearchRunTag";
import EditScenario from "../components/scenario/EditScenario";
import SearchScenario from "../components/scenario/SearchScenario";
import EditSprint from "../components/sprint/EditSprint";
import SearchSprint from "../components/sprint/SearchSprint";
import EditSteps from "../components/steps/EditSteps";
import SearchSteps from "../components/steps/SearchSteps";
import EditSubscription from "../components/subscription/EditSubscription";
import SearchSubscription from "../components/subscription/SearchSubscription";
import EditSubTask from "../components/subtask/EditSubTask";
import SearchSubTask from "../components/subtask/SearchSubTask";
import EditSuiteTag from "../components/suitetag/EditSuiteTag";
import SearchSuiteTag from "../components/suitetag/SearchSuiteTag";
import EditTag from "../components/tag/EditTag";
import SearchTag from "../components/tag/SearchTag";
import EditTaskDocument from "../components/taskdocument/EditTaskDocument";
import SearchTaskDocument from "../components/taskdocument/SearchTaskDocument";
import EditTasks from "../components/tasks/EditTasks";
import SearchTasks from "../components/tasks/SearchTasks";
import EditTestCycle from "../components/testcycle/EditTestCycle";
import SearchTestCycle from "../components/testcycle/SearchTestCycle";
import EditTestFailure from "../components/testfailure/EditTestFailure";
import SearchTestFailure from "../components/testfailure/SearchTestFailure";
import EditTestLog from "../components/testlog/EditTestLog";
import SearchTestLog from "../components/testlog/SearchTestLog";
import EditTestReport from "../components/testreport/EditTestReport";
import SearchTestReport from "../components/testreport/SearchTestReport";
import EditTestResult from "../components/testresult/EditTestResult";
import SearchTestResult from "../components/testresult/SearchTestResult";
import EditTestSuite from "../components/testsuite/EditTestSuite";
import SearchTestSuite from "../components/testsuite/SearchTestSuite";
import EditTimesheet from "../components/timesheet/EditTimesheet";
import SearchTimesheet from "../components/timesheet/SearchTimesheet";
import EditUser from "../components/user/EditUser";
import SearchUser from "../components/user/SearchUser";
import EditUserGroup from "../components/usergroup/EditUserGroup";
import SearchUserGroup from "../components/usergroup/SearchUserGroup";
import EditUserLicense from "../components/userlicense/EditUserLicense";
import SearchUserLicense from "../components/userlicense/SearchUserLicense";
function Setup({
  command: propCommand,
  accountId: propAccountId,
  actionqueryId: propActionQueryId,
  attraccessId: propAttrAccessId,
  attributeId: propAttributeId,
  billingId: propBillingId,
  boardId: propBoardId,
  cloudconfigId: propCloudConfigId,
  codeattributeId: propCodeAttributeId,
  commentsId: propCommentsId,
  customfieldsId: propCustomFieldsId,
  defectId: propDefectId,
  defectdocumentId: propDefectDocumentId,
  dockerimageId: propDockerImageId,
  emailsettingId: propEmailSettingId,
  epicId: propEpicId,
  featureId: propFeatureId,
  featuredocumentId: propFeatureDocumentId,
  genericcodeId: propGenericCodeId,
  gitrepoId: propGitRepoId,
  grouplicenseId: propGroupLicenseId,
  installlicenseId: propInstallLicenseId,
  issuesId: propIssuesId,
  listpropertyId: propListPropertyId,
  memberId: propMemberId,
  moduleId: propModuleId,
  modulelicenseId: propModuleLicenseId,
  notesId: propNotesId,
  objectId: propObjectId,
  objectaccessId: propObjectAccessId,
  objectruleId: propObjectRuleId,
  productId: propProductId,
  profileId: propProfileId,
  projectId: propProjectId,
  releaseId: propReleaseId,
  runscheduleId: propRunScheduleId,
  runtagId: propRunTagId,
  scenarioId: propScenarioId,
  sprintId: propSprintId,
  stepsId: propStepsId,
  subscriptionId: propSubscriptionId,
  subtaskId: propSubTaskId,
  suitetagId: propSuiteTagId,
  tagId: propTagId,
  taskdocumentId: propTaskDocumentId,
  tasksId: propTasksId,
  testcycleId: propTestCycleId,
  testfailureId: propTestFailureId,
  testlogId: propTestLogId,
  testreportId: propTestReportId,
  testresultId: propTestResultId,
  testsuiteId: propTestSuiteId,
  timesheetId: propTimesheetId,
  userId: propUserId,
  usergroupId: propUserGroupId,
  userlicenseId: propUserLicenseId,
  activeDashboard, // Add the activeDashboard prop
}) {
  const { command: urlCommand } = useParams();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(propCommand || urlCommand);
  const [accountId, setAccountId] = useState(propAccountId);
  const [actionqueryId, setActionQueryId] = useState(propActionQueryId);
  const [attraccessId, setAttrAccessId] = useState(propAttrAccessId);
  const [attributeId, setAttributeId] = useState(propAttributeId);
  const [billingId, setBillingId] = useState(propBillingId);
  const [boardId, setBoardId] = useState(propBoardId);
  const [cloudconfigId, setCloudConfigId] = useState(propCloudConfigId);
  const [codeattributeId, setCodeAttributeId] = useState(propCodeAttributeId);
  const [commentsId, setCommentsId] = useState(propCommentsId);
  const [customfieldsId, setCustomFieldsId] = useState(propCustomFieldsId);
  const [defectId, setDefectId] = useState(propDefectId);
  const [defectdocumentId, setDefectDocumentId] =
    useState(propDefectDocumentId);
  const [dockerimageId, setDockerImageId] = useState(propDockerImageId);
  const [emailsettingId, setEmailSettingId] = useState(propEmailSettingId);
  const [epicId, setEpicId] = useState(propEpicId);
  const [featureId, setFeatureId] = useState(propFeatureId);
  const [featuredocumentId, setFeatureDocumentId] = useState(
    propFeatureDocumentId
  );
  const [genericcodeId, setGenericCodeId] = useState(propGenericCodeId);
  const [gitrepoId, setGitRepoId] = useState(propGitRepoId);
  const [grouplicenseId, setGroupLicenseId] = useState(propGroupLicenseId);
  const [installlicenseId, setInstallLicenseId] =
    useState(propInstallLicenseId);
  const [issuesId, setIssuesId] = useState(propIssuesId);
  const [listpropertyId, setListPropertyId] = useState(propListPropertyId);
  const [memberId, setMemberId] = useState(propMemberId);
  const [moduleId, setModuleId] = useState(propModuleId);
  const [modulelicenseId, setModuleLicenseId] = useState(propModuleLicenseId);
  const [notesId, setNotesId] = useState(propNotesId);
  const [objectId, setObjectId] = useState(propObjectId);
  const [objectaccessId, setObjectAccessId] = useState(propObjectAccessId);
  const [objectruleId, setObjectRuleId] = useState(propObjectRuleId);
  const [productId, setProductId] = useState(propProductId);
  const [profileId, setProfileId] = useState(propProfileId);
  const [projectId, setProjectId] = useState(propProjectId);
  const [releaseId, setReleaseId] = useState(propReleaseId);
  const [runscheduleId, setRunScheduleId] = useState(propRunScheduleId);
  const [runtagId, setRunTagId] = useState(propRunTagId);
  const [scenarioId, setScenarioId] = useState(propScenarioId);
  const [sprintId, setSprintId] = useState(propSprintId);
  const [stepsId, setStepsId] = useState(propStepsId);
  const [subscriptionId, setSubscriptionId] = useState(propSubscriptionId);
  const [subtaskId, setSubTaskId] = useState(propSubTaskId);
  const [suitetagId, setSuiteTagId] = useState(propSuiteTagId);
  const [tagId, setTagId] = useState(propTagId);
  const [taskdocumentId, setTaskDocumentId] = useState(propTaskDocumentId);
  const [tasksId, setTasksId] = useState(propTasksId);
  const [testcycleId, setTestCycleId] = useState(propTestCycleId);
  const [testfailureId, setTestFailureId] = useState(propTestFailureId);
  const [testlogId, setTestLogId] = useState(propTestLogId);
  const [testreportId, setTestReportId] = useState(propTestReportId);
  const [testresultId, setTestResultId] = useState(propTestResultId);
  const [testsuiteId, setTestSuiteId] = useState(propTestSuiteId);
  const [timesheetId, setTimesheetId] = useState(propTimesheetId);
  const [userId, setUserId] = useState(propUserId);
  const [usergroupId, setUserGroupId] = useState(propUserGroupId);
  const [userlicenseId, setUserLicenseId] = useState(propUserLicenseId);
  useEffect(() => {
    if (propCommand && propCommand.includes("account")) {
      setAccountId(propAccountId);
    } else if (propCommand && propCommand.includes("actionquery")) {
      setActionQueryId(propActionQueryId);
    } else if (propCommand && propCommand.includes("attraccess")) {
      setAttrAccessId(propAttrAccessId);
    } else if (propCommand && propCommand.includes("attribute")) {
      setAttributeId(propAttributeId);
    } else if (propCommand && propCommand.includes("billing")) {
      setBillingId(propBillingId);
    } else if (propCommand && propCommand.includes("board")) {
      setBoardId(propBoardId);
    } else if (propCommand && propCommand.includes("cloudconfig")) {
      setCloudConfigId(propCloudConfigId);
    } else if (propCommand && propCommand.includes("codeattribute")) {
      setCodeAttributeId(propCodeAttributeId);
    } else if (propCommand && propCommand.includes("comments")) {
      setCommentsId(propCommentsId);
    } else if (propCommand && propCommand.includes("customfields")) {
      setCustomFieldsId(propCustomFieldsId);
    } else if (propCommand && propCommand.includes("defect")) {
      setDefectId(propDefectId);
    } else if (propCommand && propCommand.includes("defectdocument")) {
      setDefectDocumentId(propDefectDocumentId);
    } else if (propCommand && propCommand.includes("dockerimage")) {
      setDockerImageId(propDockerImageId);
    } else if (propCommand && propCommand.includes("emailsetting")) {
      setEmailSettingId(propEmailSettingId);
    } else if (propCommand && propCommand.includes("epic")) {
      setEpicId(propEpicId);
    } else if (propCommand && propCommand.includes("feature")) {
      setFeatureId(propFeatureId);
    } else if (propCommand && propCommand.includes("featuredocument")) {
      setFeatureDocumentId(propFeatureDocumentId);
    } else if (propCommand && propCommand.includes("genericcode")) {
      setGenericCodeId(propGenericCodeId);
    } else if (propCommand && propCommand.includes("gitrepo")) {
      setGitRepoId(propGitRepoId);
    } else if (propCommand && propCommand.includes("grouplicense")) {
      setGroupLicenseId(propGroupLicenseId);
    } else if (propCommand && propCommand.includes("installlicense")) {
      setInstallLicenseId(propInstallLicenseId);
    } else if (propCommand && propCommand.includes("issues")) {
      setIssuesId(propIssuesId);
    } else if (propCommand && propCommand.includes("listproperty")) {
      setListPropertyId(propListPropertyId);
    } else if (propCommand && propCommand.includes("member")) {
      setMemberId(propMemberId);
    } else if (propCommand && propCommand.includes("module")) {
      setModuleId(propModuleId);
    } else if (propCommand && propCommand.includes("modulelicense")) {
      setModuleLicenseId(propModuleLicenseId);
    } else if (propCommand && propCommand.includes("notes")) {
      setNotesId(propNotesId);
    } else if (propCommand && propCommand.includes("object")) {
      setObjectId(propObjectId);
    } else if (propCommand && propCommand.includes("objectaccess")) {
      setObjectAccessId(propObjectAccessId);
    } else if (propCommand && propCommand.includes("objectrule")) {
      setObjectRuleId(propObjectRuleId);
    } else if (propCommand && propCommand.includes("product")) {
      setProductId(propProductId);
    } else if (propCommand && propCommand.includes("profile")) {
      setProfileId(propProfileId);
    } else if (propCommand && propCommand.includes("project")) {
      setProjectId(propProjectId);
    } else if (propCommand && propCommand.includes("release")) {
      setReleaseId(propReleaseId);
    } else if (propCommand && propCommand.includes("runschedule")) {
      setRunScheduleId(propRunScheduleId);
    } else if (propCommand && propCommand.includes("runtag")) {
      setRunTagId(propRunTagId);
    } else if (propCommand && propCommand.includes("scenario")) {
      setScenarioId(propScenarioId);
    } else if (propCommand && propCommand.includes("sprint")) {
      setSprintId(propSprintId);
    } else if (propCommand && propCommand.includes("steps")) {
      setStepsId(propStepsId);
    } else if (propCommand && propCommand.includes("subscription")) {
      setSubscriptionId(propSubscriptionId);
    } else if (propCommand && propCommand.includes("subtask")) {
      setSubTaskId(propSubTaskId);
    } else if (propCommand && propCommand.includes("suitetag")) {
      setSuiteTagId(propSuiteTagId);
    } else if (propCommand && propCommand.includes("tag")) {
      setTagId(propTagId);
    } else if (propCommand && propCommand.includes("taskdocument")) {
      setTaskDocumentId(propTaskDocumentId);
    } else if (propCommand && propCommand.includes("tasks")) {
      setTasksId(propTasksId);
    } else if (propCommand && propCommand.includes("testcycle")) {
      setTestCycleId(propTestCycleId);
    } else if (propCommand && propCommand.includes("testfailure")) {
      setTestFailureId(propTestFailureId);
    } else if (propCommand && propCommand.includes("testlog")) {
      setTestLogId(propTestLogId);
    } else if (propCommand && propCommand.includes("testreport")) {
      setTestReportId(propTestReportId);
    } else if (propCommand && propCommand.includes("testresult")) {
      setTestResultId(propTestResultId);
    } else if (propCommand && propCommand.includes("testsuite")) {
      setTestSuiteId(propTestSuiteId);
    } else if (propCommand && propCommand.includes("timesheet")) {
      setTimesheetId(propTimesheetId);
    } else if (propCommand && propCommand.includes("user")) {
      setUserId(propUserId);
    } else if (propCommand && propCommand.includes("usergroup")) {
      setUserGroupId(propUserGroupId);
    } else if (propCommand && propCommand.includes("userlicense")) {
      setUserLicenseId(propUserLicenseId);
    }
  }, [
    propCommand,
    propAccountId,
    propActionQueryId,
    propAttrAccessId,
    propAttributeId,
    propBillingId,
    propBoardId,
    propCloudConfigId,
    propCodeAttributeId,
    propCommentsId,
    propCustomFieldsId,
    propDefectId,
    propDefectDocumentId,
    propDockerImageId,
    propEmailSettingId,
    propEpicId,
    propFeatureId,
    propFeatureDocumentId,
    propGenericCodeId,
    propGitRepoId,
    propGroupLicenseId,
    propInstallLicenseId,
    propIssuesId,
    propListPropertyId,
    propMemberId,
    propModuleId,
    propModuleLicenseId,
    propNotesId,
    propObjectId,
    propObjectAccessId,
    propObjectRuleId,
    propProductId,
    propProfileId,
    propProjectId,
    propReleaseId,
    propRunScheduleId,
    propRunTagId,
    propScenarioId,
    propSprintId,
    propStepsId,
    propSubscriptionId,
    propSubTaskId,
    propSuiteTagId,
    propTagId,
    propTaskDocumentId,
    propTasksId,
    propTestCycleId,
    propTestFailureId,
    propTestLogId,
    propTestReportId,
    propTestResultId,
    propTestSuiteId,
    propTimesheetId,
    propUserId,
    propUserGroupId,
    propUserLicenseId,
  ]);
  useEffect(() => {
    setActiveSection(propCommand || urlCommand);
  }, [propCommand, urlCommand]);

  const handleSectionClick = (section) => {
    if (activeDashboard === "account") {
      if (activeSection === section) {
        navigate(`/setup/${section}?timestamp=${Date.now()}`);
      } else {
        setActiveSection(section);
        navigate(`/setup/${section}`);
      }
    } else if (activeDashboard === "mytasks") {
      if (activeSection === section) {
        navigate(`/mytasks/${section}?timestamp=${Date.now()}`);
      } else {
        setActiveSection(section);
        navigate(`/mytasks/${section}`);
      }
    } else if (activeDashboard === "features") {
      if (activeSection === section) {
        navigate(`/features/${section}?timestamp=${Date.now()}`);
      } else {
        setActiveSection(section);
        navigate(`/features/${section}`);
      }
    } else if (activeDashboard === "testing") {
      if (activeSection === section) {
        navigate(`/testing/${section}?timestamp=${Date.now()}`);
      } else {
        setActiveSection(section);
        navigate(`/testing/${section}`);
      }
    } else if (activeDashboard === "defects") {
      if (activeSection === section) {
        navigate(`/defects/${section}?timestamp=${Date.now()}`);
      } else {
        setActiveSection(section);
        navigate(`/defects/${section}`);
      }
    }
  };
  return (
    <div className="dashboard-container">
      {activeDashboard === "account" && (
        <div className="dashboard-sections">
          <ul>
            <h4>Account</h4>{" "}
            <li onClick={() => handleSectionClick("account")}>Account</li>
            {/* <li onClick={() => handleSectionClick("subscription")}>
              Subscription
            </li> */}
            <h4>Product</h4>{" "}
            <li onClick={() => handleSectionClick("product")}>Product</li>
            <li onClick={() => handleSectionClick("project")}>Project</li>
            <li onClick={() => handleSectionClick("release")}>Release</li>
            <h4>Config</h4>{" "}
            <li onClick={() => handleSectionClick("customfields")}>
              Custom Fields
            </li>
            <li onClick={() => handleSectionClick("cloudconfig")}>
              Cloud Config
            </li>
          </ul>
        </div>
      )}
      {activeDashboard === "mytasks" && (
        <div className="dashboard-sections">
          <ul>
            <li onClick={() => handleSectionClick("tasks")}>Tasks</li>
            <li onClick={() => handleSectionClick("subtask")}>Subtasks</li>
            <li onClick={() => handleSectionClick("board")}>Board</li>
            {/* <li onClick={() => handleSectionClick("profile")}>My Profile</li> */}
          </ul>
        </div>
      )}
      {activeDashboard === "features" && (
        <div className="dashboard-sections">
          <ul>
            <li onClick={() => handleSectionClick("epic")}>Epic</li>
            <li onClick={() => handleSectionClick("feature")}>Feature</li>
            <li onClick={() => handleSectionClick("scenario")}>Scenario</li>
            <li onClick={() => handleSectionClick("steps")}>Steps</li>
          </ul>
        </div>
      )}
      {activeDashboard === "testing" && (
        <div className="dashboard-sections">
          <ul>
            <li onClick={() => handleSectionClick("tag")}>Add Tag</li>
            <li onClick={() => handleSectionClick("testcycle")}>Test Cycle</li>
            <li onClick={() => handleSectionClick("dockerimage")}>
              Docker Image
            </li>
            <li onClick={() => handleSectionClick("testsuite")}>Test Suite</li>
            <li onClick={() => handleSectionClick("testreport")}>
              Test Report
            </li>
            <li onClick={() => handleSectionClick("testresult")}>
              Test Result
            </li>
          </ul>
        </div>
      )}
      {activeDashboard === "defects" && (
        <div className="dashboard-sections">
          <ul>
            <li onClick={() => handleSectionClick("defect")}>Defect</li>
            <li onClick={() => handleSectionClick("testfailure")}>
              Test Failures
            </li>
          </ul>
        </div>
      )}
      <div className="dashboard-content">
        {activeSection === "setupdetail" && (
          <div id="setupdetail">
            <SetupDetail />
          </div>
        )}
        {activeSection === "taskdetail" && (
          <div id="taskdetail">
            <MyTasks />
          </div>
        )}
        {activeSection === "featuredetail" && (
          <div id="featuredetail">
            <MyFeatures />
          </div>
        )}
        {activeSection === "testingdetail" && (
          <div id="testingdetail">
            <TestResultGraph />
            <TestSummary />
          </div>
        )}
        {activeSection === "defectdetail" && (
          <div id="defectdetail">
            <MyDefects />
          </div>
        )}
        {activeSection === "account" && (
          <div id="account">
            <h3>Account</h3>
            <SearchAccount setAccountId={setAccountId} />
          </div>
        )}

        {/* {activeSection === "account" && (
          <div id="account">
            <h3>Account</h3>
            <SearchAccount setAccountId={setAccountId} />
          </div>
        )} */}

        {activeSection === "editaccount" && (
          <div id="editaccount">
            <h3>Account</h3>
            <EditAccount accountId={accountId} />
          </div>
        )}

        {activeSection === "actionquery" && (
          <div id="actionquery">
            <h3>ActionQuery</h3>
            <SearchActionQuery setActionQueryId={setActionQueryId} />
          </div>
        )}

        {activeSection === "editactionquery" && (
          <div id="editactionquery">
            <h3>ActionQuery</h3>
            <EditActionQuery actionqueryId={actionqueryId} />
          </div>
        )}

        {activeSection === "attraccess" && (
          <div id="attraccess">
            <h3>AttrAccess</h3>
            <SearchAttrAccess setAttrAccessId={setAttrAccessId} />
          </div>
        )}

        {activeSection === "editattraccess" && (
          <div id="editattraccess">
            <h3>AttrAccess</h3>
            <EditAttrAccess attraccessId={attraccessId} />
          </div>
        )}

        {activeSection === "attribute" && (
          <div id="attribute">
            <h3>Attribute</h3>
            <SearchAttribute setAttributeId={setAttributeId} />
          </div>
        )}

        {activeSection === "editattribute" && (
          <div id="editattribute">
            <h3>Attribute</h3>
            <EditAttribute attributeId={attributeId} />
          </div>
        )}

        {activeSection === "billing" && (
          <div id="billing">
            <h3>Billing</h3>
            <SearchBilling setBillingId={setBillingId} />
          </div>
        )}

        {activeSection === "editbilling" && (
          <div id="editbilling">
            <h3>Billing</h3>
            <EditBilling billingId={billingId} />
          </div>
        )}

        {activeSection === "board" && (
          <div id="board">
            <h3>Board</h3>
            <SearchBoard setBoardId={setBoardId} />
          </div>
        )}

        {activeSection === "editboard" && (
          <div id="editboard">
            <h3>Board</h3>
            <EditBoard boardId={boardId} />
          </div>
        )}

        {activeSection === "cloudconfig" && (
          <div id="cloudconfig">
            <h3>CloudConfig</h3>
            <SearchCloudConfig setCloudConfigId={setCloudConfigId} />
          </div>
        )}

        {activeSection === "editcloudconfig" && (
          <div id="editcloudconfig">
            <h3>CloudConfig</h3>
            <EditCloudConfig cloudconfigId={cloudconfigId} />
          </div>
        )}

        {activeSection === "codeattribute" && (
          <div id="codeattribute">
            <h3>CodeAttribute</h3>
            <SearchCodeAttribute setCodeAttributeId={setCodeAttributeId} />
          </div>
        )}

        {activeSection === "editcodeattribute" && (
          <div id="editcodeattribute">
            <h3>CodeAttribute</h3>
            <EditCodeAttribute codeattributeId={codeattributeId} />
          </div>
        )}

        {activeSection === "comments" && (
          <div id="comments">
            <h3>Comments</h3>
            <SearchComments setCommentsId={setCommentsId} />
          </div>
        )}

        {activeSection === "editcomments" && (
          <div id="editcomments">
            <h3>Comments</h3>
            <EditComments commentsId={commentsId} />
          </div>
        )}

        {activeSection === "customfields" && (
          <div id="customfields">
            <h3>CustomFields</h3>
            <SearchCustomFields setCustomFieldsId={setCustomFieldsId} />
          </div>
        )}

        {activeSection === "editcustomfields" && (
          <div id="editcustomfields">
            <h3>CustomFields</h3>
            <EditCustomFields customfieldsId={customfieldsId} />
          </div>
        )}

        {activeSection === "defect" && (
          <div id="defect">
            <h3>Defect</h3>
            <SearchDefect setDefectId={setDefectId} />
          </div>
        )}

        {activeSection === "editdefect" && (
          <div id="editdefect">
            <h3>Defect</h3>
            <EditDefect defectId={defectId} />
          </div>
        )}

        {activeSection === "defectdocument" && (
          <div id="defectdocument">
            <h3>DefectDocument</h3>
            <SearchDefectDocument setDefectDocumentId={setDefectDocumentId} />
          </div>
        )}

        {activeSection === "editdefectdocument" && (
          <div id="editdefectdocument">
            <h3>DefectDocument</h3>
            <EditDefectDocument defectdocumentId={defectdocumentId} />
          </div>
        )}

        {activeSection === "dockerimage" && (
          <div id="dockerimage">
            <h3>DockerImage</h3>
            <SearchDockerImage setDockerImageId={setDockerImageId} />
          </div>
        )}

        {activeSection === "editdockerimage" && (
          <div id="editdockerimage">
            <h3>DockerImage</h3>
            <EditDockerImage dockerimageId={dockerimageId} />
          </div>
        )}

        {activeSection === "emailsetting" && (
          <div id="emailsetting">
            <h3>EmailSetting</h3>
            <SearchEmailSetting setEmailSettingId={setEmailSettingId} />
          </div>
        )}

        {activeSection === "editemailsetting" && (
          <div id="editemailsetting">
            <h3>EmailSetting</h3>
            <EditEmailSetting emailsettingId={emailsettingId} />
          </div>
        )}

        {activeSection === "epic" && (
          <div id="epic">
            <h3>Epic</h3>
            <SearchEpic setEpicId={setEpicId} />
          </div>
        )}

        {activeSection === "editepic" && (
          <div id="editepic">
            <h3>Epic</h3>
            <EditEpic epicId={epicId} />
          </div>
        )}

        {activeSection === "feature" && (
          <div id="feature">
            <h3>Feature</h3>
            <SearchFeature setFeatureId={setFeatureId} />
          </div>
        )}

        {activeSection === "editfeature" && (
          <div id="editfeature">
            <h3>Feature</h3>
            <EditFeature featureId={featureId} />
          </div>
        )}

        {activeSection === "featuredocument" && (
          <div id="featuredocument">
            <h3>FeatureDocument</h3>
            <SearchFeatureDocument
              setFeatureDocumentId={setFeatureDocumentId}
            />
          </div>
        )}

        {activeSection === "editfeaturedocument" && (
          <div id="editfeaturedocument">
            <h3>FeatureDocument</h3>
            <EditFeatureDocument featuredocumentId={featuredocumentId} />
          </div>
        )}

        {activeSection === "genericcode" && (
          <div id="genericcode">
            <h3>GenericCode</h3>
            <SearchGenericCode setGenericCodeId={setGenericCodeId} />
          </div>
        )}

        {activeSection === "editgenericcode" && (
          <div id="editgenericcode">
            <h3>GenericCode</h3>
            <EditGenericCode genericcodeId={genericcodeId} />
          </div>
        )}

        {activeSection === "gitrepo" && (
          <div id="gitrepo">
            <h3>GitRepo</h3>
            <SearchGitRepo setGitRepoId={setGitRepoId} />
          </div>
        )}

        {activeSection === "editgitrepo" && (
          <div id="editgitrepo">
            <h3>GitRepo</h3>
            <EditGitRepo gitrepoId={gitrepoId} />
          </div>
        )}

        {activeSection === "grouplicense" && (
          <div id="grouplicense">
            <h3>GroupLicense</h3>
            <SearchGroupLicense setGroupLicenseId={setGroupLicenseId} />
          </div>
        )}

        {activeSection === "editgrouplicense" && (
          <div id="editgrouplicense">
            <h3>GroupLicense</h3>
            <EditGroupLicense grouplicenseId={grouplicenseId} />
          </div>
        )}

        {activeSection === "installlicense" && (
          <div id="installlicense">
            <h3>InstallLicense</h3>
            <SearchInstallLicense setInstallLicenseId={setInstallLicenseId} />
          </div>
        )}

        {activeSection === "editinstalllicense" && (
          <div id="editinstalllicense">
            <h3>InstallLicense</h3>
            <EditInstallLicense installlicenseId={installlicenseId} />
          </div>
        )}

        {activeSection === "issues" && (
          <div id="issues">
            <h3>Issues</h3>
            <SearchIssues setIssuesId={setIssuesId} />
          </div>
        )}

        {activeSection === "editissues" && (
          <div id="editissues">
            <h3>Issues</h3>
            <EditIssues issuesId={issuesId} />
          </div>
        )}

        {activeSection === "listproperty" && (
          <div id="listproperty">
            <h3>ListProperty</h3>
            <SearchListProperty setListPropertyId={setListPropertyId} />
          </div>
        )}

        {activeSection === "editlistproperty" && (
          <div id="editlistproperty">
            <h3>ListProperty</h3>
            <EditListProperty listpropertyId={listpropertyId} />
          </div>
        )}

        {activeSection === "member" && (
          <div id="member">
            <h3>Member</h3>
            <SearchMember setMemberId={setMemberId} />
          </div>
        )}

        {activeSection === "editmember" && (
          <div id="editmember">
            <h3>Member</h3>
            <EditMember memberId={memberId} />
          </div>
        )}

        {activeSection === "module" && (
          <div id="module">
            <h3>Module</h3>
            <SearchModule setModuleId={setModuleId} />
          </div>
        )}

        {activeSection === "editmodule" && (
          <div id="editmodule">
            <h3>Module</h3>
            <EditModule moduleId={moduleId} />
          </div>
        )}

        {activeSection === "modulelicense" && (
          <div id="modulelicense">
            <h3>ModuleLicense</h3>
            <SearchModuleLicense setModuleLicenseId={setModuleLicenseId} />
          </div>
        )}

        {activeSection === "editmodulelicense" && (
          <div id="editmodulelicense">
            <h3>ModuleLicense</h3>
            <EditModuleLicense modulelicenseId={modulelicenseId} />
          </div>
        )}

        {activeSection === "notes" && (
          <div id="notes">
            <h3>Notes</h3>
            <SearchNotes setNotesId={setNotesId} />
          </div>
        )}

        {activeSection === "editnotes" && (
          <div id="editnotes">
            <h3>Notes</h3>
            <EditNotes notesId={notesId} />
          </div>
        )}

        {activeSection === "object" && (
          <div id="object">
            <h3>Object</h3>
            <SearchObject setObjectId={setObjectId} />
          </div>
        )}

        {activeSection === "editobject" && (
          <div id="editobject">
            <h3>Object</h3>
            <EditObject objectId={objectId} />
          </div>
        )}

        {activeSection === "objectaccess" && (
          <div id="objectaccess">
            <h3>ObjectAccess</h3>
            <SearchObjectAccess setObjectAccessId={setObjectAccessId} />
          </div>
        )}

        {activeSection === "editobjectaccess" && (
          <div id="editobjectaccess">
            <h3>ObjectAccess</h3>
            <EditObjectAccess objectaccessId={objectaccessId} />
          </div>
        )}

        {activeSection === "objectrule" && (
          <div id="objectrule">
            <h3>ObjectRule</h3>
            <SearchObjectRule setObjectRuleId={setObjectRuleId} />
          </div>
        )}

        {activeSection === "editobjectrule" && (
          <div id="editobjectrule">
            <h3>ObjectRule</h3>
            <EditObjectRule objectruleId={objectruleId} />
          </div>
        )}

        {activeSection === "product" && (
          <div id="product">
            <h3>Product</h3>
            <SearchProduct setProductId={setProductId} />
          </div>
        )}

        {activeSection === "editproduct" && (
          <div id="editproduct">
            <h3>Product</h3>
            <EditProduct productId={productId} />
          </div>
        )}

        {activeSection === "profile" && (
          <div id="profile">
            <h3>Profile</h3>
            <SearchProfile setProfileId={setProfileId} />
          </div>
        )}

        {activeSection === "editprofile" && (
          <div id="editprofile">
            <h3>Profile</h3>
            <EditProfile profileId={profileId} />
          </div>
        )}

        {activeSection === "project" && (
          <div id="project">
            <h3>Project</h3>
            <SearchProject setProjectId={setProjectId} />
          </div>
        )}

        {activeSection === "editproject" && (
          <div id="editproject">
            <h3>Project</h3>
            <EditProject projectId={projectId} />
          </div>
        )}

        {activeSection === "release" && (
          <div id="release">
            <h3>Release</h3>
            <SearchRelease setReleaseId={setReleaseId} />
          </div>
        )}

        {activeSection === "editrelease" && (
          <div id="editrelease">
            <h3>Release</h3>
            <EditRelease releaseId={releaseId} />
          </div>
        )}

        {activeSection === "runschedule" && (
          <div id="runschedule">
            <h3>RunSchedule</h3>
            <SearchRunSchedule setRunScheduleId={setRunScheduleId} />
          </div>
        )}

        {activeSection === "editrunschedule" && (
          <div id="editrunschedule">
            <h3>RunSchedule</h3>
            <EditRunSchedule runscheduleId={runscheduleId} />
          </div>
        )}

        {activeSection === "runtag" && (
          <div id="runtag">
            <h3>RunTag</h3>
            <SearchRunTag setRunTagId={setRunTagId} />
          </div>
        )}

        {activeSection === "editruntag" && (
          <div id="editruntag">
            <h3>RunTag</h3>
            <EditRunTag runtagId={runtagId} />
          </div>
        )}

        {activeSection === "scenario" && (
          <div id="scenario">
            <h3>Scenario</h3>
            <SearchScenario setScenarioId={setScenarioId} />
          </div>
        )}

        {activeSection === "editscenario" && (
          <div id="editscenario">
            <h3>Scenario</h3>
            <EditScenario scenarioId={scenarioId} />
          </div>
        )}

        {activeSection === "sprint" && (
          <div id="sprint">
            <h3>Sprint</h3>
            <SearchSprint setSprintId={setSprintId} />
          </div>
        )}

        {activeSection === "editsprint" && (
          <div id="editsprint">
            <h3>Sprint</h3>
            <EditSprint sprintId={sprintId} />
          </div>
        )}

        {activeSection === "steps" && (
          <div id="steps">
            <h3>Steps</h3>
            <SearchSteps setStepsId={setStepsId} />
          </div>
        )}

        {activeSection === "editsteps" && (
          <div id="editsteps">
            <h3>Steps</h3>
            <EditSteps stepsId={stepsId} />
          </div>
        )}

        {activeSection === "subscription" && (
          <div id="subscription">
            <h3>Subscription</h3>
            <SearchSubscription setSubscriptionId={setSubscriptionId} />
          </div>
        )}

        {activeSection === "editsubscription" && (
          <div id="editsubscription">
            <h3>Subscription</h3>
            <EditSubscription subscriptionId={subscriptionId} />
          </div>
        )}

        {activeSection === "subtask" && (
          <div id="subtask">
            <h3>SubTask</h3>
            <SearchSubTask setSubTaskId={setSubTaskId} />
          </div>
        )}

        {activeSection === "editsubtask" && (
          <div id="editsubtask">
            <h3>SubTask</h3>
            <EditSubTask subtaskId={subtaskId} />
          </div>
        )}

        {activeSection === "suitetag" && (
          <div id="suitetag">
            <h3>SuiteTag</h3>
            <SearchSuiteTag setSuiteTagId={setSuiteTagId} />
          </div>
        )}

        {activeSection === "editsuitetag" && (
          <div id="editsuitetag">
            <h3>SuiteTag</h3>
            <EditSuiteTag suitetagId={suitetagId} />
          </div>
        )}

        {activeSection === "tag" && (
          <div id="tag">
            <h3>Tag</h3>
            <SearchTag setTagId={setTagId} />
          </div>
        )}

        {activeSection === "edittag" && (
          <div id="edittag">
            <h3>Tag</h3>
            <EditTag tagId={tagId} />
          </div>
        )}

        {activeSection === "taskdocument" && (
          <div id="taskdocument">
            <h3>TaskDocument</h3>
            <SearchTaskDocument setTaskDocumentId={setTaskDocumentId} />
          </div>
        )}

        {activeSection === "edittaskdocument" && (
          <div id="edittaskdocument">
            <h3>TaskDocument</h3>
            <EditTaskDocument taskdocumentId={taskdocumentId} />
          </div>
        )}

        {activeSection === "tasks" && (
          <div id="tasks">
            <h3>Tasks</h3>
            <SearchTasks setTasksId={setTasksId} />
          </div>
        )}

        {activeSection === "edittasks" && (
          <div id="edittasks">
            <h3>Tasks</h3>
            <EditTasks tasksId={tasksId} />
          </div>
        )}

        {activeSection === "testcycle" && (
          <div id="testcycle">
            <h3>TestCycle</h3>
            <SearchTestCycle setTestCycleId={setTestCycleId} />
          </div>
        )}

        {activeSection === "edittestcycle" && (
          <div id="edittestcycle">
            <h3>TestCycle</h3>
            <EditTestCycle testcycleId={testcycleId} />
          </div>
        )}

        {activeSection === "testfailure" && (
          <div id="testfailure">
            <h3>TestFailure</h3>
            <SearchTestFailure setTestFailureId={setTestFailureId} />
          </div>
        )}

        {activeSection === "edittestfailure" && (
          <div id="edittestfailure">
            <h3>TestFailure</h3>
            <EditTestFailure testfailureId={testfailureId} />
          </div>
        )}

        {activeSection === "testlog" && (
          <div id="testlog">
            <h3>TestLog</h3>
            <SearchTestLog setTestLogId={setTestLogId} />
          </div>
        )}

        {activeSection === "edittestlog" && (
          <div id="edittestlog">
            <h3>TestLog</h3>
            <EditTestLog testlogId={testlogId} />
          </div>
        )}

        {activeSection === "testreport" && (
          <div id="testreport">
            <h3>TestReport</h3>
            <SearchTestReport setTestReportId={setTestReportId} />
          </div>
        )}

        {activeSection === "edittestreport" && (
          <div id="edittestreport">
            <h3>TestReport</h3>
            <EditTestReport testreportId={testreportId} />
          </div>
        )}

        {activeSection === "testresult" && (
          <div id="testresult">
            <h3>TestResult</h3>
            <SearchTestResult setTestResultId={setTestResultId} />
          </div>
        )}

        {activeSection === "edittestresult" && (
          <div id="edittestresult">
            <h3>TestResult</h3>
            <EditTestResult testresultId={testresultId} />
          </div>
        )}

        {activeSection === "testsuite" && (
          <div id="testsuite">
            <h3>TestSuite</h3>
            <SearchTestSuite setTestSuiteId={setTestSuiteId} />
          </div>
        )}

        {activeSection === "edittestsuite" && (
          <div id="edittestsuite">
            <h3>TestSuite</h3>
            <EditTestSuite testsuiteId={testsuiteId} />
          </div>
        )}

        {activeSection === "timesheet" && (
          <div id="timesheet">
            <h3>Timesheet</h3>
            <SearchTimesheet setTimesheetId={setTimesheetId} />
          </div>
        )}

        {activeSection === "edittimesheet" && (
          <div id="edittimesheet">
            <h3>Timesheet</h3>
            <EditTimesheet timesheetId={timesheetId} />
          </div>
        )}

        {activeSection === "user" && (
          <div id="user">
            <h3>User</h3>
            <SearchUser setUserId={setUserId} />
          </div>
        )}

        {activeSection === "edituser" && (
          <div id="edituser">
            <h3>User</h3>
            <EditUser userId={userId} />
          </div>
        )}

        {activeSection === "usergroup" && (
          <div id="usergroup">
            <h3>UserGroup</h3>
            <SearchUserGroup setUserGroupId={setUserGroupId} />
          </div>
        )}

        {activeSection === "editusergroup" && (
          <div id="editusergroup">
            <h3>UserGroup</h3>
            <EditUserGroup usergroupId={usergroupId} />
          </div>
        )}

        {activeSection === "userlicense" && (
          <div id="userlicense">
            <h3>UserLicense</h3>
            <SearchUserLicense setUserLicenseId={setUserLicenseId} />
          </div>
        )}

        {activeSection === "edituserlicense" && (
          <div id="edituserlicense">
            <h3>UserLicense</h3>
            <EditUserLicense userlicenseId={userlicenseId} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Setup;
