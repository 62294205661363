import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import ObjectFormModal from "./ObjectFormModal";
import AttributeFormModal from "../attribute/AttributeFormModal";
import ListPropertyFormModal from "../listproperty/ListPropertyFormModal";
import ObjectRuleFormModal from "../objectrule/ObjectRuleFormModal";

const RightSidebar = ({
  data,
  onAttributeSubmit,
  onListPropertySubmit,
  onObjectRuleSubmit,
  onObjectSubmit,
}) => {
  const [isAttributeModalOpen, setIsAttributeModalOpen] = useState(false);
  const [isListPropertyModalOpen, setIsListPropertyModalOpen] = useState(false);
  const [isObjectRuleModalOpen, setIsObjectRuleModalOpen] = useState(false);
  const [isObjectModalOpen, setIsObjectModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated attributes
      const attributeResponse = await axios.get(
        `${conf.API_BASE_URL}/search/attribute?query=${data.objid}&field=attribute2object`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (attributeResponse.data.length > 0) {
        // If there are associated attributes, set the message
        setMessage(
          "This object has associated attributes and cannot be deleted.",
        );
        return;
      }
      // Fetch associated listpropertys
      const listpropertyResponse = await axios.get(
        `${conf.API_BASE_URL}/search/listproperty?query=${data.objid}&field=listproperty2object`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (listpropertyResponse.data.length > 0) {
        // If there are associated listpropertys, set the message
        setMessage(
          "This object has associated listpropertys and cannot be deleted.",
        );
        return;
      }
      // Fetch associated objectrules
      const objectruleResponse = await axios.get(
        `${conf.API_BASE_URL}/search/objectrule?query=${data.objid}&field=objectrule2object`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (objectruleResponse.data.length > 0) {
        // If there are associated objectrules, set the message
        setMessage(
          "This object has associated objectrules and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/objects/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Object Deleted:", response.data);
      navigate("/setup/object");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching object:", error);
      }
    }
  };

  const openAttributeModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsAttributeModalOpen(true);
  };

  const closeAttributeModal = () => {
    setIsAttributeModalOpen(false);
  };

  const handleCustomAttributeSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeAttributeModal();
      onAttributeSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching attribute:", error);
      }
    }
  };

  const openListPropertyModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsListPropertyModalOpen(true);
  };

  const closeListPropertyModal = () => {
    setIsListPropertyModalOpen(false);
  };

  const handleCustomListPropertySubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeListPropertyModal();
      onListPropertySubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching listproperty:", error);
      }
    }
  };

  const openObjectRuleModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsObjectRuleModalOpen(true);
  };

  const closeObjectRuleModal = () => {
    setIsObjectRuleModalOpen(false);
  };

  const handleCustomObjectRuleSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeObjectRuleModal();
      onObjectRuleSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching objectrule:", error);
      }
    }
  };
  const openObjectModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsObjectModalOpen(true);
  };

  const closeObjectModal = () => {
    setIsObjectModalOpen(false);
  };
  const handleCustomObjectSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeObjectModal();
      onObjectSubmit(response.data); // Pass the new object to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Object
          </a>
        </li>
        <li>
          <a href="#" onClick={openObjectModal}>
            Clone Object
          </a>
        </li>
        <li>
          <a href="# " onClick={openAttributeModal}>
            Add Attribute
          </a>
        </li>
        <li>
          <a href="# " onClick={openListPropertyModal}>
            Add ListProperty
          </a>
        </li>
        <li>
          <a href="# " onClick={openObjectRuleModal}>
            Add ObjectRule
          </a>
        </li>
      </ul>
      <AttributeFormModal
        isOpen={isAttributeModalOpen}
        onRequestClose={closeAttributeModal}
        attribute={{ attribute2object: data?.objid }} // Pass the attribute data if needed
        onSubmit={handleCustomAttributeSubmit}
        cloneData={null}
      />
      <ListPropertyFormModal
        isOpen={isListPropertyModalOpen}
        onRequestClose={closeListPropertyModal}
        listproperty={{ listproperty2object: data?.objid }} // Pass the listproperty data if needed
        onSubmit={handleCustomListPropertySubmit}
        cloneData={null}
      />
      <ObjectRuleFormModal
        isOpen={isObjectRuleModalOpen}
        onRequestClose={closeObjectRuleModal}
        objectrule={{ objectrule2object: data?.objid }} // Pass the objectrule data if needed
        onSubmit={handleCustomObjectRuleSubmit}
        cloneData={null}
      />
      <ObjectFormModal
        isOpen={isObjectModalOpen}
        onRequestClose={closeObjectModal}
        object={null} // Pass the project data if needed
        onSubmit={handleCustomObjectSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
