import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import TagFormModal from "./TagFormModal";
import RunScheduleFormModal from "../runschedule/RunScheduleFormModal";
import RunTagFormModal from "../runtag/RunTagFormModal";
import SuiteTagFormModal from "../suitetag/SuiteTagFormModal";

const RightSidebar = ({
  data,
  onRunScheduleSubmit,
  onRunTagSubmit,
  onSuiteTagSubmit,
  onTagSubmit,
}) => {
  const [isRunScheduleModalOpen, setIsRunScheduleModalOpen] = useState(false);
  const [isRunTagModalOpen, setIsRunTagModalOpen] = useState(false);
  const [isSuiteTagModalOpen, setIsSuiteTagModalOpen] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated runschedules
      const runscheduleResponse = await axios.get(
        `${conf.API_BASE_URL}/search/runschedule?query=${data.objid}&field=runschedule2tag`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (runscheduleResponse.data.length > 0) {
        // If there are associated runschedules, set the message
        setMessage(
          "This tag has associated runschedules and cannot be deleted.",
        );
        return;
      }
      // Fetch associated runtags
      const runtagResponse = await axios.get(
        `${conf.API_BASE_URL}/search/runtag?query=${data.objid}&field=runtag2tag`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (runtagResponse.data.length > 0) {
        // If there are associated runtags, set the message
        setMessage("This tag has associated runtags and cannot be deleted.");
        return;
      }
      // Fetch associated suitetags
      const suitetagResponse = await axios.get(
        `${conf.API_BASE_URL}/search/suitetag?query=${data.objid}&field=suitetag2tag`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (suitetagResponse.data.length > 0) {
        // If there are associated suitetags, set the message
        setMessage("This tag has associated suitetags and cannot be deleted.");
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/tags/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Tag Deleted:", response.data);
      navigate("/setup/tag");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching tag:", error);
      }
    }
  };

  const openRunScheduleModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsRunScheduleModalOpen(true);
  };

  const closeRunScheduleModal = () => {
    setIsRunScheduleModalOpen(false);
  };

  const handleCustomRunScheduleSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeRunScheduleModal();
      onRunScheduleSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching runschedule:", error);
      }
    }
  };

  const openRunTagModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsRunTagModalOpen(true);
  };

  const closeRunTagModal = () => {
    setIsRunTagModalOpen(false);
  };

  const handleCustomRunTagSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeRunTagModal();
      onRunTagSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching runtag:", error);
      }
    }
  };

  const openSuiteTagModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSuiteTagModalOpen(true);
  };

  const closeSuiteTagModal = () => {
    setIsSuiteTagModalOpen(false);
  };

  const handleCustomSuiteTagSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeSuiteTagModal();
      onSuiteTagSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching suitetag:", error);
      }
    }
  };
  const openTagModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTagModalOpen(true);
  };

  const closeTagModal = () => {
    setIsTagModalOpen(false);
  };
  const handleCustomTagSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTagModal();
      onTagSubmit(response.data); // Pass the new tag to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Tag
          </a>
        </li>
        <li>
          <a href="#" onClick={openTagModal}>
            Clone Tag
          </a>
        </li>
        <li>
          <a href="# " onClick={openRunScheduleModal}>
            Add Run Schedule
          </a>
        </li>
        <li>
          <a href="# " onClick={openRunTagModal}>
            Add RunTag
          </a>
        </li>
        <li>
          <a href="# " onClick={openSuiteTagModal}>
            Add SuiteTag
          </a>
        </li>
      </ul>
      <RunScheduleFormModal
        isOpen={isRunScheduleModalOpen}
        onRequestClose={closeRunScheduleModal}
        runschedule={{ runschedule2tag: data?.objid }} // Pass the runschedule data if needed
        onSubmit={handleCustomRunScheduleSubmit}
        cloneData={null}
      />
      <RunTagFormModal
        isOpen={isRunTagModalOpen}
        onRequestClose={closeRunTagModal}
        runtag={{ runtag2tag: data?.objid }} // Pass the runtag data if needed
        onSubmit={handleCustomRunTagSubmit}
        cloneData={null}
      />
      <SuiteTagFormModal
        isOpen={isSuiteTagModalOpen}
        onRequestClose={closeSuiteTagModal}
        suitetag={{ suitetag2tag: data?.objid }} // Pass the suitetag data if needed
        onSubmit={handleCustomSuiteTagSubmit}
        cloneData={null}
      />
      <TagFormModal
        isOpen={isTagModalOpen}
        onRequestClose={closeTagModal}
        tag={null} // Pass the project data if needed
        onSubmit={handleCustomTagSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
