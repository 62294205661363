import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Action Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "tablename",
    label: "Action On Table",
    type: "text",
    maxLength: 100,
    required: false,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 4000,
    required: false,
  },
  {
    name: "stepno",
    label: "StepNo",
    type: "int",
    maxLength: 16,
    required: true,
  },
  {
    name: "input",
    label: "Input(@param)",
    type: "text",
    maxLength: 4000,
    required: true,
  },
  {
    name: "inputdatatype",
    label: "Input Datatype(@type)",
    type: "text",
    maxLength: 500,
    required: true,
  },
  {
    name: "output",
    label: "Output($param)",
    type: "text",
    maxLength: 1000,
    required: true,
  },
  {
    name: "querytype",
    label: "Query Type",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=QueryType&table=ActionQuery`,
  },
  {
    name: "hasrecordset",
    label: "Has Recordset",
    type: "select",
    maxLength: 3,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=HasRecordSet&table=ActionQuery`,
  },
  {
    name: "oraclequery",
    label: "OracleQuery",
    type: "text",
    maxLength: 4000,
    required: false,
  },
  {
    name: "mssqlquery",
    label: "MssqlQuery",
    type: "text",
    maxLength: 4000,
    required: false,
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Status&table=ActionQuery`,
  },
  {
    name: "actionquery2objectrule",
    maxLength: 100,
    label: "ObjectRule",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/objectrule`,
  },
];
const ActionQueryFormContainer = ({
  propActionQuery,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propActionQuery?.objid || null,
    name: propActionQuery?.name || null,
    tablename: propActionQuery?.tablename || null,
    description: propActionQuery?.description || null,
    stepno: propActionQuery?.stepno || null,
    input: propActionQuery?.input || null,
    inputdatatype: propActionQuery?.inputdatatype || null,
    output: propActionQuery?.output || null,
    querytype: propActionQuery?.querytype || null,
    hasrecordset: propActionQuery?.hasrecordset || null,
    oraclequery: propActionQuery?.oraclequery || null,
    mssqlquery: propActionQuery?.mssqlquery || null,
    status: propActionQuery?.status || null,
    actionquery2objectrule: propActionQuery?.actionquery2objectrule,
    customdata: propActionQuery?.customdata || null,
    groupuserid: propActionQuery?.x_groupuserid || user.x_groupuserid,
    createdbyid: propActionQuery?.createdbyid || user.id,
    createdby: propActionQuery?.createdby || user.fullname,
    createddate: propActionQuery?.createddate
      ? formatDate(propActionQuery.createddate)
      : today,
    modifiedby: propActionQuery?.modifiedby || user.fullname,
    modifieddate: propActionQuery?.modifieddate
      ? formatDate(propActionQuery.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "ActionQuery", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="actionquery"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default ActionQueryFormContainer;
