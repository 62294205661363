import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "scenario",
    label: "Scenario / Test Name",
    type: "text",
    maxLength: 300,
    required: false,
  },
  {
    name: "details",
    label: "Details",
    type: "text",
    maxLength: 4000,
    required: true,
  },
  {
    name: "logurl",
    label: "Log Url",
    type: "url",
    maxLength: 300,
    required: false,
  },
  {
    name: "reporturl",
    label: "ReportURL",
    type: "url",
    maxLength: 4000,
    required: false,
  },
  {
    name: "isdefect",
    label: "Is Defect",
    type: "checkbox",
    maxLength: 3,
    required: false,
  },
  {
    name: "testfailure2testreport",
    maxLength: 100,
    label: "TestReport",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/testreport`,
  },
  {
    name: "testfailure2testsuite",
    maxLength: 100,
    label: "TestSuite",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/testsuite`,
  },
  {
    name: "testfailure2feature",
    maxLength: 100,
    label: "Feature",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/feature`,
  },
  {
    name: "testfailure2scenario",
    maxLength: 100,
    label: "Scenario",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/scenario`,
  },
  {
    name: "testfailure2assignee",
    maxLength: 100,
    label: "Assignee",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/assignee`,
  },
];
const TestFailureFormContainer = ({
  propTestFailure,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propTestFailure?.objid || null,
    name: propTestFailure?.name || null,
    scenario: propTestFailure?.scenario || null,
    details: propTestFailure?.details || null,
    logurl: propTestFailure?.logurl || null,
    reporturl: propTestFailure?.reporturl || null,
    analysis: propTestFailure?.analysis || null,
    isdefect: propTestFailure?.isdefect || null,
    testfailure2testreport: propTestFailure?.testfailure2testreport,
    testfailure2testsuite: propTestFailure?.testfailure2testsuite,
    testfailure2feature: propTestFailure?.testfailure2feature,
    testfailure2scenario: propTestFailure?.testfailure2scenario,
    testfailure2assignee: propTestFailure?.testfailure2assignee,
    customdata: propTestFailure?.customdata || null,
    groupuserid: propTestFailure?.x_groupuserid || user.x_groupuserid,
    createdbyid: propTestFailure?.createdbyid || user.id,
    createdby: propTestFailure?.createdby || user.fullname,
    createddate: propTestFailure?.createddate
      ? formatDate(propTestFailure.createddate)
      : today,
    modifiedby: propTestFailure?.modifiedby || user.fullname,
    modifieddate: propTestFailure?.modifieddate
      ? formatDate(propTestFailure.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "TestFailure", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="testfailure"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default TestFailureFormContainer;
