import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditScenario.css";
import TableScenario from "./TableScenario";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import DefectList from "../defect/DefectList";
import StepsList from "../steps/StepsList";
import TestFailureList from "../testfailure/TestFailureList";

const EditScenario = ({ scenarioId: propScenarioId }) => {
  const [scenario, setScenario] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableScenario, setShowTableScenario] = useState(false);
  const [defects, setDefects] = useState([]);
  const [stepss, setStepss] = useState([]);
  const [testfailures, setTestFailures] = useState([]);

  const { id } = useParams();
  const scenarioId = id || propScenarioId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchScenario = async () => {
        if (!scenarioId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/scenarios/searchById/${scenarioId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setScenario(response.data);
            setEditLoad(true);
            console.log("Fetched scenario data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching scenario:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchScenario();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/defect?query=${scenarioId}&field=defect2scenario`,
        setDefects,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/steps?query=${scenarioId}&field=steps2scenario`,
        setStepss,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testfailure?query=${scenarioId}&field=testfailure2scenario`,
        setTestFailures,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, scenarioId]);

  const handleScenarioSubmit = (newScenario) => {
    navigate(`/scenario/${newScenario.objid}`);
  };

  const handleDefectSubmit = (newDefect) => {
    setDefects((defects) => [newDefect, ...defects]);
    setShowTableScenario(true);
  };
  const handleStepsSubmit = (newSteps) => {
    setStepss((stepss) => [newSteps, ...stepss]);
    setShowTableScenario(true);
  };
  const handleTestFailureSubmit = (newTestFailure) => {
    setTestFailures((testfailures) => [newTestFailure, ...testfailures]);
    setShowTableScenario(true);
  };
  return (
    <div className="edit-scenario-container">
      <div className="main-content">
        <h1>Edit Scenario</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, scenario)}
        {editLoad ? (
          <TableScenario
            data={scenario}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <DefectList defects={defects} />
        <StepsList stepss={stepss} />
        <TestFailureList testfailures={testfailures} />
      </div>
      <RightSidebar
        data={scenario}
        onDefectSubmit={handleDefectSubmit}
        onStepsSubmit={handleStepsSubmit}
        onTestFailureSubmit={handleTestFailureSubmit}
        onScenarioSubmit={handleScenarioSubmit}
      />
    </div>
  );
};

export default EditScenario;
