import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Tag", type: "text", maxLength: 100, required: true },
];
const TagFormContainer = ({ propTag, onSubmit, onCancel, cloneData }) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propTag?.objid || null,
    name: propTag?.name || null,
    customdata: propTag?.customdata || null,
    groupuserid: propTag?.x_groupuserid || user.x_groupuserid,
    createdbyid: propTag?.createdbyid || user.id,
    createdby: propTag?.createdby || user.fullname,
    createddate: propTag?.createddate ? formatDate(propTag.createddate) : today,
    modifiedby: propTag?.modifiedby || user.fullname,
    modifieddate: propTag?.modifieddate
      ? formatDate(propTag.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Tag", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="tag"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default TagFormContainer;
