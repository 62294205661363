import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import config from "../config/config";
import "./PasswordRecovery.css";
import { AuthContext } from "../context/AuthContext"; // Ensure this path is correct

const PasswordRecovery = () => {
  const { user, login, logout } = useContext(AuthContext);
  const [loginemail, setLoginemail] = useState("");
  const [recoveryCode, setRecoveryCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const loginCalledRef = useRef(false);

  useEffect(() => {
    const fetchToken = async () => {
      if (loginCalledRef.current) {
        return; // Don't call login again if it's already been called
      }

      loginCalledRef.current = true;

      try {
        if (user) {
          await logout(); // Log out the user
        }

        const response = await axios.post(`${config.AUTH_URL}/login`, {
          logintype: config.USER_TYPE_REGISTRATION,
        });
        login(response.data);
      } catch (error) {
        console.error("Error logging in:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchToken();
  }, [login, logout, user]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/activate/password-recovery`,
        {
          loginemail,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            "Content-Type": "application/json",
          },
        }
      );

      setStep(2);
      setMessage("Recovery code sent to your email.");
    } catch (error) {
      console.error("Error sending recovery code:", error);
      setMessage("Failed to send recovery code. Please try again.");
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/activate/reset-password`,
        {
          loginemail,
          recoveryCode,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMessage("Password reset is successful.");
    } catch (error) {
      console.error("Error resetting password:", error);
      setMessage("Failed to reset password. Please try again.");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="password-recovery-container">
      {step === 1 ? (
        <form className="password-recovery-form" onSubmit={handleEmailSubmit}>
          <h2>Password Recovery</h2>
          <input
            type="email"
            value={loginemail}
            onChange={(e) => setLoginemail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button type="submit">Recover</button>
        </form>
      ) : (
        <form className="password-recovery-form" onSubmit={handleCodeSubmit}>
          <h2>Reset Password</h2>
          <input
            type="text"
            value={recoveryCode}
            onChange={(e) => setRecoveryCode(e.target.value)}
            placeholder="Enter recovery code"
            required
          />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
            required
          />
          <button type="submit">Reset Password</button>
        </form>
      )}
      {message && <p>{message}</p>}
    </div>
  );
};

export default PasswordRecovery;
