import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import BoardFormModal from "./BoardFormModal";
import MemberFormModal from "../member/MemberFormModal";
import SprintFormModal from "../sprint/SprintFormModal";

const RightSidebar = ({
  data,
  onMemberSubmit,
  onSprintSubmit,
  onBoardSubmit,
}) => {
  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
  const [isSprintModalOpen, setIsSprintModalOpen] = useState(false);
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated members
      const memberResponse = await axios.get(
        `${conf.API_BASE_URL}/search/member?query=${data.objid}&field=member2board`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (memberResponse.data.length > 0) {
        // If there are associated members, set the message
        setMessage("This board has associated members and cannot be deleted.");
        return;
      }
      // Fetch associated sprints
      const sprintResponse = await axios.get(
        `${conf.API_BASE_URL}/search/sprint?query=${data.objid}&field=sprint2board`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (sprintResponse.data.length > 0) {
        // If there are associated sprints, set the message
        setMessage("This board has associated sprints and cannot be deleted.");
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/boards/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Board Deleted:", response.data);
      navigate("/setup/board");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching board:", error);
      }
    }
  };

  const openMemberModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsMemberModalOpen(true);
  };

  const closeMemberModal = () => {
    setIsMemberModalOpen(false);
  };

  const handleCustomMemberSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeMemberModal();
      onMemberSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching member:", error);
      }
    }
  };

  const openSprintModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSprintModalOpen(true);
  };

  const closeSprintModal = () => {
    setIsSprintModalOpen(false);
  };

  const handleCustomSprintSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeSprintModal();
      onSprintSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching sprint:", error);
      }
    }
  };
  const openBoardModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsBoardModalOpen(true);
  };

  const closeBoardModal = () => {
    setIsBoardModalOpen(false);
  };
  const handleCustomBoardSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeBoardModal();
      onBoardSubmit(response.data); // Pass the new board to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Board
          </a>
        </li>
        <li>
          <a href="#" onClick={openBoardModal}>
            Clone Board
          </a>
        </li>
        <li>
          <a href="# " onClick={openMemberModal}>
            Add Member
          </a>
        </li>
        <li>
          <a href="# " onClick={openSprintModal}>
            Add Sprint
          </a>
        </li>
      </ul>
      <MemberFormModal
        isOpen={isMemberModalOpen}
        onRequestClose={closeMemberModal}
        member={{ member2board: data?.objid }} // Pass the member data if needed
        onSubmit={handleCustomMemberSubmit}
        cloneData={null}
      />
      <SprintFormModal
        isOpen={isSprintModalOpen}
        onRequestClose={closeSprintModal}
        sprint={{ sprint2board: data?.objid }} // Pass the sprint data if needed
        onSubmit={handleCustomSprintSubmit}
        cloneData={null}
      />
      <BoardFormModal
        isOpen={isBoardModalOpen}
        onRequestClose={closeBoardModal}
        board={null} // Pass the project data if needed
        onSubmit={handleCustomBoardSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
