import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import "./RegistrationForm.css";
import config from "../config/config";
import { AuthContext } from "../context/AuthContext"; // Make sure this path is correct

const RegistrationForm = () => {
  const { user, login, logout } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    loginemail: "",
    password: "",
    company: "",
    website: "",
    phone: "",
    mediacode: "",
    reasoncode: "",
    createddate: new Date(),
    verificationCode: "",
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEmailValidated, setIsEmailValidated] = useState(false);

  const loginCalledRef = useRef(false);
  const emailValidationRef = useRef(false);

  useEffect(() => {
    const fetchToken = async () => {
      if (loginCalledRef.current) {
        return; // Don't call login again if it's already been called
      }

      loginCalledRef.current = true;

      try {
        if (user) {
          await logout(); // Log out the user
        }

        const response = await axios.post(`${config.AUTH_URL}/login`, {
          logintype: config.USER_TYPE_REGISTRATION,
        });
        login(response.data);
      } catch (error) {
        console.error("Error logging in:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchToken();
  }, [login, logout, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateField(name, value);
    if (
      name === "password" &&
      isEmailValidated &&
      !emailValidationRef.current
    ) {
      sendVerificationCode(formData.loginemail);
      emailValidationRef.current = true;
    }
  };

  const validateField = async (name, value) => {
    let error = "";
    if (name === "loginemail") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        error = "Invalid email format.";
        setCodeSent(false);
        setIsVerified(false);
        setIsEmailValidated(false);
        emailValidationRef.current = false;
      } else {
        try {
          const response = await axios.get(
            `${config.API_BASE_URL}/activate/check-email`,
            {
              params: { loginemail: value },
              headers: {
                Authorization: `Bearer ${user.token}`,
                x_account_id: `${user.x_account_id}`,
              },
            }
          );
          if (
            response.data[0] != null &&
            response.data[0].loginemail === value
          ) {
            error = "Email is already registered.";
            setCodeSent(false);
            setIsVerified(false);
            emailValidationRef.current = false;
          } else {
            setIsEmailValidated(true);
          }
        } catch (error) {
          console.error("Error checking email:", error);
          emailValidationRef.current = false;
        }
      }
    } else if (name === "password") {
      const passwordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordPattern.test(value)) {
        error =
          "Password must have at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a special character.";
      }
    }

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const sendVerificationCode = async (email) => {
    try {
      await axios.post(
        `${config.API_BASE_URL}/activate/send-verification-code`,
        { loginemail: email },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
          },
        }
      );
      setCodeSent(true);
      setMessage(
        "Enter Verification code sent to your email. Check Junk Email in case."
      );
    } catch (error) {
      console.error("Error sending verification code:", error);
      setMessage("Error sending verification code. Please try again.");
    }
  };

  const verifyCode = async () => {
    try {
      await axios.post(
        `${config.API_BASE_URL}/activate/verify-code`,
        {
          loginemail: formData.loginemail,
          verificationCode: formData.verificationCode,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
          },
        }
      );
      setIsVerified(true);
      setMessage("Email verification successful.");
    } catch (error) {
      console.error("Error verifying code:", error);
      setIsVerified(false);
      setMessage("Invalid verification code. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid =
      Object.values(errors).every((error) => error === "") &&
      Object.values(formData).every((value) => value !== "") &&
      isVerified;

    if (isValid) {
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}/activate/saveProfile`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Profile created:", response.data);
      } catch (error) {
        console.error("There was an error submitting the form:", error);
        setMessage("Registration failed. Please try again.");
      }

      try {
        const emailResponse = await axios.post(
          `${config.API_BASE_URL}/activate/send-activation-email`,
          { loginemail: formData.loginemail },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              "Content-Type": "application/json",
            },
          }
        );
        setIsRegistered(true);
        console.log("Profile created:", emailResponse.data);
        setMessage(
          "Registration successful! \n Please check your email and activate your account."
        );
      } catch (error) {
        console.error("There was an error submitting the form:", error);
        setMessage("Registration failed. Please try again.");
      }
    } else {
      alert(
        "Please fix the errors in the form and complete email verification."
      );
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <form className="registration-form" onSubmit={handleSubmit}>
      <h2>Registration</h2>
      <label>
        First Name:
        <input
          type="text"
          name="firstname"
          value={formData.firstname}
          onChange={handleChange}
          required
        />
        {errors.firstname && <span>{errors.firstname}</span>}
      </label>
      <br />
      <label>
        Last Name:
        <input
          type="text"
          name="lastname"
          value={formData.lastname}
          onChange={handleChange}
          required
        />
        {errors.lastname && <span>{errors.lastname}</span>}
      </label>
      <br />
      <label>
        Email:
        <input
          type="email"
          name="loginemail"
          value={formData.loginemail}
          onChange={handleChange}
          required
        />
        {errors.loginemail && <span>{errors.loginemail}</span>}
        {message && codeSent && <p style={{ color: "green" }}>{message}</p>}
      </label>
      {codeSent && (
        <>
          <label>
            Verification Code:
            <input
              type="text"
              name="verificationCode"
              value={formData.verificationCode}
              onChange={handleChange}
              required
            />
            {message && isVerified && (
              <p style={{ color: "green" }}>{message}</p>
            )}
          </label>
          <button type="button" onClick={verifyCode}>
            Verify Code
          </button>
        </>
      )}
      <br />
      <label>
        Password:
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        {errors.password && <span>{errors.password}</span>}
      </label>
      <br />
      <label>
        Company / Institute:
        <input
          type="text"
          name="company"
          value={formData.company}
          onChange={handleChange}
          required
        />
        {errors.company && <span>{errors.company}</span>}
      </label>
      <br />
      <label>
        Website:
        <input
          type="url"
          name="website"
          value={formData.website}
          onChange={handleChange}
          required
        />
        {errors.website && <span>{errors.website}</span>}
      </label>
      <br />
      <label>
        Phone:
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        {errors.phone && <span>{errors.phone}</span>}
      </label>
      <br />
      <label>
        Media Code:
        <select
          name="mediacode"
          value={formData.mediacode}
          onChange={handleChange}
          required
        >
          <option value="">Select...</option>
          <option value="Google Search">Google Search</option>
          <option value="Google Ad">Google Ad</option>
          <option value="Facebook">Facebook</option>
          <option value="Linked In">Linked In</option>
          <option value="Instagram">Instagram</option>
          <option value="Youtube">Youtube</option>
          <option value="Other">Other</option>
        </select>
        {errors.mediacode && <span>{errors.mediacode}</span>}
      </label>
      <br />
      <label>
        Reason Code:
        <select
          name="reasoncode"
          value={formData.reasoncode}
          onChange={handleChange}
          required
        >
          <option value="">Select...</option>
          <option value="Trial">Trial</option>
          <option value="Freelancing">Freelancing</option>
          <option value="Startup">Startup</option>
          <option value="Student">Student</option>
          <option value="Developer">Developer</option>
          <option value="Enterprise">Enterprise</option>
        </select>
        {errors.reasoncode && <span>{errors.reasoncode}</span>}
      </label>
      <br />
      {message && !isRegistered && !isVerified && (
        <p style={{ color: "red" }}>{message}</p>
      )}
      {message && isRegistered && isVerified && (
        <p style={{ color: "green" }}>{message}</p>
      )}
      <button type="submit">Register</button>
    </form>
  );
};

export default RegistrationForm;
