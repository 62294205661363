import React, { useState, useContext } from "react";
import axios from "axios";
import config from "../config/config";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import "./Login.css";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { login, tokenExpiryMessage } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.AUTH_URL}/login`, {
        username,
        password,
      });
      login(response.data);
      navigate("/home");
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <div className="main-content">
      <div className="login-container">
        <form className="login-form" onSubmit={handleLogin}>
          <h2>Login</h2>
          {tokenExpiryMessage && (
            <div className="error-message">{tokenExpiryMessage}</div>
          )}
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Login Email"
          />
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <div className="show-password-container">
            <label htmlFor="show-password">Show Password</label>
            <input
              type="checkbox"
              id="show-password"
              checked={showPassword}
              onChange={(e) => setShowPassword(e.target.checked)}
            />
          </div>
          <button type="submit">Login</button>
          <div className="login-links">
            <Link to="/register">Registration?</Link>
            <Link to="/password-recovery">Forgot Password?</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
