import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { AuthContext } from "../context/AuthContext";
import conf from "../config/config";
import "./TestResultGraph.css"; // Import the new CSS file

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TestResultGraph = () => {
  const [testResults, setTestResults] = useState([]);
  const [selectedEnv, setSelectedEnv] = useState("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchTestResults = async () => {
      try {
        const response = await axios.post(
          `${conf.API_BASE_URL}/dashboard/getTestResults`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          }
        );
        setTestResults(response.data.testResults);
        // Set the default selected environment to the first unique testenv
        const uniqueEnvs = [
          ...new Set(response.data.testResults.map((result) => result.testenv)),
        ];
        setSelectedEnv(uniqueEnvs[0]);
      } catch (error) {
        console.error("Error fetching test results:", error);
      }
    };

    fetchTestResults();
  }, [user.token, user.x_account_id, user.x_groupuserid, user.x_poolindex]);

  const handleEnvChange = (event) => {
    setSelectedEnv(event.target.value);
  };

  const filteredResults = testResults.filter(
    (result) => result.testenv === selectedEnv
  );

  const data = {
    labels: filteredResults.map((result) => result.createddate),
    datasets: [
      {
        label: "Total Tests",
        data: filteredResults.map((result) => result.totaltest),
        borderColor: "blue",
        fill: false,
      },
      {
        label: "Passed",
        data: filteredResults.map((result) => result.passed),
        borderColor: "green",
        fill: false,
      },
      {
        label: "Failed",
        data: filteredResults.map((result) => result.failed),
        borderColor: "red",
        fill: false,
      },
      {
        label: "Skipped",
        data: filteredResults.map((result) => result.skipped),
        borderColor: "orange",
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        title: {
          display: true,
          text: "Created Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Tests",
        },
      },
    },
  };

  return (
    <div className="dashboard-content">
      <h2>Test Result</h2>
      <div>
        <label htmlFor="testenv-select">Select Environment: </label>
        <select
          id="testenv-select"
          value={selectedEnv}
          onChange={handleEnvChange}
        >
          {[...new Set(testResults.map((result) => result.testenv))].map(
            (env) => (
              <option key={env} value={env}>
                {env}
              </option>
            )
          )}
        </select>
      </div>
      <div className="test-results-graph">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default TestResultGraph;
