import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import DefectFormModal from "./DefectFormModal";
import DefectDocumentFormModal from "../defectdocument/DefectDocumentFormModal";
import NotesFormModal from "../notes/NotesFormModal";

const RightSidebar = ({
  data,
  onDefectDocumentSubmit,
  onNotesSubmit,
  onDefectSubmit,
}) => {
  const [isDefectDocumentModalOpen, setIsDefectDocumentModalOpen] =
    useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isDefectModalOpen, setIsDefectModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated defectdocuments
      const defectdocumentResponse = await axios.get(
        `${conf.API_BASE_URL}/search/defectdocument?query=${data.objid}&field=defectdocument2defect`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (defectdocumentResponse.data.length > 0) {
        // If there are associated defectdocuments, set the message
        setMessage(
          "This defect has associated defectdocuments and cannot be deleted.",
        );
        return;
      }
      // Fetch associated notess
      const notesResponse = await axios.get(
        `${conf.API_BASE_URL}/search/notes?query=${data.objid}&field=notes2defect`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (notesResponse.data.length > 0) {
        // If there are associated notess, set the message
        setMessage("This defect has associated notess and cannot be deleted.");
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/defects/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Defect Deleted:", response.data);
      navigate("/setup/defect");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching defect:", error);
      }
    }
  };

  const openDefectDocumentModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDefectDocumentModalOpen(true);
  };

  const closeDefectDocumentModal = () => {
    setIsDefectDocumentModalOpen(false);
  };

  const handleCustomDefectDocumentSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeDefectDocumentModal();
      onDefectDocumentSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching defectdocument:", error);
      }
    }
  };

  const openNotesModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsNotesModalOpen(true);
  };

  const closeNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  const handleCustomNotesSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeNotesModal();
      onNotesSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching notes:", error);
      }
    }
  };
  const openDefectModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDefectModalOpen(true);
  };

  const closeDefectModal = () => {
    setIsDefectModalOpen(false);
  };
  const handleCustomDefectSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeDefectModal();
      onDefectSubmit(response.data); // Pass the new defect to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Defect
          </a>
        </li>
        <li>
          <a href="#" onClick={openDefectModal}>
            Clone Defect
          </a>
        </li>
        <li>
          <a href="# " onClick={openDefectDocumentModal}>
            Add Document
          </a>
        </li>
        <li>
          <a href="# " onClick={openNotesModal}>
            Add Notes
          </a>
        </li>
      </ul>
      <DefectDocumentFormModal
        isOpen={isDefectDocumentModalOpen}
        onRequestClose={closeDefectDocumentModal}
        defectdocument={{ defectdocument2defect: data?.objid }} // Pass the defectdocument data if needed
        onSubmit={handleCustomDefectDocumentSubmit}
        cloneData={null}
      />
      <NotesFormModal
        isOpen={isNotesModalOpen}
        onRequestClose={closeNotesModal}
        notes={{ notes2defect: data?.objid }} // Pass the notes data if needed
        onSubmit={handleCustomNotesSubmit}
        cloneData={null}
      />
      <DefectFormModal
        isOpen={isDefectModalOpen}
        onRequestClose={closeDefectModal}
        defect={null} // Pass the project data if needed
        onSubmit={handleCustomDefectSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
