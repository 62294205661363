import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import SubscriptionFormModal from "./SubscriptionFormModal";
import BillingFormModal from "../billing/BillingFormModal";

const RightSidebar = ({ data, onBillingSubmit, onSubscriptionSubmit }) => {
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated billings
      const billingResponse = await axios.get(
        `${conf.API_BASE_URL}/search/billing?query=${data.objid}&field=billing2subscription`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (billingResponse.data.length > 0) {
        // If there are associated billings, set the message
        setMessage(
          "This subscription has associated billings and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/subscriptions/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Subscription Deleted:", response.data);
      navigate("/setup/subscription");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching subscription:", error);
      }
    }
  };

  const openBillingModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsBillingModalOpen(true);
  };

  const closeBillingModal = () => {
    setIsBillingModalOpen(false);
  };

  const handleCustomBillingSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeBillingModal();
      onBillingSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching billing:", error);
      }
    }
  };
  const openSubscriptionModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubscriptionModalOpen(true);
  };

  const closeSubscriptionModal = () => {
    setIsSubscriptionModalOpen(false);
  };
  const handleCustomSubscriptionSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeSubscriptionModal();
      onSubscriptionSubmit(response.data); // Pass the new subscription to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Subscription
          </a>
        </li>
        <li>
          <a href="#" onClick={openSubscriptionModal}>
            Clone Subscription
          </a>
        </li>
        <li>
          <a href="# " onClick={openBillingModal}>
            Add Billing
          </a>
        </li>
      </ul>
      <BillingFormModal
        isOpen={isBillingModalOpen}
        onRequestClose={closeBillingModal}
        billing={{ billing2subscription: data?.objid }} // Pass the billing data if needed
        onSubmit={handleCustomBillingSubmit}
        cloneData={null}
      />
      <SubscriptionFormModal
        isOpen={isSubscriptionModalOpen}
        onRequestClose={closeSubscriptionModal}
        subscription={null} // Pass the project data if needed
        onSubmit={handleCustomSubscriptionSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
