import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditObjectAccess.css";
import TableObjectAccess from "./TableObjectAccess";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import AttrAccessList from "../attraccess/AttrAccessList";

const EditObjectAccess = ({ objectaccessId: propObjectAccessId }) => {
  const [objectaccess, setObjectAccess] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableObjectAccess, setShowTableObjectAccess] = useState(false);
  const [attraccesss, setAttrAccesss] = useState([]);

  const { id } = useParams();
  const objectaccessId = id || propObjectAccessId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchObjectAccess = async () => {
        if (!objectaccessId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/objectaccesss/searchById/${objectaccessId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setObjectAccess(response.data);
            setEditLoad(true);
            console.log("Fetched objectaccess data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching objectaccess:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchObjectAccess();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/attraccess?query=${objectaccessId}&field=attraccess2objectaccess`,
        setAttrAccesss,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, objectaccessId]);

  const handleObjectAccessSubmit = (newObjectAccess) => {
    navigate(`/objectaccess/${newObjectAccess.objid}`);
  };

  const handleAttrAccessSubmit = (newAttrAccess) => {
    setAttrAccesss((attraccesss) => [newAttrAccess, ...attraccesss]);
    setShowTableObjectAccess(true);
  };
  return (
    <div className="edit-objectaccess-container">
      <div className="main-content">
        <h1>Edit ObjectAccess</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, objectaccess)}
        {editLoad ? (
          <TableObjectAccess
            data={objectaccess}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <AttrAccessList attraccesss={attraccesss} />
      </div>
      <RightSidebar
        data={objectaccess}
        onAttrAccessSubmit={handleAttrAccessSubmit}
        onObjectAccessSubmit={handleObjectAccessSubmit}
      />
    </div>
  );
};

export default EditObjectAccess;
