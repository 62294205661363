import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Attribute Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "tablename",
    label: "Table Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "hasproperty",
    label: "Has Property",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=HasProperty&table=Attribute`,
  },
  {
    name: "hascodeobject",
    label: "Has Code",
    type: "select",
    maxLength: 30,
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=HasCodeObject&table=Attribute`,
  },
  {
    name: "attribute2object",
    maxLength: 100,
    label: "Object",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/object`,
  },
];
const AttributeFormContainer = ({
  propAttribute,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propAttribute?.objid || null,
    name: propAttribute?.name || null,
    tablename: propAttribute?.tablename || null,
    hasproperty: propAttribute?.hasproperty || null,
    hascodeobject: propAttribute?.hascodeobject || null,
    attribute2object: propAttribute?.attribute2object,
    customdata: propAttribute?.customdata || null,
    groupuserid: propAttribute?.x_groupuserid || user.x_groupuserid,
    createdbyid: propAttribute?.createdbyid || user.id,
    createdby: propAttribute?.createdby || user.fullname,
    createddate: propAttribute?.createddate
      ? formatDate(propAttribute.createddate)
      : today,
    modifiedby: propAttribute?.modifiedby || user.fullname,
    modifieddate: propAttribute?.modifieddate
      ? formatDate(propAttribute.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Attribute", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="attribute"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default AttributeFormContainer;
