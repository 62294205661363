import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "../../common/ListingPage.css";
import axios from "axios";
import conf from "../../config/config";
import { AuthContext } from "../../context/AuthContext";

const DockerImageList = ({ dockerimages }) => {
  const { user, logout } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const dockerimagesPerPage = 5;

  const sortedDockerImages = [...dockerimages].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const indexOfLastDockerImage = currentPage * dockerimagesPerPage;
  const indexOfFirstDockerImage = indexOfLastDockerImage - dockerimagesPerPage;
  const currentDockerImages = sortedDockerImages.slice(
    indexOfFirstDockerImage,
    indexOfLastDockerImage,
  );

  const getSignedUrl = async (key) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/docker/generate-signed-url`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: { key },
        },
      );
      return response.data.url;
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching test result URL:", error);
      }
    }
  };

  const handleClick = async (urlKey) => {
    try {
      if (!urlKey) {
        setErrorMessage("No URL");
        return;
      }

      const urls = urlKey.split(";");

      for (const url of urls) {
        try {
          if (url.startsWith("http:") || url.startsWith("https:")) {
            window.open(url, "_blank");
          } else {
            const signedUrl = await getSignedUrl(url);
            window.open(signedUrl, "_blank");
          }
        } catch (error) {
          console.error("Error handling URL click:", error);
        }
      }
    } catch (error) {
      console.error("Error handling URL click:", error);
    }
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="dockerimage-list">
      <h2>Test Image</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {Array.isArray(dockerimages) && dockerimages.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort("name")}>Custom Name</th>
                <th onClick={() => handleSort("imagename")}>
                  Image Name (lower case)
                </th>
                <th onClick={() => handleSort("imagetag")}>
                  Image Tag (lower case)
                </th>
                <th onClick={() => handleSort("reponame")}>
                  AWS ECS Repository Name
                </th>
                <th onClick={() => handleSort("imagetype")}>Image Type</th>
              </tr>
            </thead>
            <tbody>
              {currentDockerImages.map((dockerimage) => (
                <tr key={dockerimage.objid}>
                  <td>
                    <Link to={`/dockerimage/${dockerimage.objid}`}>
                      {dockerimage.name || "Unnamed DockerImage"}
                    </Link>
                  </td>
                  <td>{dockerimage.imagename}</td>
                  <td>{dockerimage.imagetag}</td>
                  <td>{dockerimage.reponame}</td>
                  <td>{dockerimage.imagetype}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(dockerimages.length / dockerimagesPerPage) },
              (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ),
            )}
          </div>
        </>
      ) : (
        <p>No dockerimages available</p>
      )}
    </div>
  );
};

export default DockerImageList;
