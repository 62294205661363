import "./TableComments.css";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import conf from "../../config/config";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {
  handleEditClick,
  handleSaveClick,
  handleComboChange,
  handleSearchChange,
  handleFileChange,
  render300Plus,
  handleCheckboxChange,
  handleChange,
  renderField,
  renderFieldReadOnly,
  renderUpto300BasedOnMode,
} from "../../common/TableUtility";

const TableComments = ({ data, setSuccessMessage, setErrorMessage }) => {
  const [editField, setEditField] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [editMode, setEditMode] = useState("");
  const [modifiedData, setModifiedData] = useState({ ...data });
  const [options, setOptions] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { user, logout } = useContext(AuthContext);
  const [updatedFields, setUpdatedFields] = useState([]);
  const navigate = useNavigate();
  const [initialNames, setInitialNames] = useState({
    modifiedby: user.fullname,
    comments2tasks: data.comments2tasks,
  });
  useEffect(() => {
    const fetchInitialNames = async () => {
      let userName = user.fullname;
      let tasksName = "";

      if (data.comments2tasks) {
        try {
          const tasksResponse = await axios.get(
            `${conf.API_BASE_URL}/taskss/searchById/${data.comments2tasks}`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
                x_account_id: `${user.x_account_id}`,
                x_groupuser_id: `${user.x_groupuserid}`,
                x_poolindex: `${user.x_poolindex}`,
              },
            },
          );
          tasksName = tasksResponse.data.name || "";
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching comments:", error);
          }
        }
      }

      if (
        user.my_groupuserid !== user.x_groupuserid &&
        user.fullname !== data.createdby
      ) {
        setEditMode("readOnly");
      }
      setInitialNames({
        modifiedby: userName,
        comments2tasks: tasksName,
      });
    };

    fetchInitialNames();
  }, [data.comments2tasks, data.modifiedby]);
  const fields = [
    {
      label: "Commented By",
      key: "name",
      maxLength: 100,
      type: "text",
      editable: false,
    },
    {
      label: "Description",
      key: "description",
      maxLength: 4000,
      type: "textarea",
    },
    {
      label: " Tasks for Comments ",
      key: "comments2tasks",
      type: "combo",
      maxLength: 100,
      link: "/tasks",
    },
    {
      label: "Created By",
      key: "createdby",
      editable: false,
      type: "text",
      maxLength: 100,
    },
    {
      label: "Created Date",
      key: "createddate",
      editable: false,
      type: "date",
      maxLength: 100,
    },
    {
      label: "Modified By",
      key: "modifiedby",
      editable: false,
      type: "text",
      maxLength: 100,
    },
    {
      label: "Modified Date",
      key: "modifieddate",
      editable: false,
      type: "date",
      maxLength: 100,
    },
  ];
  useEffect(() => {
    if (searchTerm && editField && editField.includes("2")) {
      const parent = editField.split("2")[1];
      const fetchOptions = async () => {
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/search/${parent}`,
            {
              params: { query: searchTerm },
              headers: {
                Authorization: `Bearer ${user.token}`,
                x_account_id: `${user.x_account_id}`,
                x_groupuser_id: `${user.x_groupuserid}`,
                x_poolindex: `${user.x_poolindex}`,
              },
            },
          );
          setOptions(response.data || []);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching comments:", error);
            setErrorMessage("Error: " + error.response.data.error);
          }
          setOptions([]);
        }
      };

      const debounceFetch = setTimeout(fetchOptions, 300);
      return () => clearTimeout(debounceFetch);
    } else {
      setOptions([]);
    }
  }, [searchTerm, editField]);

  const handleSave = async (formData) => {
    try {
      const response = await axios.put(
        `${conf.API_BASE_URL}/commentss/update/${formData.objid}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Response data:", response.data);
      setSuccessMessage("Updated Comments successfully.");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        setErrorMessage("Failed:" + error.response.data.error);
        console.error("Error fetching comments:", error);
      }
    }
  };

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Comments", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        const customFieldsData = response.data || [];

        // Use a Set to ensure custom fields are only added once
        const customFieldSet = new Set(fields.map((field) => field.key));

        customFieldsData.forEach((customField) => {
          const customFieldObject = {
            label: customField.label,
            key: `customdata.${customField.name}`,
            maxLength: customField.maxlength || 100,
            type: customField.fieldtype || "text",
          };

          // Find the index of the first field whose key contains "2"
          let index = fields.findIndex((field) => field.key.includes("2"));

          // If no such field is found, find the index of the "Created By" field
          if (index === -1) {
            index = fields.findIndex((field) => field.key === "createdby");
          }

          // Insert the custom field at the found index if it hasn't been added already
          if (!customFieldSet.has(customFieldObject.key)) {
            fields.splice(index, 0, customFieldObject);
            customFieldSet.add(customFieldObject.key);
            console.log(
              "adding custom fields=" + JSON.stringify(customFieldObject.key),
            );
          }
        });

        console.log("custom fields=" + JSON.stringify(fields));

        // Initialize updatedFields with the initial fields array
        setUpdatedFields(fields);

        // Fetch options for select fields
        fields.forEach((field) => {
          if (field.type === "select" && field.fetchOptions) {
            fetchOptions(field.key, field.fetchOptions);
          }
        });
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    const fetchOptions = async (fieldName, url) => {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        });

        if (Array.isArray(response.data)) {
          const optionsObject = response.data.reduce((acc, item) => {
            const key = item.objid || item.propertyvalue || item.codevalue;
            const value = item.name || item.propertystring;
            if (key && value) {
              acc[key] = value;
            }
            return acc;
          }, {});

          setOptions((prevOptions) => ({
            ...prevOptions,
            [fieldName]: optionsObject,
          }));

          // Update the fields array with the fetched options
          setUpdatedFields((prevFields) =>
            prevFields.map((field) =>
              field.key === fieldName
                ? { ...field, options: optionsObject }
                : field,
            ),
          );
        } else {
          console.error(`Expected an array but got:`, response.data);
        }
      } catch (error) {
        if (error.response && error.response.data.error === "Invalid token") {
          logout("Auth Token Expired. Please login again");
        } else {
          console.error(`Error fetching options for ${fieldName}:`, error);
        }
      }
    };

    fetchCustomFields();
  }, [user]);

  let fld = null;
  return (
    <table className="table-comments">
      <tbody>
        {updatedFields.map((field, index) => {
          if (field.maxLength && field.maxLength >= 300) {
            fld = field;
            return render300Plus(
              field,
              editField,
              editValue,
              modifiedData,
              handleSaveClick,
              handleEditClick,
              handleChange,
              setEditField,
              setEditValue,
              setModifiedData,
              handleSave,
            );
          } else if (fld !== field) {
            fld =
              updatedFields[index + 1] &&
              updatedFields[index + 1].maxLength &&
              updatedFields[index + 1].maxLength < 300
                ? updatedFields[index + 1]
                : null;
            return renderUpto300BasedOnMode(
              field,
              fld,
              editField,
              editValue,
              modifiedData,
              handleSaveClick,
              handleEditClick,
              handleChange,
              handleComboChange,
              handleSearchChange,
              handleFileChange,
              handleSave,
              handleCheckboxChange,
              options,
              searchTerm,
              initialNames,
              setEditField,
              setEditValue,
              setSearchTerm,
              setModifiedData,
              renderField,
              renderFieldReadOnly,
              setInitialNames,
              editMode,
            );
          }
          return null;
        })}
      </tbody>
    </table>
  );
};

export default TableComments;
