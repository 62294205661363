import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Rule Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "tablename",
    label: "Table Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "effectedtable",
    label: "Effected Table",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 4000,
    required: false,
  },
  {
    name: "reason",
    label: "Reason",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Reason&table=ObjectRule`,
  },
  {
    name: "actionstate",
    label: "Action State",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=ActionState&table=ObjectRule`,
  },
  {
    name: "condition",
    label: "Condition ($)",
    type: "text",
    maxLength: 500,
    required: true,
  },
  {
    name: "ruleindex",
    label: "Rule Index",
    type: "text",
    maxLength: 16,
    required: true,
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Status&table=ObjectRule`,
  },
  {
    name: "objectrule2object",
    maxLength: 100,
    label: "Object",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/object`,
  },
];
const ObjectRuleFormContainer = ({
  propObjectRule,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propObjectRule?.objid || null,
    name: propObjectRule?.name || null,
    tablename: propObjectRule?.tablename || null,
    effectedtable: propObjectRule?.effectedtable || null,
    description: propObjectRule?.description || null,
    reason: propObjectRule?.reason || null,
    actionstate: propObjectRule?.actionstate || null,
    condition: propObjectRule?.condition || null,
    ruleindex: propObjectRule?.ruleindex || null,
    status: propObjectRule?.status || null,
    objectrule2object: propObjectRule?.objectrule2object,
    customdata: propObjectRule?.customdata || null,
    groupuserid: propObjectRule?.x_groupuserid || user.x_groupuserid,
    createdbyid: propObjectRule?.createdbyid || user.id,
    createdby: propObjectRule?.createdby || user.fullname,
    createddate: propObjectRule?.createddate
      ? formatDate(propObjectRule.createddate)
      : today,
    modifiedby: propObjectRule?.modifiedby || user.fullname,
    modifieddate: propObjectRule?.modifieddate
      ? formatDate(propObjectRule.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "ObjectRule", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="objectrule"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default ObjectRuleFormContainer;
