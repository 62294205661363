import React from "react";
import Modal from "react-modal";
import UserFormContainer from "./UserFormContainer";
import "./UserForm.css";
const UserFormModal = ({
  isOpen,
  onRequestClose,
  user,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="User Form"
    >
      <div className="modal-content">
        <UserFormContainer
          propUser={user}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default UserFormModal;
