import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import EpicFormModal from "./EpicFormModal";
import FeatureFormModal from "../feature/FeatureFormModal";

const RightSidebar = ({ data, onFeatureSubmit, onEpicSubmit }) => {
  const [isFeatureModalOpen, setIsFeatureModalOpen] = useState(false);
  const [isEpicModalOpen, setIsEpicModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated features
      const featureResponse = await axios.get(
        `${conf.API_BASE_URL}/search/feature?query=${data.objid}&field=feature2epic`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (featureResponse.data.length > 0) {
        // If there are associated features, set the message
        setMessage("This epic has associated features and cannot be deleted.");
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/epics/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Epic Deleted:", response.data);
      navigate("/setup/epic");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching epic:", error);
      }
    }
  };

  const openFeatureModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsFeatureModalOpen(true);
  };

  const closeFeatureModal = () => {
    setIsFeatureModalOpen(false);
  };

  const handleCustomFeatureSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeFeatureModal();
      onFeatureSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching feature:", error);
      }
    }
  };
  const openEpicModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsEpicModalOpen(true);
  };

  const closeEpicModal = () => {
    setIsEpicModalOpen(false);
  };
  const handleCustomEpicSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeEpicModal();
      onEpicSubmit(response.data); // Pass the new epic to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Epic
          </a>
        </li>
        <li>
          <a href="#" onClick={openEpicModal}>
            Clone Epic
          </a>
        </li>
        <li>
          <a href="# " onClick={openFeatureModal}>
            Add Feature
          </a>
        </li>
      </ul>
      <FeatureFormModal
        isOpen={isFeatureModalOpen}
        onRequestClose={closeFeatureModal}
        feature={{ feature2epic: data?.objid }} // Pass the feature data if needed
        onSubmit={handleCustomFeatureSubmit}
        cloneData={null}
      />
      <EpicFormModal
        isOpen={isEpicModalOpen}
        onRequestClose={closeEpicModal}
        epic={null} // Pass the project data if needed
        onSubmit={handleCustomEpicSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
