import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditDefect.css";
import TableDefect from "./TableDefect";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import DefectDocumentList from "../defectdocument/DefectDocumentList";
import NotesList from "../notes/NotesList";

const EditDefect = ({ defectId: propDefectId }) => {
  const [defect, setDefect] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableDefect, setShowTableDefect] = useState(false);
  const [defectdocuments, setDefectDocuments] = useState([]);
  const [notess, setNotess] = useState([]);

  const { id } = useParams();
  const defectId = id || propDefectId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchDefect = async () => {
        if (!defectId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/defects/searchById/${defectId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setDefect(response.data);
            setEditLoad(true);
            console.log("Fetched defect data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching defect:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchDefect();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/defectdocument?query=${defectId}&field=defectdocument2defect`,
        setDefectDocuments,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/notes?query=${defectId}&field=notes2defect`,
        setNotess,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, defectId]);

  const handleDefectSubmit = (newDefect) => {
    navigate(`/defect/${newDefect.objid}`);
  };

  const handleDefectDocumentSubmit = (newDefectDocument) => {
    setDefectDocuments((defectdocuments) => [
      newDefectDocument,
      ...defectdocuments,
    ]);
    setShowTableDefect(true);
  };
  const handleNotesSubmit = (newNotes) => {
    setNotess((notess) => [newNotes, ...notess]);
    setShowTableDefect(true);
  };
  return (
    <div className="edit-defect-container">
      <div className="main-content">
        <h1>Edit Defect</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, defect)}
        {editLoad ? (
          <TableDefect
            data={defect}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <DefectDocumentList defectdocuments={defectdocuments} />
        <NotesList notess={notess} />
      </div>
      <RightSidebar
        data={defect}
        onDefectDocumentSubmit={handleDefectDocumentSubmit}
        onNotesSubmit={handleNotesSubmit}
        onDefectSubmit={handleDefectSubmit}
      />
    </div>
  );
};

export default EditDefect;
