import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditBilling.css";
import TableBilling from "./TableBilling";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";

const EditBilling = ({ billingId: propBillingId }) => {
  const [billing, setBilling] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableBilling, setShowTableBilling] = useState(false);
  const { id } = useParams();
  const billingId = id || propBillingId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchBilling = async () => {
        if (!billingId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/billings/searchById/${billingId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setBilling(response.data);
            setEditLoad(true);
            console.log("Fetched billing data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching billing:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchBilling();
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, billingId]);

  const handleBillingSubmit = (newBilling) => {
    navigate(`/billing/${newBilling.objid}`);
  };

  return (
    <div className="edit-billing-container">
      <div className="main-content">
        <h1>Edit Billing</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, billing)}
        {editLoad ? (
          <TableBilling
            data={billing}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <RightSidebar data={billing} onBillingSubmit={handleBillingSubmit} />
    </div>
  );
};

export default EditBilling;
