import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import TestSuiteFormModal from "./TestSuiteFormModal";
import DefectFormModal from "../defect/DefectFormModal";
import SuiteTagFormModal from "../suitetag/SuiteTagFormModal";
import TestFailureFormModal from "../testfailure/TestFailureFormModal";
import TestLogFormModal from "../testlog/TestLogFormModal";
import TestResultFormModal from "../testresult/TestResultFormModal";

const RightSidebar = ({
  data,
  onDefectSubmit,
  onSuiteTagSubmit,
  onTestFailureSubmit,
  onTestLogSubmit,
  onTestResultSubmit,
  onTestSuiteSubmit,
}) => {
  const [isDefectModalOpen, setIsDefectModalOpen] = useState(false);
  const [isSuiteTagModalOpen, setIsSuiteTagModalOpen] = useState(false);
  const [isTestFailureModalOpen, setIsTestFailureModalOpen] = useState(false);
  const [isTestLogModalOpen, setIsTestLogModalOpen] = useState(false);
  const [isTestResultModalOpen, setIsTestResultModalOpen] = useState(false);
  const [isTestSuiteModalOpen, setIsTestSuiteModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated defects
      const defectResponse = await axios.get(
        `${conf.API_BASE_URL}/search/defect?query=${data.objid}&field=defect2testsuite`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (defectResponse.data.length > 0) {
        // If there are associated defects, set the message
        setMessage(
          "This testsuite has associated defects and cannot be deleted.",
        );
        return;
      }
      // Fetch associated suitetags
      const suitetagResponse = await axios.get(
        `${conf.API_BASE_URL}/search/suitetag?query=${data.objid}&field=suitetag2testsuite`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (suitetagResponse.data.length > 0) {
        // If there are associated suitetags, set the message
        setMessage(
          "This testsuite has associated suitetags and cannot be deleted.",
        );
        return;
      }
      // Fetch associated testfailures
      const testfailureResponse = await axios.get(
        `${conf.API_BASE_URL}/search/testfailure?query=${data.objid}&field=testfailure2testsuite`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (testfailureResponse.data.length > 0) {
        // If there are associated testfailures, set the message
        setMessage(
          "This testsuite has associated testfailures and cannot be deleted.",
        );
        return;
      }
      // Fetch associated testlogs
      const testlogResponse = await axios.get(
        `${conf.API_BASE_URL}/search/testlog?query=${data.objid}&field=testlog2testsuite`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (testlogResponse.data.length > 0) {
        // If there are associated testlogs, set the message
        setMessage(
          "This testsuite has associated testlogs and cannot be deleted.",
        );
        return;
      }
      // Fetch associated testresults
      const testresultResponse = await axios.get(
        `${conf.API_BASE_URL}/search/testresult?query=${data.objid}&field=testresult2testsuite`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (testresultResponse.data.length > 0) {
        // If there are associated testresults, set the message
        setMessage(
          "This testsuite has associated testresults and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/testsuites/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("TestSuite Deleted:", response.data);
      navigate("/setup/testsuite");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testsuite:", error);
      }
    }
  };

  const openDefectModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDefectModalOpen(true);
  };

  const closeDefectModal = () => {
    setIsDefectModalOpen(false);
  };

  const handleCustomDefectSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeDefectModal();
      onDefectSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching defect:", error);
      }
    }
  };

  const openSuiteTagModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSuiteTagModalOpen(true);
  };

  const closeSuiteTagModal = () => {
    setIsSuiteTagModalOpen(false);
  };

  const handleCustomSuiteTagSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeSuiteTagModal();
      onSuiteTagSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching suitetag:", error);
      }
    }
  };

  const openTestFailureModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestFailureModalOpen(true);
  };

  const closeTestFailureModal = () => {
    setIsTestFailureModalOpen(false);
  };

  const handleCustomTestFailureSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestFailureModal();
      onTestFailureSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testfailure:", error);
      }
    }
  };

  const openTestLogModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestLogModalOpen(true);
  };

  const closeTestLogModal = () => {
    setIsTestLogModalOpen(false);
  };

  const handleCustomTestLogSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestLogModal();
      onTestLogSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testlog:", error);
      }
    }
  };

  const openTestResultModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestResultModalOpen(true);
  };

  const closeTestResultModal = () => {
    setIsTestResultModalOpen(false);
  };

  const handleCustomTestResultSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestResultModal();
      onTestResultSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testresult:", error);
      }
    }
  };
  const openTestSuiteModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestSuiteModalOpen(true);
  };

  const closeTestSuiteModal = () => {
    setIsTestSuiteModalOpen(false);
  };
  const handleCustomTestSuiteSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestSuiteModal();
      onTestSuiteSubmit(response.data); // Pass the new testsuite to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete TestSuite
          </a>
        </li>
        <li>
          <a href="#" onClick={openTestSuiteModal}>
            Clone TestSuite
          </a>
        </li>
        <li>
          <a href="# " onClick={openDefectModal}>
            Add Defect
          </a>
        </li>
        <li>
          <a href="# " onClick={openSuiteTagModal}>
            Add SuiteTag
          </a>
        </li>
        <li>
          <a href="# " onClick={openTestFailureModal}>
            Add Test Failure
          </a>
        </li>
        <li>
          <a href="# " onClick={openTestLogModal}>
            Add Test Log
          </a>
        </li>
        <li>
          <a href="# " onClick={openTestResultModal}>
            Add TestResult
          </a>
        </li>
      </ul>
      <DefectFormModal
        isOpen={isDefectModalOpen}
        onRequestClose={closeDefectModal}
        defect={{ defect2testsuite: data?.objid }} // Pass the defect data if needed
        onSubmit={handleCustomDefectSubmit}
        cloneData={null}
      />
      <SuiteTagFormModal
        isOpen={isSuiteTagModalOpen}
        onRequestClose={closeSuiteTagModal}
        suitetag={{ suitetag2testsuite: data?.objid }} // Pass the suitetag data if needed
        onSubmit={handleCustomSuiteTagSubmit}
        cloneData={null}
      />
      <TestFailureFormModal
        isOpen={isTestFailureModalOpen}
        onRequestClose={closeTestFailureModal}
        testfailure={{ testfailure2testsuite: data?.objid }} // Pass the testfailure data if needed
        onSubmit={handleCustomTestFailureSubmit}
        cloneData={null}
      />
      <TestLogFormModal
        isOpen={isTestLogModalOpen}
        onRequestClose={closeTestLogModal}
        testlog={{ testlog2testsuite: data?.objid }} // Pass the testlog data if needed
        onSubmit={handleCustomTestLogSubmit}
        cloneData={null}
      />
      <TestResultFormModal
        isOpen={isTestResultModalOpen}
        onRequestClose={closeTestResultModal}
        testresult={{ testresult2testsuite: data?.objid }} // Pass the testresult data if needed
        onSubmit={handleCustomTestResultSubmit}
        cloneData={null}
      />
      <TestSuiteFormModal
        isOpen={isTestSuiteModalOpen}
        onRequestClose={closeTestSuiteModal}
        testsuite={null} // Pass the project data if needed
        onSubmit={handleCustomTestSuiteSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
