import React from "react";
import Modal from "react-modal";
import GroupLicenseFormContainer from "./GroupLicenseFormContainer";
import "./GroupLicenseForm.css";
const GroupLicenseFormModal = ({
  isOpen,
  onRequestClose,
  grouplicense,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="GroupLicense Form"
    >
      <div className="modal-content">
        <GroupLicenseFormContainer
          propGroupLicense={grouplicense}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default GroupLicenseFormModal;
