import React from "react";
import Modal from "react-modal";
import TagFormContainer from "./TagFormContainer";
import "./TagForm.css";
const TagFormModal = ({ isOpen, onRequestClose, tag, onSubmit, cloneData }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Tag Form"
    >
      <div className="modal-content">
        <TagFormContainer
          propTag={tag}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default TagFormModal;
