import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditDockerImage.css";
import TableDockerImage from "./TableDockerImage";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import TestSuiteList from "../testsuite/TestSuiteList";

const EditDockerImage = ({ dockerimageId: propDockerImageId }) => {
  const [dockerimage, setDockerImage] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableDockerImage, setShowTableDockerImage] = useState(false);
  const [testsuites, setTestSuites] = useState([]);

  const { id } = useParams();
  const dockerimageId = id || propDockerImageId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchDockerImage = async () => {
        if (!dockerimageId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/dockerimages/searchById/${dockerimageId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setDockerImage(response.data);
            setEditLoad(true);
            console.log("Fetched dockerimage data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching dockerimage:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchDockerImage();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testsuite?query=${dockerimageId}&field=testsuite2dockerimage`,
        setTestSuites,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, dockerimageId]);

  const handleDockerImageSubmit = (newDockerImage) => {
    handleDockerImage(newDockerImage);
  };
  const handleTestSuiteSubmit = (newTestSuite) => {
    setTestSuites((testsuites) => [newTestSuite, ...testsuites]);
    setShowTableDockerImage(true);
  };

  const handleDockerImage = async (data) => {
    try {
      const response = await axios.post(
        `${conf.API_BASE_URL}/docker/create-image`,
        data, // Include the payload here
        {
          headers: {
            Authorization: `Bearer ${memoizedUser.token}`,
            x_account_id: `${memoizedUser.x_account_id}`,
            x_groupuser_id: `${memoizedUser.x_groupuserid}`,
            x_poolindex: `${memoizedUser.x_poolindex}`,
          },
        },
      );
      console.log("Docker Image creation response:", response.data);
      if (response.status === 200) {
        setSuccessMessage("Image created successfully!");
        console.log("Image created successfully:", response.data);
        // Navigate to the new docker image page
        navigate(`/dockerimage/${data.objid}`);
        // Fetch the updated Docker image data to re-render the component
      } else {
        console.error("Error creating image:", response.data);
      }
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error creating Docker Image:", error);
        setErrorMessage("Image creation failed! " + error.response.data.error);
      }
    }
  };
  return (
    <div className="edit-dockerimage-container">
      <div className="main-content">
        <h1>Edit Test Image</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, dockerimage)}
        {editLoad ? (
          <TableDockerImage
            data={dockerimage}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <TestSuiteList testsuites={testsuites} />
      </div>
      <RightSidebar
        data={dockerimage}
        onTestSuiteSubmit={handleTestSuiteSubmit}
        onDockerImageSubmit={handleDockerImageSubmit}
      />
    </div>
  );
};

export default EditDockerImage;
