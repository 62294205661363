import mode from "./runenv";
let config = {};

if (mode.RUN_MODE === "dev") {
  config = {
    API_BASE_URL: "http://localhost:3000/api",
    AUTH_URL: "http://localhost:3000/auth",
    USER_TYPE_REGISTRATION: "registration",
    USER_TYPE_LOGIN: "login",
    RECAPTCHA_SITE_KEY: "6LdTUYMqAAAAAC4R_DHwwega5ihO64WNSAB1So_o",
  };
} else {
  config = {
    API_BASE_URL: "https://projextest.com/api",
    AUTH_URL: "https://projextest.com/auth",
    USER_TYPE_REGISTRATION: "registration",
    USER_TYPE_LOGIN: "login",
    RECAPTCHA_SITE_KEY: "6LdTUYMqAAAAAC4R_DHwwega5ihO64WNSAB1So_o",
  };
}

export default config;
