import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import TestFailureFormModal from "./TestFailureFormModal";
import DefectFormModal from "../defect/DefectFormModal";

const RightSidebar = ({ data, onDefectSubmit, onTestFailureSubmit }) => {
  const [isDefectModalOpen, setIsDefectModalOpen] = useState(false);
  const [isTestFailureModalOpen, setIsTestFailureModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated defects
      const defectResponse = await axios.get(
        `${conf.API_BASE_URL}/search/defect?query=${data.objid}&field=defect2testfailure`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (defectResponse.data.length > 0) {
        // If there are associated defects, set the message
        setMessage(
          "This testfailure has associated defects and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/testfailures/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("TestFailure Deleted:", response.data);
      navigate("/setup/testfailure");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testfailure:", error);
      }
    }
  };

  const openDefectModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDefectModalOpen(true);
  };

  const closeDefectModal = () => {
    setIsDefectModalOpen(false);
  };

  const handleCustomDefectSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeDefectModal();
      onDefectSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching defect:", error);
      }
    }
  };
  const openTestFailureModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestFailureModalOpen(true);
  };

  const closeTestFailureModal = () => {
    setIsTestFailureModalOpen(false);
  };
  const handleCustomTestFailureSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestFailureModal();
      onTestFailureSubmit(response.data); // Pass the new testfailure to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete TestFailure
          </a>
        </li>
        <li>
          <a href="#" onClick={openTestFailureModal}>
            Clone TestFailure
          </a>
        </li>
        <li>
          <a href="# " onClick={openDefectModal}>
            Add Defect
          </a>
        </li>
      </ul>
      <DefectFormModal
        isOpen={isDefectModalOpen}
        onRequestClose={closeDefectModal}
        defect={{ defect2testfailure: data?.objid }} // Pass the defect data if needed
        onSubmit={handleCustomDefectSubmit}
        cloneData={null}
      />
      <TestFailureFormModal
        isOpen={isTestFailureModalOpen}
        onRequestClose={closeTestFailureModal}
        testfailure={null} // Pass the project data if needed
        onSubmit={handleCustomTestFailureSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
