import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import ProductFormModal from "./ProductFormModal";
import ProjectFormModal from "../project/ProjectFormModal";
import ReleaseFormModal from "../release/ReleaseFormModal";

const RightSidebar = ({
  data,
  onProjectSubmit,
  onReleaseSubmit,
  onProductSubmit,
}) => {
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [isReleaseModalOpen, setIsReleaseModalOpen] = useState(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated projects
      const projectResponse = await axios.get(
        `${conf.API_BASE_URL}/search/project?query=${data.objid}&field=project2product`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (projectResponse.data.length > 0) {
        // If there are associated projects, set the message
        setMessage(
          "This product has associated projects and cannot be deleted.",
        );
        return;
      }
      // Fetch associated releases
      const releaseResponse = await axios.get(
        `${conf.API_BASE_URL}/search/release?query=${data.objid}&field=release2product`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (releaseResponse.data.length > 0) {
        // If there are associated releases, set the message
        setMessage(
          "This product has associated releases and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/products/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Product Deleted:", response.data);
      navigate("/setup/product");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching product:", error);
      }
    }
  };

  const openProjectModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsProjectModalOpen(true);
  };

  const closeProjectModal = () => {
    setIsProjectModalOpen(false);
  };

  const handleCustomProjectSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeProjectModal();
      onProjectSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching project:", error);
      }
    }
  };

  const openReleaseModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsReleaseModalOpen(true);
  };

  const closeReleaseModal = () => {
    setIsReleaseModalOpen(false);
  };

  const handleCustomReleaseSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeReleaseModal();
      onReleaseSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching release:", error);
      }
    }
  };
  const openProductModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsProductModalOpen(true);
  };

  const closeProductModal = () => {
    setIsProductModalOpen(false);
  };
  const handleCustomProductSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeProductModal();
      onProductSubmit(response.data); // Pass the new product to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Product
          </a>
        </li>
        <li>
          <a href="#" onClick={openProductModal}>
            Clone Product
          </a>
        </li>
        <li>
          <a href="# " onClick={openProjectModal}>
            Add Project
          </a>
        </li>
        <li>
          <a href="# " onClick={openReleaseModal}>
            Add Release
          </a>
        </li>
      </ul>
      <ProjectFormModal
        isOpen={isProjectModalOpen}
        onRequestClose={closeProjectModal}
        project={{ project2product: data?.objid }} // Pass the project data if needed
        onSubmit={handleCustomProjectSubmit}
        cloneData={null}
      />
      <ReleaseFormModal
        isOpen={isReleaseModalOpen}
        onRequestClose={closeReleaseModal}
        release={{ release2product: data?.objid }} // Pass the release data if needed
        onSubmit={handleCustomReleaseSubmit}
        cloneData={null}
      />
      <ProductFormModal
        isOpen={isProductModalOpen}
        onRequestClose={closeProductModal}
        product={null} // Pass the project data if needed
        onSubmit={handleCustomProductSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
