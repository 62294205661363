import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditObject.css";
import TableObject from "./TableObject";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import AttributeList from "../attribute/AttributeList";
import ListPropertyList from "../listproperty/ListPropertyList";
import ObjectRuleList from "../objectrule/ObjectRuleList";

const EditObject = ({ objectId: propObjectId }) => {
  const [object, setObject] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableObject, setShowTableObject] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [listpropertys, setListPropertys] = useState([]);
  const [objectrules, setObjectRules] = useState([]);

  const { id } = useParams();
  const objectId = id || propObjectId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchObject = async () => {
        if (!objectId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/objects/searchById/${objectId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setObject(response.data);
            setEditLoad(true);
            console.log("Fetched object data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching object:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchObject();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/attribute?query=${objectId}&field=attribute2object`,
        setAttributes,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/listproperty?query=${objectId}&field=listproperty2object`,
        setListPropertys,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/objectrule?query=${objectId}&field=objectrule2object`,
        setObjectRules,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, objectId]);

  const handleObjectSubmit = (newObject) => {
    navigate(`/object/${newObject.objid}`);
  };

  const handleAttributeSubmit = (newAttribute) => {
    setAttributes((attributes) => [newAttribute, ...attributes]);
    setShowTableObject(true);
  };
  const handleListPropertySubmit = (newListProperty) => {
    setListPropertys((listpropertys) => [newListProperty, ...listpropertys]);
    setShowTableObject(true);
  };
  const handleObjectRuleSubmit = (newObjectRule) => {
    setObjectRules((objectrules) => [newObjectRule, ...objectrules]);
    setShowTableObject(true);
  };
  return (
    <div className="edit-object-container">
      <div className="main-content">
        <h1>Edit Object</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, object)}
        {editLoad ? (
          <TableObject
            data={object}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <AttributeList attributes={attributes} />
        <ListPropertyList listpropertys={listpropertys} />
        <ObjectRuleList objectrules={objectrules} />
      </div>
      <RightSidebar
        data={object}
        onAttributeSubmit={handleAttributeSubmit}
        onListPropertySubmit={handleListPropertySubmit}
        onObjectRuleSubmit={handleObjectRuleSubmit}
        onObjectSubmit={handleObjectSubmit}
      />
    </div>
  );
};

export default EditObject;
