import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 4000,
    required: false,
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Status&table=Defect`,
  },
  {
    name: "creationdate",
    label: "Creation Date",
    type: "date",
    maxLength: 10,
    required: true,
  },
  {
    name: "type",
    label: "Type",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Type&table=Defect`,
  },
  {
    name: "priority",
    label: "Priority",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Priority&table=Defect`,
  },
  {
    name: "severity",
    label: "Severity",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Severity&table=Defect`,
  },
  {
    name: "reporturl",
    label: "Report URL",
    type: "url",
    maxLength: 4000,
    required: false,
  },
  {
    name: "logurl",
    label: "Log URL",
    type: "url",
    maxLength: 300,
    required: false,
  },
  {
    name: "defect2assignee",
    maxLength: 100,
    label: "Assignee",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/assignee`,
  },
  {
    name: "defect2scenario",
    maxLength: 100,
    label: "Scenario",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/scenario`,
  },
  {
    name: "defect2testsuite",
    maxLength: 100,
    label: "TestSuite",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/testsuite`,
  },
  {
    name: "defect2testcycle",
    maxLength: 100,
    label: "TestCycle",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/testcycle`,
  },
  {
    name: "defect2testfailure",
    maxLength: 100,
    label: "TestFailure",
    type: "select",
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/testfailure`,
  },
];
const DefectFormContainer = ({ propDefect, onSubmit, onCancel, cloneData }) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propDefect?.objid || null,
    name: propDefect?.name || null,
    description: propDefect?.description || null,
    status: propDefect?.status || null,
    creationdate: propDefect?.creationdate || null,
    type: propDefect?.type || null,
    priority: propDefect?.priority || null,
    severity: propDefect?.severity || null,
    reporturl: propDefect?.reporturl || null,
    logurl: propDefect?.logurl || null,
    defect2assignee: propDefect?.defect2assignee,
    defect2scenario: propDefect?.defect2scenario,
    defect2testsuite: propDefect?.defect2testsuite,
    defect2testcycle: propDefect?.defect2testcycle,
    defect2testfailure: propDefect?.defect2testfailure,
    customdata: propDefect?.customdata || null,
    groupuserid: propDefect?.x_groupuserid || user.x_groupuserid,
    createdbyid: propDefect?.createdbyid || user.id,
    createdby: propDefect?.createdby || user.fullname,
    createddate: propDefect?.createddate
      ? formatDate(propDefect.createddate)
      : today,
    modifiedby: propDefect?.modifiedby || user.fullname,
    modifieddate: propDefect?.modifieddate
      ? formatDate(propDefect.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Defect", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="defect"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default DefectFormContainer;
