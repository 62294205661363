import React from "react";
import Modal from "react-modal";
import EmailSettingFormContainer from "./EmailSettingFormContainer";
import "./EmailSettingForm.css";
const EmailSettingFormModal = ({
  isOpen,
  onRequestClose,
  emailsetting,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="EmailSetting Form"
    >
      <div className="modal-content">
        <EmailSettingFormContainer
          propEmailSetting={emailsetting}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default EmailSettingFormModal;
