import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import UserGroupFormModal from "./UserGroupFormModal";
import UserFormModal from "../user/UserFormModal";
import ModuleFormModal from "../module/ModuleFormModal";
import ObjectAccessFormModal from "../objectaccess/ObjectAccessFormModal";

const RightSidebar = ({
  data,
  onUserSubmit,
  onModuleSubmit,
  onObjectAccessSubmit,
  onUserGroupSubmit,
}) => {
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isModuleModalOpen, setIsModuleModalOpen] = useState(false);
  const [isObjectAccessModalOpen, setIsObjectAccessModalOpen] = useState(false);
  const [isUserGroupModalOpen, setIsUserGroupModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated users
      const userResponse = await axios.get(
        `${conf.API_BASE_URL}/search/user?query=${data.objid}&field=user2usergroup`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (userResponse.data.length > 0) {
        // If there are associated users, set the message
        setMessage(
          "This usergroup has associated users and cannot be deleted.",
        );
        return;
      }
      // Fetch associated modules
      const moduleResponse = await axios.get(
        `${conf.API_BASE_URL}/search/module?query=${data.objid}&field=module2usergroup`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (moduleResponse.data.length > 0) {
        // If there are associated modules, set the message
        setMessage(
          "This usergroup has associated modules and cannot be deleted.",
        );
        return;
      }
      // Fetch associated objectaccesss
      const objectaccessResponse = await axios.get(
        `${conf.API_BASE_URL}/search/objectaccess?query=${data.objid}&field=objectaccess2usergroup`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (objectaccessResponse.data.length > 0) {
        // If there are associated objectaccesss, set the message
        setMessage(
          "This usergroup has associated objectaccesss and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/usergroups/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("UserGroup Deleted:", response.data);
      navigate("/setup/usergroup");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching usergroup:", error);
      }
    }
  };

  const openUserModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsUserModalOpen(true);
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
  };

  const handleCustomUserSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeUserModal();
      onUserSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching user:", error);
      }
    }
  };

  const openModuleModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsModuleModalOpen(true);
  };

  const closeModuleModal = () => {
    setIsModuleModalOpen(false);
  };

  const handleCustomModuleSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeModuleModal();
      onModuleSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching module:", error);
      }
    }
  };

  const openObjectAccessModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsObjectAccessModalOpen(true);
  };

  const closeObjectAccessModal = () => {
    setIsObjectAccessModalOpen(false);
  };

  const handleCustomObjectAccessSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeObjectAccessModal();
      onObjectAccessSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching objectaccess:", error);
      }
    }
  };
  const openUserGroupModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsUserGroupModalOpen(true);
  };

  const closeUserGroupModal = () => {
    setIsUserGroupModalOpen(false);
  };
  const handleCustomUserGroupSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeUserGroupModal();
      onUserGroupSubmit(response.data); // Pass the new usergroup to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete UserGroup
          </a>
        </li>
        <li>
          <a href="#" onClick={openUserGroupModal}>
            Clone UserGroup
          </a>
        </li>
        <li>
          <a href="# " onClick={openUserModal}>
            Add App User
          </a>
        </li>
        <li>
          <a href="# " onClick={openModuleModal}>
            Add Module
          </a>
        </li>
        <li>
          <a href="# " onClick={openObjectAccessModal}>
            Add ObjectAccess
          </a>
        </li>
      </ul>
      <UserFormModal
        isOpen={isUserModalOpen}
        onRequestClose={closeUserModal}
        user={{ user2usergroup: data?.objid }} // Pass the user data if needed
        onSubmit={handleCustomUserSubmit}
        cloneData={null}
      />
      <ModuleFormModal
        isOpen={isModuleModalOpen}
        onRequestClose={closeModuleModal}
        module={{ module2usergroup: data?.objid }} // Pass the module data if needed
        onSubmit={handleCustomModuleSubmit}
        cloneData={null}
      />
      <ObjectAccessFormModal
        isOpen={isObjectAccessModalOpen}
        onRequestClose={closeObjectAccessModal}
        objectaccess={{ objectaccess2usergroup: data?.objid }} // Pass the objectaccess data if needed
        onSubmit={handleCustomObjectAccessSubmit}
        cloneData={null}
      />
      <UserGroupFormModal
        isOpen={isUserGroupModalOpen}
        onRequestClose={closeUserGroupModal}
        usergroup={null} // Pass the project data if needed
        onSubmit={handleCustomUserGroupSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
