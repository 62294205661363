import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditBoard.css";
import TableBoard from "./TableBoard";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import MemberList from "../member/MemberList";
import SprintList from "../sprint/SprintList";
import BoardTasks from "../../common/BoardTasks";

const EditBoard = ({ boardId: propBoardId }) => {
  const [board, setBoard] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableBoard, setShowTableBoard] = useState(false);
  const [members, setMembers] = useState([]);
  const [sprints, setSprints] = useState([]);

  const { id } = useParams();
  const boardId = id || propBoardId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchBoard = async () => {
        if (!boardId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/boards/searchById/${boardId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setBoard(response.data);
            setEditLoad(true);
            console.log("Fetched board data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching board:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchBoard();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/member?query=${boardId}&field=member2board`,
        setMembers,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/sprint?query=${boardId}&field=sprint2board`,
        setSprints,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, boardId]);

  const handleBoardSubmit = (newBoard) => {
    navigate(`/board/${newBoard.objid}`);
  };

  const handleMemberSubmit = (newMember) => {
    setMembers((members) => [newMember, ...members]);
    setShowTableBoard(true);
  };
  const handleSprintSubmit = (newSprint) => {
    setSprints((sprints) => [newSprint, ...sprints]);
    setShowTableBoard(true);
  };
  return (
    <div className="edit-board-container">
      <div className="main-content">
        <h1>Edit Board</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, board)}
        {editLoad ? (
          <TableBoard
            data={board}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <MemberList members={members} />
        <SprintList sprints={sprints} />
        <BoardTasks inputId={"boardid"} inputValue={boardId} />
      </div>
      <RightSidebar
        data={board}
        onMemberSubmit={handleMemberSubmit}
        onSprintSubmit={handleSprintSubmit}
        onBoardSubmit={handleBoardSubmit}
      />
    </div>
  );
};

export default EditBoard;
