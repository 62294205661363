import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditAccount.css";
import TableAccount from "./TableAccount";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import UserList from "../user/UserList";
import SubscriptionList from "../subscription/SubscriptionList";

const EditAccount = ({ accountId: propAccountId }) => {
  const [account, setAccount] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableAccount, setShowTableAccount] = useState(false);
  const [users, setUsers] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  const { id } = useParams();
  const accountId = id || propAccountId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchAccount = async () => {
        if (!accountId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/accounts/searchById/${accountId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setAccount(response.data);
            setEditLoad(true);
            console.log("Fetched account data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching account:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchAccount();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/user?query=${accountId}&field=user2account`,
        setUsers,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/subscription?query=${accountId}&field=subscription2account`,
        setSubscriptions,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, accountId]);

  const handleAccountSubmit = (newAccount) => {
    navigate(`/account/${newAccount.objid}`);
  };

  const handleUserSubmit = (newUser) => {
    setUsers((users) => [newUser, ...users]);
    setShowTableAccount(true);
  };
  const handleSubscriptionSubmit = (newSubscription) => {
    setSubscriptions((subscriptions) => [newSubscription, ...subscriptions]);
    setShowTableAccount(true);
  };
  return (
    <div className="edit-account-container">
      <div className="main-content">
        <h1>Edit Account</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, account)}
        {editLoad ? (
          <TableAccount
            data={account}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <UserList users={users} />
        <SubscriptionList subscriptions={subscriptions} />
      </div>
      <RightSidebar
        data={account}
        onUserSubmit={handleUserSubmit}
        onSubscriptionSubmit={handleSubscriptionSubmit}
        onAccountSubmit={handleAccountSubmit}
      />
    </div>
  );
};

export default EditAccount;
