import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import "./SearchComments.css";
import { AuthContext } from "../../context/AuthContext";
import CommentsFormModal from "./CommentsFormModal";
import conf from "../../config/config";

const SearchComments = ({ setCommentsId }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [commentss, setCommentss] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSearch = async (page = 1) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/commentss/search`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: { name, description, page },
        },
      );
      setCommentss(response.data.commentss);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching comments:", error);
      }
    }
  };

  useEffect(() => {
    handleSearch(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEditClick = (commentsId) => {
    navigate("/setup/editcomments");
    setCommentsId(commentsId);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedCommentss = [...commentss].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCustomCommentsSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      if (response.data.error) {
        setErrorMessage("Comments creation Failed!" + response.data.error);
      } else {
        setSuccessMessage("Comments created successfully!");
      }
      setCommentss((prevCommentss) => {
        const updatedCommentss = [response.data, ...prevCommentss];
        console.log("Updated commentss:", updatedCommentss);
        return updatedCommentss;
      });
      closeModal();
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching comments:", error);
      }
    }
  };
  const getSignedUrl = async (key) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/docker/generate-signed-url`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: { key },
        },
      );
      return response.data.url;
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching test result URL:", error);
      }
    }
  };

  const handleClick = async (urlKey) => {
    try {
      if (!urlKey) {
        setErrorMessage("No URL");
        return;
      }

      const urls = urlKey.split(";");

      for (const url of urls) {
        try {
          if (url.startsWith("http:") || url.startsWith("https:")) {
            window.open(url, "_blank");
          } else {
            const signedUrl = await getSignedUrl(url);
            window.open(signedUrl, "_blank");
          }
        } catch (error) {
          console.error("Error handling URL click:", error);
        }
      }
    } catch (error) {
      console.error("Error handling URL click:", error);
    }
  };
  return (
    <div className="app-container">
      <div className="header">
        <h1>Comments Search</h1>
      </div>
      <div className="content-container">
        <div className="main-content">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          <div className="search-form">
            {" "}
            <input
              type="text"
              placeholder="Commented By"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />{" "}
            <input
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <button onClick={() => handleSearch(1)}>Search</button>
          </div>
          <div className="results">
            <h2>Results</h2>
            <table>
              <thead>
                <tr>
                  {" "}
                  <th onClick={() => handleSort("name")}>Commented By</th>
                  <th onClick={() => handleSort("description")}>Description</th>
                </tr>
              </thead>
              <tbody>
                {sortedCommentss.map((comments) => (
                  <tr key={comments.objid}>
                    <td>
                      <Link to={`/comments/${comments.objid}`}>
                        {comments.name || "Unnamed Comments"}
                      </Link>
                    </td>
                    <td>{comments.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>{" "}
        <div className="sidebar">
          <ul>
            <li>
              <a href="#" onClick={openModal}>
                Create Comments
              </a>
            </li>
            <li>
              <h4>Comments Dependency</h4>
            </li>

            <li>
              <a href="/setup/tasks">Search Tasks</a>
            </li>
            <li>
              <h5>Instruction:</h5>
            </li>
            <li>
              Create or search dependencies before you proceed to create
              Comments{" "}
            </li>
          </ul>
        </div>
      </div>
      <CommentsFormModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        comments={null} // Pass the project data if needed
        onSubmit={handleCustomCommentsSubmit}
      />
    </div>
  );
};

export default SearchComments;
