import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditRelease.css";
import TableRelease from "./TableRelease";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import TestCycleList from "../testcycle/TestCycleList";

const EditRelease = ({ releaseId: propReleaseId }) => {
  const [release, setRelease] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableRelease, setShowTableRelease] = useState(false);
  const [testcycles, setTestCycles] = useState([]);

  const { id } = useParams();
  const releaseId = id || propReleaseId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchRelease = async () => {
        if (!releaseId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/releases/searchById/${releaseId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setRelease(response.data);
            setEditLoad(true);
            console.log("Fetched release data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching release:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchRelease();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testcycle?query=${releaseId}&field=testcycle2release`,
        setTestCycles,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, releaseId]);

  const handleReleaseSubmit = (newRelease) => {
    navigate(`/release/${newRelease.objid}`);
  };

  const handleTestCycleSubmit = (newTestCycle) => {
    setTestCycles((testcycles) => [newTestCycle, ...testcycles]);
    setShowTableRelease(true);
  };
  return (
    <div className="edit-release-container">
      <div className="main-content">
        <h1>Edit Release</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, release)}
        {editLoad ? (
          <TableRelease
            data={release}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <TestCycleList testcycles={testcycles} />
      </div>
      <RightSidebar
        data={release}
        onTestCycleSubmit={handleTestCycleSubmit}
        onReleaseSubmit={handleReleaseSubmit}
      />
    </div>
  );
};

export default EditRelease;
