import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "firstname",
    label: "First Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "lastname",
    label: "Other/Last Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "loginemail",
    label: "Email As Login Name",
    type: "email",
    maxLength: 100,
    required: true,
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    maxLength: 200,
    required: true,
  },
  {
    name: "verifycode",
    label: "Verify Code",
    type: "text",
    maxLength: 20,
    required: false,
  },
  {
    name: "company",
    label: "Company Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "website",
    label: "Web Site(http:..)",
    type: "text",
    maxLength: 200,
    required: true,
  },
  {
    name: "street",
    label: "Street",
    type: "text",
    maxLength: 200,
    required: false,
  },
  {
    name: "city",
    label: "City",
    type: "text",
    maxLength: 100,
    required: false,
  },
  {
    name: "state",
    label: "State(Like CA,..)",
    type: "text",
    maxLength: 30,
    required: false,
  },
  {
    name: "zipcode",
    label: "Zip Code",
    type: "integer",
    maxLength: 10,
    required: false,
  },
  {
    name: "countrycode",
    label: "Country",
    type: "select",
    maxLength: 30,
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=CountryCode`,
  },
  {
    name: "phone",
    label: "Phone(xxx-xxx-xxxx)",
    type: "phone",
    maxLength: 20,
    required: true,
  },
  {
    name: "phone2",
    label: "Cell Phone",
    type: "phone",
    maxLength: 20,
    required: false,
  },
  { name: "fax", label: "Fax", type: "phone", maxLength: 20, required: false },
  {
    name: "reasoncode",
    label: "Type",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=ReasonCode`,
  },
  {
    name: "mediacode",
    label: "How Do You Know Us",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=MediaCode`,
  },
];
const ProfileFormContainer = ({
  propProfile,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propProfile?.objid || null,
    firstname: propProfile?.firstname || null,
    lastname: propProfile?.lastname || null,
    loginemail: propProfile?.loginemail || null,
    userid: propProfile?.userid || null,
    password: propProfile?.password || null,
    verifycode: propProfile?.verifycode || null,
    company: propProfile?.company || null,
    website: propProfile?.website || null,
    street: propProfile?.street || null,
    city: propProfile?.city || null,
    state: propProfile?.state || null,
    zipcode: propProfile?.zipcode || null,
    countrycode: propProfile?.countrycode || null,
    phone: propProfile?.phone || null,
    phone2: propProfile?.phone2 || null,
    fax: propProfile?.fax || null,
    reasoncode: propProfile?.reasoncode || null,
    mediacode: propProfile?.mediacode || null,
    poolindex: propProfile?.poolindex || null,
    customdata: propProfile?.customdata || null,
    groupuserid: propProfile?.x_groupuserid || user.x_groupuserid,
    createdbyid: propProfile?.createdbyid || user.id,
    createdby: propProfile?.createdby || user.fullname,
    createddate: propProfile?.createddate
      ? formatDate(propProfile.createddate)
      : today,
    modifiedby: propProfile?.modifiedby || user.fullname,
    modifieddate: propProfile?.modifieddate
      ? formatDate(propProfile.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Profile", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="profile"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default ProfileFormContainer;
