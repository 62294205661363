import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Tag Name",
    type: "text",
    maxLength: 100,
    required: true,
    fieldtype: "readonly",
  },
  {
    name: "runtag2testcycle",
    maxLength: 100,
    label: "TestCycle",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/testcycle`,
  },
  {
    name: "runtag2tag",
    maxLength: 100,
    label: "Tag",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/tag`,
    autopopulate: "name",
  },
];
const RunTagFormContainer = ({ propRunTag, onSubmit, onCancel, cloneData }) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propRunTag?.objid || null,
    name: propRunTag?.name || null,
    lastrun: propRunTag?.lastrun || null,
    runtag2testcycle: propRunTag?.runtag2testcycle,
    runtag2tag: propRunTag?.runtag2tag,
    customdata: propRunTag?.customdata || null,
    groupuserid: propRunTag?.x_groupuserid || user.x_groupuserid,
    createdbyid: propRunTag?.createdbyid || user.id,
    createdby: propRunTag?.createdby || user.fullname,
    createddate: propRunTag?.createddate
      ? formatDate(propRunTag.createddate)
      : today,
    modifiedby: propRunTag?.modifiedby || user.fullname,
    modifieddate: propRunTag?.modifieddate
      ? formatDate(propRunTag.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "RunTag", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="runtag"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default RunTagFormContainer;
