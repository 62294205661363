import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "giturl",
    label: "Git URL",
    type: "text",
    maxLength: 300,
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    maxLength: 1000,
    required: false,
  },
  {
    name: "executionbranch",
    label: "Execution Branch",
    type: "text",
    maxLength: 100,
    required: false,
  },
  {
    name: "runcommand",
    label: "Command To Run Test",
    type: "text",
    maxLength: 100,
    required: false,
  },
  {
    name: "gittoken",
    label: "Git Token",
    type: "password",
    maxLength: 200,
    required: false,
  },
  {
    name: "sshprivatekey",
    label: "SSH Private Key Path",
    type: "text",
    maxLength: 200,
    required: false,
  },
  {
    name: "sshpublickey",
    label: "SSH Public Key Path",
    type: "text",
    maxLength: 200,
    required: false,
  },
];
const GitRepoFormContainer = ({
  propGitRepo,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propGitRepo?.objid || null,
    name: propGitRepo?.name || null,
    giturl: propGitRepo?.giturl || null,
    description: propGitRepo?.description || null,
    executionbranch: propGitRepo?.executionbranch || null,
    runcommand: propGitRepo?.runcommand || null,
    gittoken: propGitRepo?.gittoken || null,
    sshprivatekey: propGitRepo?.sshprivatekey || null,
    sshpublickey: propGitRepo?.sshpublickey || null,
    customdata: propGitRepo?.customdata || null,
    groupuserid: propGitRepo?.x_groupuserid || user.x_groupuserid,
    createdbyid: propGitRepo?.createdbyid || user.id,
    createdby: propGitRepo?.createdby || user.fullname,
    createddate: propGitRepo?.createddate
      ? formatDate(propGitRepo.createddate)
      : today,
    modifiedby: propGitRepo?.modifiedby || user.fullname,
    modifieddate: propGitRepo?.modifieddate
      ? formatDate(propGitRepo.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "GitRepo", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="gitrepo"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default GitRepoFormContainer;
