import React from "react";
import Modal from "react-modal";
import UserGroupFormContainer from "./UserGroupFormContainer";
import "./UserGroupForm.css";
const UserGroupFormModal = ({
  isOpen,
  onRequestClose,
  usergroup,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="UserGroup Form"
    >
      <div className="modal-content">
        <UserGroupFormContainer
          propUserGroup={usergroup}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default UserGroupFormModal;
