import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import DockerImageFormModal from "./DockerImageFormModal";
import TestSuiteFormModal from "../testsuite/TestSuiteFormModal";

const RightSidebar = ({ data, onTestSuiteSubmit, onDockerImageSubmit }) => {
  const [isTestSuiteModalOpen, setIsTestSuiteModalOpen] = useState(false);
  const [isDockerImageModalOpen, setIsDockerImageModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    if (data.dockerimageurl) {
      setMessage(
        "We deleted your docker image record. You need to delete the image in AWS DCR cluster.",
      );
    }
    try {
      // Fetch associated testsuites
      const testsuiteResponse = await axios.get(
        `${conf.API_BASE_URL}/search/testsuite?query=${data.objid}&field=testsuite2dockerimage`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (testsuiteResponse.data.length > 0) {
        // If there are associated testsuites, set the message
        setMessage(
          "This dockerimage has associated testsuites and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/dockerimages/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("DockerImage Deleted:", response.data);
      navigate("/setup/dockerimage");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching dockerimage:", error);
      }
    }
  };

  const openTestSuiteModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestSuiteModalOpen(true);
  };

  const closeTestSuiteModal = () => {
    setIsTestSuiteModalOpen(false);
  };

  const handleCustomTestSuiteSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestSuiteModal();
      onTestSuiteSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testsuite:", error);
      }
    }
  };
  const openDockerImageModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDockerImageModalOpen(true);
  };

  const closeDockerImageModal = () => {
    setIsDockerImageModalOpen(false);
  };
  const handleCustomDockerImageSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeDockerImageModal();
      onDockerImageSubmit(response.data); // Pass the new dockerimage to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete DockerImage
          </a>
        </li>
        <li>
          <a href="#" onClick={openDockerImageModal}>
            Clone DockerImage
          </a>
        </li>
        <li>
          <a href="# " onClick={openTestSuiteModal}>
            Add Test Suite
          </a>
        </li>
      </ul>
      <TestSuiteFormModal
        isOpen={isTestSuiteModalOpen}
        onRequestClose={closeTestSuiteModal}
        testsuite={{ testsuite2dockerimage: data?.objid }} // Pass the testsuite data if needed
        onSubmit={handleCustomTestSuiteSubmit}
        cloneData={null}
      />
      <DockerImageFormModal
        isOpen={isDockerImageModalOpen}
        onRequestClose={closeDockerImageModal}
        dockerimage={null} // Pass the project data if needed
        onSubmit={handleCustomDockerImageSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
