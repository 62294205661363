// index.js
import Modal from "react-modal";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext"; // Adjust the import path as needed
import "./index.css"; // Import global CSS

// Set the app element for accessibility
Modal.setAppElement("#root"); // Replace '#root' with the ID of your app's root element

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
