import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditProduct.css";
import TableProduct from "./TableProduct";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import ProjectList from "../project/ProjectList";
import ReleaseList from "../release/ReleaseList";

const EditProduct = ({ productId: propProductId }) => {
  const [product, setProduct] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableProduct, setShowTableProduct] = useState(false);
  const [projects, setProjects] = useState([]);
  const [releases, setReleases] = useState([]);

  const { id } = useParams();
  const productId = id || propProductId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchProduct = async () => {
        if (!productId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/products/searchById/${productId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setProduct(response.data);
            setEditLoad(true);
            console.log("Fetched product data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching product:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchProduct();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/project?query=${productId}&field=project2product`,
        setProjects,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/release?query=${productId}&field=release2product`,
        setReleases,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, productId]);

  const handleProductSubmit = (newProduct) => {
    navigate(`/product/${newProduct.objid}`);
  };

  const handleProjectSubmit = (newProject) => {
    setProjects((projects) => [newProject, ...projects]);
    setShowTableProduct(true);
  };
  const handleReleaseSubmit = (newRelease) => {
    setReleases((releases) => [newRelease, ...releases]);
    setShowTableProduct(true);
  };
  return (
    <div className="edit-product-container">
      <div className="main-content">
        <h1>Edit Product</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, product)}
        {editLoad ? (
          <TableProduct
            data={product}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <ProjectList projects={projects} />
        <ReleaseList releases={releases} />
      </div>
      <RightSidebar
        data={product}
        onProjectSubmit={handleProjectSubmit}
        onReleaseSubmit={handleReleaseSubmit}
        onProductSubmit={handleProductSubmit}
      />
    </div>
  );
};

export default EditProduct;
