import React from "react";
import Modal from "react-modal";
import TestCycleFormContainer from "./TestCycleFormContainer";
import "./TestCycleForm.css";
const TestCycleFormModal = ({
  isOpen,
  onRequestClose,
  testcycle,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="TestCycle Form"
    >
      <div className="modal-content">
        <TestCycleFormContainer
          propTestCycle={testcycle}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default TestCycleFormModal;
