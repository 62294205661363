import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import ActionQueryFormModal from "./ActionQueryFormModal";

const RightSidebar = ({ data, onActionQuerySubmit }) => {
  const [isActionQueryModalOpen, setIsActionQueryModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      const response = await axios.delete(
        `${conf.API_BASE_URL}/actionquerys/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("ActionQuery Deleted:", response.data);
      navigate("/setup/actionquery");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching actionquery:", error);
      }
    }
  };

  const openActionQueryModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsActionQueryModalOpen(true);
  };

  const closeActionQueryModal = () => {
    setIsActionQueryModalOpen(false);
  };
  const handleCustomActionQuerySubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeActionQueryModal();
      onActionQuerySubmit(response.data); // Pass the new actionquery to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete ActionQuery
          </a>
        </li>
        <li>
          <a href="#" onClick={openActionQueryModal}>
            Clone ActionQuery
          </a>
        </li>
      </ul>
      <ActionQueryFormModal
        isOpen={isActionQueryModalOpen}
        onRequestClose={closeActionQueryModal}
        actionquery={null} // Pass the project data if needed
        onSubmit={handleCustomActionQuerySubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
