import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import TestCycleFormModal from "./TestCycleFormModal";
import DefectFormModal from "../defect/DefectFormModal";
import RunScheduleFormModal from "../runschedule/RunScheduleFormModal";
import RunTagFormModal from "../runtag/RunTagFormModal";
import TestReportFormModal from "../testreport/TestReportFormModal";

const RightSidebar = ({
  data,
  onDefectSubmit,
  onRunScheduleSubmit,
  onRunTagSubmit,
  onTestReportSubmit,
  onTestCycleSubmit,
}) => {
  const [isDefectModalOpen, setIsDefectModalOpen] = useState(false);
  const [isRunScheduleModalOpen, setIsRunScheduleModalOpen] = useState(false);
  const [isRunTagModalOpen, setIsRunTagModalOpen] = useState(false);
  const [isTestReportModalOpen, setIsTestReportModalOpen] = useState(false);
  const [isTestCycleModalOpen, setIsTestCycleModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated defects
      const defectResponse = await axios.get(
        `${conf.API_BASE_URL}/search/defect?query=${data.objid}&field=defect2testcycle`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (defectResponse.data.length > 0) {
        // If there are associated defects, set the message
        setMessage(
          "This testcycle has associated defects and cannot be deleted.",
        );
        return;
      }
      // Fetch associated runschedules
      const runscheduleResponse = await axios.get(
        `${conf.API_BASE_URL}/search/runschedule?query=${data.objid}&field=runschedule2testcycle`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (runscheduleResponse.data.length > 0) {
        // If there are associated runschedules, set the message
        setMessage(
          "This testcycle has associated runschedules and cannot be deleted.",
        );
        return;
      }
      // Fetch associated runtags
      const runtagResponse = await axios.get(
        `${conf.API_BASE_URL}/search/runtag?query=${data.objid}&field=runtag2testcycle`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (runtagResponse.data.length > 0) {
        // If there are associated runtags, set the message
        setMessage(
          "This testcycle has associated runtags and cannot be deleted.",
        );
        return;
      }
      // Fetch associated testreports
      const testreportResponse = await axios.get(
        `${conf.API_BASE_URL}/search/testreport?query=${data.objid}&field=testreport2testcycle`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (testreportResponse.data.length > 0) {
        // If there are associated testreports, set the message
        setMessage(
          "This testcycle has associated testreports and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/testcycles/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("TestCycle Deleted:", response.data);
      navigate("/setup/testcycle");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testcycle:", error);
      }
    }
  };

  const openDefectModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDefectModalOpen(true);
  };

  const closeDefectModal = () => {
    setIsDefectModalOpen(false);
  };

  const handleCustomDefectSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeDefectModal();
      onDefectSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching defect:", error);
      }
    }
  };

  const openRunScheduleModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsRunScheduleModalOpen(true);
  };

  const closeRunScheduleModal = () => {
    setIsRunScheduleModalOpen(false);
  };

  const handleCustomRunScheduleSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeRunScheduleModal();
      onRunScheduleSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching runschedule:", error);
      }
    }
  };

  const openRunTagModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsRunTagModalOpen(true);
  };

  const closeRunTagModal = () => {
    setIsRunTagModalOpen(false);
  };

  const handleCustomRunTagSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeRunTagModal();
      onRunTagSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching runtag:", error);
      }
    }
  };

  const openTestReportModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestReportModalOpen(true);
  };

  const closeTestReportModal = () => {
    setIsTestReportModalOpen(false);
  };

  const handleCustomTestReportSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestReportModal();
      onTestReportSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testreport:", error);
      }
    }
  };
  const openTestCycleModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestCycleModalOpen(true);
  };

  const closeTestCycleModal = () => {
    setIsTestCycleModalOpen(false);
  };
  const handleCustomTestCycleSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestCycleModal();
      onTestCycleSubmit(response.data); // Pass the new testcycle to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete TestCycle
          </a>
        </li>
        <li>
          <a href="#" onClick={openTestCycleModal}>
            Clone TestCycle
          </a>
        </li>
        <li>
          <a href="# " onClick={openDefectModal}>
            Add Defect
          </a>
        </li>
        <li>
          <a href="# " onClick={openRunScheduleModal}>
            Add Run Schedule
          </a>
        </li>
        <li>
          <a href="# " onClick={openRunTagModal}>
            Add RunTag
          </a>
        </li>
        <li>
          <a href="# " onClick={openTestReportModal}>
            Add TestReport
          </a>
        </li>
      </ul>
      <DefectFormModal
        isOpen={isDefectModalOpen}
        onRequestClose={closeDefectModal}
        defect={{ defect2testcycle: data?.objid }} // Pass the defect data if needed
        onSubmit={handleCustomDefectSubmit}
        cloneData={null}
      />
      <RunScheduleFormModal
        isOpen={isRunScheduleModalOpen}
        onRequestClose={closeRunScheduleModal}
        runschedule={{ runschedule2testcycle: data?.objid }} // Pass the runschedule data if needed
        onSubmit={handleCustomRunScheduleSubmit}
        cloneData={null}
      />
      <RunTagFormModal
        isOpen={isRunTagModalOpen}
        onRequestClose={closeRunTagModal}
        runtag={{ runtag2testcycle: data?.objid }} // Pass the runtag data if needed
        onSubmit={handleCustomRunTagSubmit}
        cloneData={null}
      />
      <TestReportFormModal
        isOpen={isTestReportModalOpen}
        onRequestClose={closeTestReportModal}
        testreport={{ testreport2testcycle: data?.objid }} // Pass the testreport data if needed
        onSubmit={handleCustomTestReportSubmit}
        cloneData={null}
      />
      <TestCycleFormModal
        isOpen={isTestCycleModalOpen}
        onRequestClose={closeTestCycleModal}
        testcycle={null} // Pass the project data if needed
        onSubmit={handleCustomTestCycleSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
