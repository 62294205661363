import React from "react";
import Modal from "react-modal";
import ActionQueryFormContainer from "./ActionQueryFormContainer";
import "./ActionQueryForm.css";
const ActionQueryFormModal = ({
  isOpen,
  onRequestClose,
  actionquery,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="ActionQuery Form"
    >
      <div className="modal-content">
        <ActionQueryFormContainer
          propActionQuery={actionquery}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default ActionQueryFormModal;
