import React from "react";
import Modal from "react-modal";
import SubTaskFormContainer from "./SubTaskFormContainer";
import "./SubTaskForm.css";
const SubTaskFormModal = ({
  isOpen,
  onRequestClose,
  subtask,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="SubTask Form"
    >
      <div className="modal-content">
        <SubTaskFormContainer
          propSubTask={subtask}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default SubTaskFormModal;
