import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditFeature.css";
import TableFeature from "./TableFeature";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import FeatureDocumentList from "../featuredocument/FeatureDocumentList";
import ScenarioList from "../scenario/ScenarioList";
import TasksList from "../tasks/TasksList";
import TestFailureList from "../testfailure/TestFailureList";
import TestSuiteList from "../testsuite/TestSuiteList";

const EditFeature = ({ featureId: propFeatureId }) => {
  const [feature, setFeature] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableFeature, setShowTableFeature] = useState(false);
  const [featuredocuments, setFeatureDocuments] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [taskss, setTaskss] = useState([]);
  const [testfailures, setTestFailures] = useState([]);
  const [testsuites, setTestSuites] = useState([]);

  const { id } = useParams();
  const featureId = id || propFeatureId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchFeature = async () => {
        if (!featureId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/features/searchById/${featureId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setFeature(response.data);
            setEditLoad(true);
            console.log("Fetched feature data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching feature:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchFeature();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/featuredocument?query=${featureId}&field=featuredocument2feature`,
        setFeatureDocuments,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/scenario?query=${featureId}&field=scenario2feature`,
        setScenarios,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/tasks?query=${featureId}&field=tasks2feature`,
        setTaskss,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testfailure?query=${featureId}&field=testfailure2feature`,
        setTestFailures,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/testsuite?query=${featureId}&field=testsuite2feature`,
        setTestSuites,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, featureId]);

  const handleFeatureSubmit = (newFeature) => {
    navigate(`/feature/${newFeature.objid}`);
  };

  const handleFeatureDocumentSubmit = (newFeatureDocument) => {
    setFeatureDocuments((featuredocuments) => [
      newFeatureDocument,
      ...featuredocuments,
    ]);
    setShowTableFeature(true);
  };
  const handleScenarioSubmit = (newScenario) => {
    setScenarios((scenarios) => [newScenario, ...scenarios]);
    setShowTableFeature(true);
  };
  const handleTasksSubmit = (newTasks) => {
    setTaskss((taskss) => [newTasks, ...taskss]);
    setShowTableFeature(true);
  };
  const handleTestFailureSubmit = (newTestFailure) => {
    setTestFailures((testfailures) => [newTestFailure, ...testfailures]);
    setShowTableFeature(true);
  };
  const handleTestSuiteSubmit = (newTestSuite) => {
    setTestSuites((testsuites) => [newTestSuite, ...testsuites]);
    setShowTableFeature(true);
  };
  return (
    <div className="edit-feature-container">
      <div className="main-content">
        <h1>Edit Feature</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, feature)}
        {editLoad ? (
          <TableFeature
            data={feature}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <FeatureDocumentList featuredocuments={featuredocuments} />
        <ScenarioList scenarios={scenarios} />
        <TasksList taskss={taskss} />
        <TestFailureList testfailures={testfailures} />
        <TestSuiteList testsuites={testsuites} />
      </div>
      <RightSidebar
        data={feature}
        onFeatureDocumentSubmit={handleFeatureDocumentSubmit}
        onScenarioSubmit={handleScenarioSubmit}
        onTasksSubmit={handleTasksSubmit}
        onTestFailureSubmit={handleTestFailureSubmit}
        onTestSuiteSubmit={handleTestSuiteSubmit}
        onFeatureSubmit={handleFeatureSubmit}
      />
    </div>
  );
};

export default EditFeature;
