import React from "react";
import Modal from "react-modal";
import AttrAccessFormContainer from "./AttrAccessFormContainer";
import "./AttrAccessForm.css";
const AttrAccessFormModal = ({
  isOpen,
  onRequestClose,
  attraccess,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="AttrAccess Form"
    >
      <div className="modal-content">
        <AttrAccessFormContainer
          propAttrAccess={attraccess}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default AttrAccessFormModal;
