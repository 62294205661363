import React from "react";
import Modal from "react-modal";
import GitRepoFormContainer from "./GitRepoFormContainer";
import "./GitRepoForm.css";
const GitRepoFormModal = ({
  isOpen,
  onRequestClose,
  gitrepo,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="GitRepo Form"
    >
      <div className="modal-content">
        <GitRepoFormContainer
          propGitRepo={gitrepo}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default GitRepoFormModal;
