export const AWS_customfields = [
  {
    name: "awsaccountid",
    label: "AWS Account Id",
    fieldtype: "text",
    maxlength: 50,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "awsregion",
    label: "Region",
    fieldtype: "text",
    maxlength: 50,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "access_key_id",
    label: "Access Key Id",
    fieldtype: "text",
    maxlength: 200,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "secret_access_key",
    label: "Secret Access Key",
    fieldtype: "text",
    maxlength: 250,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "bucket",
    label: "S3 Bucket",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "cluster",
    label: "AWS Container Cluster Name",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "subnets",
    label: "Subnet",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
  {
    name: "securitygroups",
    label: "Security Groups",
    fieldtype: "text",
    maxlength: 100,
    defaultvalue: "",
    isrequired: "true",
    iseditable: "true",
  },
];
