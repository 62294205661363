import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import TasksFormModal from "./TasksFormModal";
import CommentsFormModal from "../comments/CommentsFormModal";
import SubTaskFormModal from "../subtask/SubTaskFormModal";
import TaskDocumentFormModal from "../taskdocument/TaskDocumentFormModal";
import TimesheetFormModal from "../timesheet/TimesheetFormModal";

const RightSidebar = ({
  data,
  onCommentsSubmit,
  onSubTaskSubmit,
  onTaskDocumentSubmit,
  onTimesheetSubmit,
  onTasksSubmit,
}) => {
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [isSubTaskModalOpen, setIsSubTaskModalOpen] = useState(false);
  const [isTaskDocumentModalOpen, setIsTaskDocumentModalOpen] = useState(false);
  const [isTimesheetModalOpen, setIsTimesheetModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated commentss
      const commentsResponse = await axios.get(
        `${conf.API_BASE_URL}/search/comments?query=${data.objid}&field=comments2tasks`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (commentsResponse.data.length > 0) {
        // If there are associated commentss, set the message
        setMessage(
          "This tasks has associated commentss and cannot be deleted.",
        );
        return;
      }
      // Fetch associated subtasks
      const subtaskResponse = await axios.get(
        `${conf.API_BASE_URL}/search/subtask?query=${data.objid}&field=subtask2tasks`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (subtaskResponse.data.length > 0) {
        // If there are associated subtasks, set the message
        setMessage("This tasks has associated subtasks and cannot be deleted.");
        return;
      }
      // Fetch associated taskdocuments
      const taskdocumentResponse = await axios.get(
        `${conf.API_BASE_URL}/search/taskdocument?query=${data.objid}&field=taskdocument2tasks`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (taskdocumentResponse.data.length > 0) {
        // If there are associated taskdocuments, set the message
        setMessage(
          "This tasks has associated taskdocuments and cannot be deleted.",
        );
        return;
      }
      // Fetch associated timesheets
      const timesheetResponse = await axios.get(
        `${conf.API_BASE_URL}/search/timesheet?query=${data.objid}&field=timesheet2tasks`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (timesheetResponse.data.length > 0) {
        // If there are associated timesheets, set the message
        setMessage(
          "This tasks has associated timesheets and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/taskss/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Tasks Deleted:", response.data);
      navigate("/setup/tasks");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching tasks:", error);
      }
    }
  };

  const openCommentsModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsCommentsModalOpen(true);
  };

  const closeCommentsModal = () => {
    setIsCommentsModalOpen(false);
  };

  const handleCustomCommentsSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeCommentsModal();
      onCommentsSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching comments:", error);
      }
    }
  };

  const openSubTaskModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubTaskModalOpen(true);
  };

  const closeSubTaskModal = () => {
    setIsSubTaskModalOpen(false);
  };

  const handleCustomSubTaskSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeSubTaskModal();
      onSubTaskSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching subtask:", error);
      }
    }
  };

  const openTaskDocumentModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTaskDocumentModalOpen(true);
  };

  const closeTaskDocumentModal = () => {
    setIsTaskDocumentModalOpen(false);
  };

  const handleCustomTaskDocumentSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTaskDocumentModal();
      onTaskDocumentSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching taskdocument:", error);
      }
    }
  };

  const openTimesheetModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTimesheetModalOpen(true);
  };

  const closeTimesheetModal = () => {
    setIsTimesheetModalOpen(false);
  };

  const handleCustomTimesheetSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTimesheetModal();
      onTimesheetSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching timesheet:", error);
      }
    }
  };
  const openTasksModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTasksModalOpen(true);
  };

  const closeTasksModal = () => {
    setIsTasksModalOpen(false);
  };
  const handleCustomTasksSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTasksModal();
      onTasksSubmit(response.data); // Pass the new tasks to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Tasks
          </a>
        </li>
        <li>
          <a href="#" onClick={openTasksModal}>
            Clone Tasks
          </a>
        </li>
        <li>
          <a href="# " onClick={openCommentsModal}>
            Add Comments
          </a>
        </li>
        <li>
          <a href="# " onClick={openSubTaskModal}>
            Add SubTask
          </a>
        </li>
        <li>
          <a href="# " onClick={openTaskDocumentModal}>
            Add Document
          </a>
        </li>
        <li>
          <a href="# " onClick={openTimesheetModal}>
            Add Timesheet
          </a>
        </li>
      </ul>
      <CommentsFormModal
        isOpen={isCommentsModalOpen}
        onRequestClose={closeCommentsModal}
        comments={{ comments2tasks: data?.objid }} // Pass the comments data if needed
        onSubmit={handleCustomCommentsSubmit}
        cloneData={null}
      />
      <SubTaskFormModal
        isOpen={isSubTaskModalOpen}
        onRequestClose={closeSubTaskModal}
        subtask={{ subtask2tasks: data?.objid }} // Pass the subtask data if needed
        onSubmit={handleCustomSubTaskSubmit}
        cloneData={null}
      />
      <TaskDocumentFormModal
        isOpen={isTaskDocumentModalOpen}
        onRequestClose={closeTaskDocumentModal}
        taskdocument={{ taskdocument2tasks: data?.objid }} // Pass the taskdocument data if needed
        onSubmit={handleCustomTaskDocumentSubmit}
        cloneData={null}
      />
      <TimesheetFormModal
        isOpen={isTimesheetModalOpen}
        onRequestClose={closeTimesheetModal}
        timesheet={{ timesheet2tasks: data?.objid }} // Pass the timesheet data if needed
        onSubmit={handleCustomTimesheetSubmit}
        cloneData={null}
      />
      <TasksFormModal
        isOpen={isTasksModalOpen}
        onRequestClose={closeTasksModal}
        tasks={null} // Pass the project data if needed
        onSubmit={handleCustomTasksSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
