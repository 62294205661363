import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import TestReportFormModal from "./TestReportFormModal";
import TestFailureFormModal from "../testfailure/TestFailureFormModal";
import TestLogFormModal from "../testlog/TestLogFormModal";
import TestResultFormModal from "../testresult/TestResultFormModal";

const RightSidebar = ({
  data,
  onTestFailureSubmit,
  onTestLogSubmit,
  onTestResultSubmit,
  onTestReportSubmit,
}) => {
  const [isTestFailureModalOpen, setIsTestFailureModalOpen] = useState(false);
  const [isTestLogModalOpen, setIsTestLogModalOpen] = useState(false);
  const [isTestResultModalOpen, setIsTestResultModalOpen] = useState(false);
  const [isTestReportModalOpen, setIsTestReportModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated testfailures
      const testfailureResponse = await axios.get(
        `${conf.API_BASE_URL}/search/testfailure?query=${data.objid}&field=testfailure2testreport`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (testfailureResponse.data.length > 0) {
        // If there are associated testfailures, set the message
        setMessage(
          "This testreport has associated testfailures and cannot be deleted.",
        );
        return;
      }
      // Fetch associated testlogs
      const testlogResponse = await axios.get(
        `${conf.API_BASE_URL}/search/testlog?query=${data.objid}&field=testlog2testreport`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (testlogResponse.data.length > 0) {
        // If there are associated testlogs, set the message
        setMessage(
          "This testreport has associated testlogs and cannot be deleted.",
        );
        return;
      }
      // Fetch associated testresults
      const testresultResponse = await axios.get(
        `${conf.API_BASE_URL}/search/testresult?query=${data.objid}&field=testresult2testreport`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (testresultResponse.data.length > 0) {
        // If there are associated testresults, set the message
        setMessage(
          "This testreport has associated testresults and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/testreports/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("TestReport Deleted:", response.data);
      navigate("/setup/testreport");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testreport:", error);
      }
    }
  };

  const openTestFailureModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestFailureModalOpen(true);
  };

  const closeTestFailureModal = () => {
    setIsTestFailureModalOpen(false);
  };

  const handleCustomTestFailureSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestFailureModal();
      onTestFailureSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testfailure:", error);
      }
    }
  };

  const openTestLogModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestLogModalOpen(true);
  };

  const closeTestLogModal = () => {
    setIsTestLogModalOpen(false);
  };

  const handleCustomTestLogSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestLogModal();
      onTestLogSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testlog:", error);
      }
    }
  };

  const openTestResultModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestResultModalOpen(true);
  };

  const closeTestResultModal = () => {
    setIsTestResultModalOpen(false);
  };

  const handleCustomTestResultSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestResultModal();
      onTestResultSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testresult:", error);
      }
    }
  };
  const openTestReportModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTestReportModalOpen(true);
  };

  const closeTestReportModal = () => {
    setIsTestReportModalOpen(false);
  };
  const handleCustomTestReportSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTestReportModal();
      onTestReportSubmit(response.data); // Pass the new testreport to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete TestReport
          </a>
        </li>
        <li>
          <a href="#" onClick={openTestReportModal}>
            Clone TestReport
          </a>
        </li>
        <li>
          <a href="# " onClick={openTestFailureModal}>
            Add Test Failure
          </a>
        </li>
        <li>
          <a href="# " onClick={openTestLogModal}>
            Add Test Log
          </a>
        </li>
        <li>
          <a href="# " onClick={openTestResultModal}>
            Add TestResult
          </a>
        </li>
      </ul>
      <TestFailureFormModal
        isOpen={isTestFailureModalOpen}
        onRequestClose={closeTestFailureModal}
        testfailure={{ testfailure2testreport: data?.objid }} // Pass the testfailure data if needed
        onSubmit={handleCustomTestFailureSubmit}
        cloneData={null}
      />
      <TestLogFormModal
        isOpen={isTestLogModalOpen}
        onRequestClose={closeTestLogModal}
        testlog={{ testlog2testreport: data?.objid }} // Pass the testlog data if needed
        onSubmit={handleCustomTestLogSubmit}
        cloneData={null}
      />
      <TestResultFormModal
        isOpen={isTestResultModalOpen}
        onRequestClose={closeTestResultModal}
        testresult={{ testresult2testreport: data?.objid }} // Pass the testresult data if needed
        onSubmit={handleCustomTestResultSubmit}
        cloneData={null}
      />
      <TestReportFormModal
        isOpen={isTestReportModalOpen}
        onRequestClose={closeTestReportModal}
        testreport={null} // Pass the project data if needed
        onSubmit={handleCustomTestReportSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
