import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../config/config";
import axios from "axios";
import Select from "react-select";
import { AuthContext } from "../context/AuthContext";
import "./ListingPage.css"; // Import the CSS file

const BoardTasks = ({ inputId, inputValue }) => {
  const [tasks, setTasks] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [taskStatusCodes, setTaskStatusCodes] = useState([]);
  const [editingTask, setEditingTask] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [tempEditedValue, setTempEditedValue] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { user: authUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTasks();
    fetchTaskStatusCodes(); // Fetch task status codes when the component mounts
  }, [inputId, inputValue, currentPage]);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/search/getSprintTasks?${inputId}=${inputValue}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${authUser.token}`,
            x_account_id: `${authUser.x_account_id}`,
            x_groupuser_id: `${authUser.x_groupuserid}`,
            x_poolindex: `${authUser.x_poolindex}`,
          },
        }
      );
      setTasks(response.data.tasks);
      setTotalCount(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchAssignees = async (query) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/search/assignee?query=${query}`,
        {
          headers: {
            Authorization: `Bearer ${authUser.token}`,
            x_account_id: `${authUser.x_account_id}`,
            x_groupuser_id: `${authUser.x_groupuserid}`,
            x_poolindex: `${authUser.x_poolindex}`,
          },
        }
      );

      const responseData = response.data;
      let assigneeOptions;

      if (Array.isArray(responseData)) {
        assigneeOptions = responseData.map((assignee) => ({
          value: assignee.objid,
          label: assignee.name,
        }));
      } else {
        assigneeOptions = [
          {
            value: responseData.objid,
            label: responseData.name,
          },
        ];
      }

      setAssignees(assigneeOptions);
    } catch (error) {
      console.error("Error fetching assignees:", error);
    }
  };

  const fetchTaskStatusCodes = async () => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/search/getGenericcode?attributename=TaskStatusCode`,
        {
          headers: {
            Authorization: `Bearer ${authUser.token}`,
            x_account_id: `${authUser.x_account_id}`,
            x_groupuser_id: `${authUser.x_groupuserid}`,
            x_poolindex: `${authUser.x_poolindex}`,
          },
        }
      );
      const taskStatusCodeOptions = response.data.reduce(
        (acc, taskStatusCode) => {
          acc[taskStatusCode.codevalue] = taskStatusCode.name;
          return acc;
        },
        {}
      );
      setTaskStatusCodes(taskStatusCodeOptions);
    } catch (error) {
      console.error("Error fetching task status codes:", error);
    }
  };

  const handleEditClick = (task, field) => {
    setEditingTask({ ...task, field });
    setEditedValue(task[field]);
    setTempEditedValue(task[field]);
  };

  const handleSaveClick = async (task) => {
    try {
      const updatedTask = {
        ...task,
        [editingTask.field]: tempEditedValue,
      };
      if (editingTask.field === "tasks2assignee") {
        updatedTask.tasks2assignee = tempEditedValue;
      }
      delete updatedTask.assigneename; // Remove assigneename from the update object
      await axios.put(
        `${conf.API_BASE_URL}/taskss/update/${task.objid}`,
        updatedTask,
        {
          headers: {
            Authorization: `Bearer ${authUser.token}`,
            x_account_id: `${authUser.x_account_id}`,
            x_groupuser_id: `${authUser.x_groupuserid}`,
            x_poolindex: `${authUser.x_poolindex}`,
          },
        }
      );
      setEditingTask(null);
      fetchTasks();
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const handleAssigneeChange = (selectedOption) => {
    setTempEditedValue(selectedOption ? selectedOption.value : "");
  };

  const handleTaskStatusCodeChange = (e, setEditValue) => {
    setEditValue(e.target.value);
  };

  const handleInputChange = (inputValue) => {
    if (inputValue) {
      fetchAssignees(inputValue);
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedTasks = [...tasks].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(totalCount / 10);

  const renderSelectField = (
    field,
    editValue,
    handleChange,
    handleSaveClick,
    setEditValue,
    modifiedData,
    setModifiedData,
    handleSave,
    setEditField
  ) => (
    <>
      <select
        value={editValue}
        onChange={(e) => {
          console.log(`handleChange called with value: ${e.target.value}`);
          handleChange(e, setEditValue);
        }}
        name={field.key}
      >
        {Object.entries(field.options).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
    </>
  );

  return (
    <div>
      <h2>All Tasks</h2>
      {Array.isArray(sortedTasks) && sortedTasks.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort("name")}>Name</th>
                <th onClick={() => handleSort("taskstatuscode")}>
                  Task Status Code
                </th>
                <th onClick={() => handleSort("startdate")}>Start Date</th>
                <th onClick={() => handleSort("duedate")}>Due Date</th>
                <th onClick={() => handleSort("storypoint")}>Story Point</th>
                <th onClick={() => handleSort("estimatedhr")}>
                  Estimated Hours
                </th>
                <th>Assignee</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedTasks.map((task) => (
                <tr key={task.objid}>
                  <td>
                    <a href={`/tasks/${task.objid}`}>{task.name}</a>
                  </td>
                  <td>
                    {editingTask?.objid === task.objid &&
                    editingTask.field === "taskstatuscode" ? (
                      renderSelectField(
                        { key: "taskstatuscode", options: taskStatusCodes },
                        tempEditedValue,
                        handleTaskStatusCodeChange,
                        handleSaveClick,
                        setTempEditedValue,
                        null,
                        null,
                        null,
                        null
                      )
                    ) : (
                      <span
                        onClick={() => handleEditClick(task, "taskstatuscode")}
                      >
                        {task.taskstatuscode}
                      </span>
                    )}
                  </td>
                  <td>
                    {editingTask?.objid === task.objid &&
                    editingTask.field === "startdate" ? (
                      <input
                        type="date"
                        value={tempEditedValue}
                        onChange={(e) => setTempEditedValue(e.target.value)}
                      />
                    ) : (
                      <span onClick={() => handleEditClick(task, "startdate")}>
                        {task.startdate}
                      </span>
                    )}
                  </td>
                  <td>
                    {editingTask?.objid === task.objid &&
                    editingTask.field === "duedate" ? (
                      <input
                        type="date"
                        value={tempEditedValue}
                        onChange={(e) => setTempEditedValue(e.target.value)}
                      />
                    ) : (
                      <span onClick={() => handleEditClick(task, "duedate")}>
                        {task.duedate}
                      </span>
                    )}
                  </td>
                  <td>
                    {editingTask?.objid === task.objid &&
                    editingTask.field === "storypoint" ? (
                      <input
                        type="number"
                        value={tempEditedValue}
                        onChange={(e) => setTempEditedValue(e.target.value)}
                      />
                    ) : (
                      <span onClick={() => handleEditClick(task, "storypoint")}>
                        {task.storypoint}
                      </span>
                    )}
                  </td>
                  <td>
                    {editingTask?.objid === task.objid &&
                    editingTask.field === "estimatedhr" ? (
                      <input
                        type="number"
                        value={tempEditedValue}
                        onChange={(e) => setTempEditedValue(e.target.value)}
                      />
                    ) : (
                      <span
                        onClick={() => handleEditClick(task, "estimatedhr")}
                      >
                        {task.estimatedhr}
                      </span>
                    )}
                  </td>
                  <td>
                    {editingTask?.objid === task.objid &&
                    editingTask.field === "tasks2assignee" ? (
                      <Select
                        value={assignees.find(
                          (assignee) => assignee.value === tempEditedValue
                        )}
                        onInputChange={handleInputChange}
                        onChange={handleAssigneeChange}
                        options={assignees}
                        isClearable
                        isSearchable
                        filterOption={(option, inputValue) =>
                          option.label
                            .toLowerCase()
                            .includes(String(inputValue).toLowerCase())
                        }
                        menuPortalTarget={document.body} // Render the dropdown in a portal
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }} // Ensure the dropdown is above other elements
                      />
                    ) : (
                      <span
                        onClick={() => handleEditClick(task, "tasks2assignee")}
                      >
                        {task.assigneename}
                      </span>
                    )}
                  </td>
                  <td>
                    {editingTask?.objid === task.objid ? (
                      <button onClick={() => handleSaveClick(task)}>✔️</button>
                    ) : (
                      <span
                        onClick={() => handleEditClick(task, "tasks2assignee")}
                      >
                        ✏️
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      ) : (
        "No Tasks Found"
      )}
    </div>
  );
};

export default BoardTasks;
