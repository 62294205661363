import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  { name: "name", label: "Name", type: "text", maxLength: 100, required: true },
  {
    name: "lastname",
    label: "Last Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "loginname",
    label: "Login Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Status&table=User`,
  },
  {
    name: "deptcode",
    label: "Department",
    type: "select",
    maxLength: 30,
    required: false,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=DeptCode`,
  },
  {
    name: "userid",
    label: "Short Id",
    type: "text",
    maxLength: 30,
    required: false,
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    maxLength: 100,
    required: true,
  },
  {
    name: "user2usergroup",
    maxLength: 100,
    label: "UserGroup",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/usergroup`,
  },
  {
    name: "user2account",
    maxLength: 100,
    label: "Account",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/account`,
  },
];
const UserFormContainer = ({ propUser, onSubmit, onCancel, cloneData }) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propUser?.objid || null,
    name: propUser?.name || null,
    lastname: propUser?.lastname || null,
    loginname: propUser?.loginname || null,
    password: propUser?.password || null,
    verifycode: propUser?.verifycode || null,
    status: propUser?.status || null,
    usertype: propUser?.usertype || null,
    deptcode: propUser?.deptcode || null,
    userid: propUser?.userid || null,
    email: propUser?.email || null,
    poolindex: propUser?.poolindex || null,
    user2usergroup: propUser?.user2usergroup,
    user2account: propUser?.user2account,
    customdata: propUser?.customdata || null,
    groupuserid: propUser?.x_groupuserid || user.x_groupuserid,
    createdbyid: propUser?.createdbyid || user.id,
    createdby: propUser?.createdby || user.fullname,
    createddate: propUser?.createddate
      ? formatDate(propUser.createddate)
      : today,
    modifiedby: propUser?.modifiedby || user.fullname,
    modifieddate: propUser?.modifieddate
      ? formatDate(propUser.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "User", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="user"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default UserFormContainer;
