import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import CodeAttributeFormModal from "./CodeAttributeFormModal";
import GenericCodeFormModal from "../genericcode/GenericCodeFormModal";

const RightSidebar = ({ data, onGenericCodeSubmit, onCodeAttributeSubmit }) => {
  const [isGenericCodeModalOpen, setIsGenericCodeModalOpen] = useState(false);
  const [isCodeAttributeModalOpen, setIsCodeAttributeModalOpen] =
    useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated genericcodes
      const genericcodeResponse = await axios.get(
        `${conf.API_BASE_URL}/search/genericcode?query=${data.objid}&field=genericcode2codeattribute`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (genericcodeResponse.data.length > 0) {
        // If there are associated genericcodes, set the message
        setMessage(
          "This codeattribute has associated genericcodes and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/codeattributes/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("CodeAttribute Deleted:", response.data);
      navigate("/setup/codeattribute");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching codeattribute:", error);
      }
    }
  };

  const openGenericCodeModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsGenericCodeModalOpen(true);
  };

  const closeGenericCodeModal = () => {
    setIsGenericCodeModalOpen(false);
  };

  const handleCustomGenericCodeSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeGenericCodeModal();
      onGenericCodeSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching genericcode:", error);
      }
    }
  };
  const openCodeAttributeModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsCodeAttributeModalOpen(true);
  };

  const closeCodeAttributeModal = () => {
    setIsCodeAttributeModalOpen(false);
  };
  const handleCustomCodeAttributeSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeCodeAttributeModal();
      onCodeAttributeSubmit(response.data); // Pass the new codeattribute to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete CodeAttribute
          </a>
        </li>
        <li>
          <a href="#" onClick={openCodeAttributeModal}>
            Clone CodeAttribute
          </a>
        </li>
        <li>
          <a href="# " onClick={openGenericCodeModal}>
            Add GenericCode
          </a>
        </li>
      </ul>
      <GenericCodeFormModal
        isOpen={isGenericCodeModalOpen}
        onRequestClose={closeGenericCodeModal}
        genericcode={{ genericcode2codeattribute: data?.objid }} // Pass the genericcode data if needed
        onSubmit={handleCustomGenericCodeSubmit}
        cloneData={null}
      />
      <CodeAttributeFormModal
        isOpen={isCodeAttributeModalOpen}
        onRequestClose={closeCodeAttributeModal}
        codeattribute={null} // Pass the project data if needed
        onSubmit={handleCustomCodeAttributeSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
