import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import conf from "../config/config";
import "./TestSummary.css"; // Import the CSS file for styling

const TestSummary = () => {
  const [testResults, setTestResults] = useState([]);
  const [selectedEnv, setSelectedEnv] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "createddate",
    direction: "ascending",
  });
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchTestResults = async () => {
      try {
        const response = await axios.post(
          `${conf.API_BASE_URL}/dashboard/getTestResults`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          }
        );
        setTestResults(response.data.testResults);
        const uniqueEnvs = [
          ...new Set(response.data.testResults.map((result) => result.testenv)),
        ];
        setSelectedEnv(uniqueEnvs[0]);
      } catch (error) {
        console.error("Error fetching test results:", error);
      }
    };

    fetchTestResults();
  }, [user.token, user.x_account_id, user.x_groupuserid, user.x_poolindex]);

  const handleEnvChange = (event) => {
    setSelectedEnv(event.target.value);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const filteredResults = testResults.filter(
    (result) => result.testenv === selectedEnv
  );

  const sortedResults = [...filteredResults].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  return (
    <div className="dashboard-content">
      <h2>Test Summary</h2>
      <div>
        <label htmlFor="testenv-select">Select Environment: </label>
        <select
          id="testenv-select"
          value={selectedEnv}
          onChange={handleEnvChange}
        >
          {[...new Set(testResults.map((result) => result.testenv))].map(
            (env) => (
              <option key={env} value={env}>
                {env}
              </option>
            )
          )}
        </select>
      </div>
      <table className="test-summary-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("createddate")}>Created Date</th>
            <th onClick={() => handleSort("totaltest")}>Total Tests</th>
            <th onClick={() => handleSort("passed")}>Passed</th>
            <th onClick={() => handleSort("failed")}>Failed</th>
            <th onClick={() => handleSort("skipped")}>Skipped</th>
          </tr>
        </thead>
        <tbody>
          {sortedResults.map((result, index) => (
            <tr key={index}>
              <td>{result.createddate}</td>
              <td>{result.totaltest}</td>
              <td>{result.passed}</td>
              <td>{result.failed}</td>
              <td>{result.skipped}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TestSummary;
