import React from "react";
import Modal from "react-modal";
import TimesheetFormContainer from "./TimesheetFormContainer";
import "./TimesheetForm.css";
const TimesheetFormModal = ({
  isOpen,
  onRequestClose,
  timesheet,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Timesheet Form"
    >
      <div className="modal-content">
        <TimesheetFormContainer
          propTimesheet={timesheet}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default TimesheetFormModal;
