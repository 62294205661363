import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Custom Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "imagename",
    label: "Image Name (lower case)",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "imagetag",
    label: "Image Tag (lower case)",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "reponame",
    label: "AWS ECS Repository Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "dockerfile",
    label: "Docker File Content",
    type: "text",
    maxLength: 4000,
    required: true,
  },
  {
    name: "giturl",
    label: "Project Git URL",
    type: "text",
    maxLength: 200,
    required: true,
  },
  {
    name: "gittoken",
    label: "Git Token",
    type: "password",
    maxLength: 200,
    required: true,
  },
  {
    name: "imagetype",
    label: "Image Type",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=ImageType&table=DockerImage`,
  },
  {
    name: "dockerimage2cloudconfig",
    maxLength: 100,
    label: "CloudConfig",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/cloudconfig`,
  },
];
const DockerImageFormContainer = ({
  propDockerImage,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propDockerImage?.objid || null,
    name: propDockerImage?.name || null,
    imagename: propDockerImage?.imagename || null,
    imagetag: propDockerImage?.imagetag || null,
    reponame: propDockerImage?.reponame || null,
    dockerfile: propDockerImage?.dockerfile || null,
    giturl: propDockerImage?.giturl || null,
    gittoken: propDockerImage?.gittoken || null,
    imagetype: propDockerImage?.imagetype || null,
    status: propDockerImage?.status || null,
    dockerimageurl: propDockerImage?.dockerimageurl || null,
    dockerimage2cloudconfig: propDockerImage?.dockerimage2cloudconfig,
    customdata: propDockerImage?.customdata || null,
    groupuserid: propDockerImage?.x_groupuserid || user.x_groupuserid,
    createdbyid: propDockerImage?.createdbyid || user.id,
    createdby: propDockerImage?.createdby || user.fullname,
    createddate: propDockerImage?.createddate
      ? formatDate(propDockerImage.createddate)
      : today,
    modifiedby: propDockerImage?.modifiedby || user.fullname,
    modifieddate: propDockerImage?.modifieddate
      ? formatDate(propDockerImage.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "DockerImage", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="dockerimage"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default DockerImageFormContainer;
