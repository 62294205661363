import React from "react";
import Modal from "react-modal";
import StepsFormContainer from "./StepsFormContainer";
import "./StepsForm.css";
const StepsFormModal = ({
  isOpen,
  onRequestClose,
  steps,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Steps Form"
    >
      <div className="modal-content">
        <StepsFormContainer
          propSteps={steps}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default StepsFormModal;
