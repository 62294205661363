import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import ObjectRuleFormModal from "./ObjectRuleFormModal";
import ActionQueryFormModal from "../actionquery/ActionQueryFormModal";

const RightSidebar = ({ data, onActionQuerySubmit, onObjectRuleSubmit }) => {
  const [isActionQueryModalOpen, setIsActionQueryModalOpen] = useState(false);
  const [isObjectRuleModalOpen, setIsObjectRuleModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated actionquerys
      const actionqueryResponse = await axios.get(
        `${conf.API_BASE_URL}/search/actionquery?query=${data.objid}&field=actionquery2objectrule`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (actionqueryResponse.data.length > 0) {
        // If there are associated actionquerys, set the message
        setMessage(
          "This objectrule has associated actionquerys and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/objectrules/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("ObjectRule Deleted:", response.data);
      navigate("/setup/objectrule");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching objectrule:", error);
      }
    }
  };

  const openActionQueryModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsActionQueryModalOpen(true);
  };

  const closeActionQueryModal = () => {
    setIsActionQueryModalOpen(false);
  };

  const handleCustomActionQuerySubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeActionQueryModal();
      onActionQuerySubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching actionquery:", error);
      }
    }
  };
  const openObjectRuleModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsObjectRuleModalOpen(true);
  };

  const closeObjectRuleModal = () => {
    setIsObjectRuleModalOpen(false);
  };
  const handleCustomObjectRuleSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeObjectRuleModal();
      onObjectRuleSubmit(response.data); // Pass the new objectrule to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete ObjectRule
          </a>
        </li>
        <li>
          <a href="#" onClick={openObjectRuleModal}>
            Clone ObjectRule
          </a>
        </li>
        <li>
          <a href="# " onClick={openActionQueryModal}>
            Add ActionQuery
          </a>
        </li>
      </ul>
      <ActionQueryFormModal
        isOpen={isActionQueryModalOpen}
        onRequestClose={closeActionQueryModal}
        actionquery={{ actionquery2objectrule: data?.objid }} // Pass the actionquery data if needed
        onSubmit={handleCustomActionQuerySubmit}
        cloneData={null}
      />
      <ObjectRuleFormModal
        isOpen={isObjectRuleModalOpen}
        onRequestClose={closeObjectRuleModal}
        objectrule={null} // Pass the project data if needed
        onSubmit={handleCustomObjectRuleSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
