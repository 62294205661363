import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Table Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "title",
    label: "Title",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "value",
    label: "Privilege Value",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Value&table=ObjectAccess`,
  },
  {
    name: "type",
    label: "Privelege Type",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Type&table=ObjectAccess`,
  },
  {
    name: "isrecursive",
    label: "Is Recursive",
    type: "select",
    maxLength: 3,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=IsRecursive&table=ObjectAccess`,
  },
  {
    name: "objectaccess2usergroup",
    maxLength: 100,
    label: "UserGroup",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/usergroup`,
  },
  {
    name: "objectaccess2moduleobject",
    maxLength: 100,
    label: "ModuleObject",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/moduleobject`,
  },
];
const ObjectAccessFormContainer = ({
  propObjectAccess,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propObjectAccess?.objid || null,
    name: propObjectAccess?.name || null,
    title: propObjectAccess?.title || null,
    value: propObjectAccess?.value || null,
    type: propObjectAccess?.type || null,
    isrecursive: propObjectAccess?.isrecursive || null,
    objectaccess2usergroup: propObjectAccess?.objectaccess2usergroup,
    objectaccess2moduleobject: propObjectAccess?.objectaccess2moduleobject,
    customdata: propObjectAccess?.customdata || null,
    groupuserid: propObjectAccess?.x_groupuserid || user.x_groupuserid,
    createdbyid: propObjectAccess?.createdbyid || user.id,
    createdby: propObjectAccess?.createdby || user.fullname,
    createddate: propObjectAccess?.createddate
      ? formatDate(propObjectAccess.createddate)
      : today,
    modifiedby: propObjectAccess?.modifiedby || user.fullname,
    modifieddate: propObjectAccess?.modifieddate
      ? formatDate(propObjectAccess.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "ObjectAccess", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="objectaccess"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default ObjectAccessFormContainer;
