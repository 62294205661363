import React from "react";
import Modal from "react-modal";
import ObjectAccessFormContainer from "./ObjectAccessFormContainer";
import "./ObjectAccessForm.css";
const ObjectAccessFormModal = ({
  isOpen,
  onRequestClose,
  objectaccess,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="ObjectAccess Form"
    >
      <div className="modal-content">
        <ObjectAccessFormContainer
          propObjectAccess={objectaccess}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default ObjectAccessFormModal;
