import React from "react";
import Modal from "react-modal";
import SprintFormContainer from "./SprintFormContainer";
import "./SprintForm.css";
const SprintFormModal = ({
  isOpen,
  onRequestClose,
  sprint,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Sprint Form"
    >
      <div className="modal-content">
        <SprintFormContainer
          propSprint={sprint}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default SprintFormModal;
