import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditSubscription.css";
import TableSubscription from "./TableSubscription";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import BillingList from "../billing/BillingList";

const EditSubscription = ({ subscriptionId: propSubscriptionId }) => {
  const [subscription, setSubscription] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableSubscription, setShowTableSubscription] = useState(false);
  const [billings, setBillings] = useState([]);

  const { id } = useParams();
  const subscriptionId = id || propSubscriptionId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchSubscription = async () => {
        if (!subscriptionId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/subscriptions/searchById/${subscriptionId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setSubscription(response.data);
            setEditLoad(true);
            console.log("Fetched subscription data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching subscription:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchSubscription();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/billing?query=${subscriptionId}&field=billing2subscription`,
        setBillings,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, subscriptionId]);

  const handleSubscriptionSubmit = (newSubscription) => {
    navigate(`/subscription/${newSubscription.objid}`);
  };

  const handleBillingSubmit = (newBilling) => {
    setBillings((billings) => [newBilling, ...billings]);
    setShowTableSubscription(true);
  };
  return (
    <div className="edit-subscription-container">
      <div className="main-content">
        <h1>Edit Subscription</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, subscription)}
        {editLoad ? (
          <TableSubscription
            data={subscription}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <BillingList billings={billings} />
      </div>
      <RightSidebar
        data={subscription}
        onBillingSubmit={handleBillingSubmit}
        onSubscriptionSubmit={handleSubscriptionSubmit}
      />
    </div>
  );
};

export default EditSubscription;
