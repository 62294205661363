import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Login Name",
    type: "email",
    maxLength: 100,
    required: true,
  },
  {
    name: "subscribecode",
    label: "Subscription Type",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=SubscribeCode`,
  },
  {
    name: "enddate",
    label: "End Date",
    type: "date",
    maxLength: 10,
    required: false,
  },
  {
    name: "fullname",
    label: "Full Name(As in Credit Card)",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "cardno",
    label: "Credit Card Number",
    type: "integer",
    maxLength: 16,
    required: true,
  },
  {
    name: "monthcode",
    label: "Month of Expiry",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=MonthCode`,
  },
  {
    name: "yearcode",
    label: "Year of Expiry",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=YearCode`,
  },
  {
    name: "cardid",
    label: "3 Digit Verification No",
    type: "text",
    maxLength: 10,
    required: true,
  },
  {
    name: "cardtypecode",
    label: "Card Type",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getGenericcode?attributename=CardTypeCode`,
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    maxLength: 20,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=Status&table=Subscription`,
  },
  {
    name: "subscription2account",
    maxLength: 100,
    label: "Account",
    type: "select",
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/account`,
  },
];
const SubscriptionFormContainer = ({
  propSubscription,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propSubscription?.objid || null,
    name: propSubscription?.name || null,
    subscribecode: propSubscription?.subscribecode || null,
    startdate: propSubscription?.startdate || null,
    enddate: propSubscription?.enddate || "disable",
    fullname: propSubscription?.fullname || null,
    cardno: propSubscription?.cardno || null,
    monthcode: propSubscription?.monthcode || null,
    yearcode: propSubscription?.yearcode || null,
    cardid: propSubscription?.cardid || null,
    cardtypecode: propSubscription?.cardtypecode || null,
    status: propSubscription?.status || "disable",
    subscription2account: propSubscription?.subscription2account,
    customdata: propSubscription?.customdata || null,
    groupuserid: propSubscription?.x_groupuserid || user.x_groupuserid,
    createdbyid: propSubscription?.createdbyid || user.id,
    createdby: propSubscription?.createdby || user.fullname,
    createddate: propSubscription?.createddate
      ? formatDate(propSubscription.createddate)
      : today,
    modifiedby: propSubscription?.modifiedby || user.fullname,
    modifieddate: propSubscription?.modifieddate
      ? formatDate(propSubscription.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "Subscription", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="subscription"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default SubscriptionFormContainer;
