import React from "react";
import Modal from "react-modal";
import ProjectFormContainer from "./ProjectFormContainer";
import "./ProjectForm.css";
const ProjectFormModal = ({
  isOpen,
  onRequestClose,
  project,
  onSubmit,
  cloneData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Project Form"
    >
      <div className="modal-content">
        <ProjectFormContainer
          propProject={project}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          cloneData={cloneData}
        />
      </div>
    </Modal>
  );
};

export default ProjectFormModal;
