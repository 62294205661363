import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import ProjectFormModal from "./ProjectFormModal";
import BoardFormModal from "../board/BoardFormModal";
import EpicFormModal from "../epic/EpicFormModal";

const RightSidebar = ({
  data,
  onBoardSubmit,
  onEpicSubmit,
  onProjectSubmit,
}) => {
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [isEpicModalOpen, setIsEpicModalOpen] = useState(false);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated boards
      const boardResponse = await axios.get(
        `${conf.API_BASE_URL}/search/board?query=${data.objid}&field=board2project`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (boardResponse.data.length > 0) {
        // If there are associated boards, set the message
        setMessage("This project has associated boards and cannot be deleted.");
        return;
      }
      // Fetch associated epics
      const epicResponse = await axios.get(
        `${conf.API_BASE_URL}/search/epic?query=${data.objid}&field=epic2project`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (epicResponse.data.length > 0) {
        // If there are associated epics, set the message
        setMessage("This project has associated epics and cannot be deleted.");
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/projects/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Project Deleted:", response.data);
      navigate("/setup/project");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching project:", error);
      }
    }
  };

  const openBoardModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsBoardModalOpen(true);
  };

  const closeBoardModal = () => {
    setIsBoardModalOpen(false);
  };

  const handleCustomBoardSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeBoardModal();
      onBoardSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching board:", error);
      }
    }
  };

  const openEpicModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsEpicModalOpen(true);
  };

  const closeEpicModal = () => {
    setIsEpicModalOpen(false);
  };

  const handleCustomEpicSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeEpicModal();
      onEpicSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching epic:", error);
      }
    }
  };
  const openProjectModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsProjectModalOpen(true);
  };

  const closeProjectModal = () => {
    setIsProjectModalOpen(false);
  };
  const handleCustomProjectSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeProjectModal();
      onProjectSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Project
          </a>
        </li>
        <li>
          <a href="#" onClick={openProjectModal}>
            Clone Project
          </a>
        </li>
        <li>
          <a href="# " onClick={openBoardModal}>
            Add Board
          </a>
        </li>
        <li>
          <a href="# " onClick={openEpicModal}>
            Add Epic
          </a>
        </li>
      </ul>
      <BoardFormModal
        isOpen={isBoardModalOpen}
        onRequestClose={closeBoardModal}
        board={{ board2project: data?.objid }} // Pass the board data if needed
        onSubmit={handleCustomBoardSubmit}
        cloneData={null}
      />
      <EpicFormModal
        isOpen={isEpicModalOpen}
        onRequestClose={closeEpicModal}
        epic={{ epic2project: data?.objid }} // Pass the epic data if needed
        onSubmit={handleCustomEpicSubmit}
        cloneData={null}
      />
      <ProjectFormModal
        isOpen={isProjectModalOpen}
        onRequestClose={closeProjectModal}
        project={null} // Pass the project data if needed
        onSubmit={handleCustomProjectSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
