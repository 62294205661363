import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import config from "../config/config";
import "./Activation.css";
import { AuthContext } from "../context/AuthContext"; // Ensure this path is correct

const Activation = () => {
  const { id } = useParams();
  const location = useLocation();
  const { user, login, logout } = useContext(AuthContext);
  const [activationCode, setActivationCode] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const loginCalledRef = useRef(false);

  // Extract poolIndex from query parameters
  const queryParams = new URLSearchParams(location.search);
  const poolIndex = queryParams.get("poolIndex");

  useEffect(() => {
    const fetchToken = async () => {
      if (loginCalledRef.current) {
        return; // Don't call login again if it's already been called
      }

      loginCalledRef.current = true;

      try {
        if (user) {
          await logout(); // Log out the user
        }

        const response = await axios.post(`${config.AUTH_URL}/login`, {
          logintype: config.USER_TYPE_REGISTRATION,
        });
        login(response.data);
      } catch (error) {
        console.error("Error logging in:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchToken();
  }, [login, logout, user]);

  const handleActivation = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/activate/activate-account`,
        {
          id,
          activationCode,
          poolIndex, // Include poolIndex in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMessage("Account activated successfully.");
      navigate("/login");
    } catch (error) {
      console.error("Error activating account:", error);
      setMessage("Failed to activate account. Please try again.");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="activation-container">
      <form className="activation-form" onSubmit={handleActivation}>
        <h2>Account Activation</h2>
        <input
          type="text"
          value={activationCode}
          onChange={(e) => setActivationCode(e.target.value)}
          placeholder="Enter activation code"
          required
        />
        <button type="submit">Activate</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Activation;
