import React, { useState, useContext, useEffect } from "react";
import DynamicForm from "../../common/DynamicForm";
import { AuthContext } from "../../context/AuthContext";
import conf from "../../config/config";
import axios from "axios";
import "../../common/DynamicForm.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`; // Format as yyyy-mm-dd for input type="date"
};

const today = formatDate(new Date());

const fields = [
  {
    name: "name",
    label: "Field Name",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "tablename",
    label: "Table Name",
    type: "select",
    maxLength: 100,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=TableName&table=CustomFields`,
  },
  {
    name: "label",
    label: "Field Label",
    type: "text",
    maxLength: 100,
    required: true,
  },
  {
    name: "fieldtype",
    label: "Field Type",
    type: "select",
    maxLength: 30,
    required: true,
    fetchOptions: `${conf.API_BASE_URL}/search/getListproperty?name=FieldType&table=CustomFields`,
  },
  {
    name: "maxlength",
    label: "Max length",
    type: "number",
    maxLength: 16,
    required: true,
  },
  {
    name: "defaultvalue",
    label: "Default Value",
    type: "text",
    maxLength: 50,
    required: false,
  },
  {
    name: "isrequired",
    label: "Is Required",
    type: "checkbox",
    maxLength: 3,
    required: false,
  },
  {
    name: "iseditable",
    label: "Is Editable",
    type: "checkbox",
    maxLength: 3,
    required: false,
  },
];
const CustomFieldsFormContainer = ({
  propCustomFields,
  onSubmit,
  onCancel,
  cloneData,
}) => {
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    objid: propCustomFields?.objid || null,
    name: propCustomFields?.name || null,
    tablename: propCustomFields?.tablename || null,
    label: propCustomFields?.label || null,
    fieldtype: propCustomFields?.fieldtype || null,
    maxlength: propCustomFields?.maxlength || null,
    defaultvalue: propCustomFields?.defaultvalue || null,
    isrequired: propCustomFields?.isrequired || null,
    iseditable: propCustomFields?.iseditable || null,
    customdata: propCustomFields?.customdata || null,
    groupuserid: propCustomFields?.x_groupuserid || user.x_groupuserid,
    createdbyid: propCustomFields?.createdbyid || user.id,
    createdby: propCustomFields?.createdby || user.fullname,
    createddate: propCustomFields?.createddate
      ? formatDate(propCustomFields.createddate)
      : today,
    modifiedby: propCustomFields?.modifiedby || user.fullname,
    modifieddate: propCustomFields?.modifieddate
      ? formatDate(propCustomFields.modifieddate)
      : today,
  });

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await axios.get(
          `${conf.API_BASE_URL}/search/customfields`,
          {
            params: { query: "CustomFields", field: "tablename" },
            headers: {
              Authorization: `Bearer ${user.token}`,
              x_account_id: `${user.x_account_id}`,
              x_groupuser_id: `${user.x_groupuserid}`,
              x_poolindex: `${user.x_poolindex}`,
            },
          },
        );
        setCustomFields(response.data || []);
      } catch (error) {
        console.error("Error fetching custom fields:", error);
      }
    };

    fetchCustomFields();
  }, [user]);

  return (
    <div className="content">
      <DynamicForm
        tableName="customfields"
        fields={fields}
        customFields={customFields}
        formData={cloneData ? cloneData : formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default CustomFieldsFormContainer;
