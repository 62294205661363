import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import conf from "../../config/config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./EditUserGroup.css";
import TableUserGroup from "./TableUserGroup";
import RightSidebar from "./RightSidebar";
import { useNavigate } from "react-router-dom";
import UserList from "../user/UserList";
import ModuleList from "../module/ModuleList";
import ObjectAccessList from "../objectaccess/ObjectAccessList";

const EditUserGroup = ({ usergroupId: propUserGroupId }) => {
  const [usergroup, setUserGroup] = useState(null);
  const { user: authUser, logout } = useContext(AuthContext);
  const [editLoad, setEditLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTableUserGroup, setShowTableUserGroup] = useState(false);
  const [users, setUsers] = useState([]);
  const [modules, setModules] = useState([]);
  const [objectaccesss, setObjectAccesss] = useState([]);

  const { id } = useParams();
  const usergroupId = id || propUserGroupId;
  const navigate = useNavigate();
  // Memoize user to prevent unnecessary re-renders
  const memoizedUser = useMemo(() => authUser, [authUser]);
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      const fetchUserGroup = async () => {
        if (!usergroupId) return;
        try {
          const response = await axios.get(
            `${conf.API_BASE_URL}/usergroups/searchById/${usergroupId}`,
            {
              headers: {
                Authorization: `Bearer ${memoizedUser.token}`,
                x_account_id: `${memoizedUser.x_account_id}`,
                x_groupuser_id: `${memoizedUser.x_groupuserid}`,
                x_poolindex: `${memoizedUser.x_poolindex}`,
              },
            },
          );
          if (response.data) {
            setUserGroup(response.data);
            setEditLoad(true);
            console.log("Fetched usergroup data:", response.data);
            console.log("editLoad set to true");
          }
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error("Error fetching usergroup:", error);
          }
        }
      };
      const fetchRelatedData = async (url, setter) => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${memoizedUser.token}`,
              x_account_id: `${memoizedUser.x_account_id}`,
              x_groupuser_id: `${memoizedUser.x_groupuserid}`,
              x_poolindex: `${memoizedUser.x_poolindex}`,
            },
          });
          console.log("API response:", response.data);
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setter(data);
        } catch (error) {
          if (error.response && error.response.data.error === "Invalid token") {
            logout("Auth Token Expired. Please login again");
          } else {
            console.error(`Error fetching data from ${url}:`, error);
          }
        }
      };

      fetchUserGroup();
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/user?query=${usergroupId}&field=user2usergroup`,
        setUsers,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/module?query=${usergroupId}&field=module2usergroup`,
        setModules,
      );
      fetchRelatedData(
        `${conf.API_BASE_URL}/search/objectaccess?query=${usergroupId}&field=objectaccess2usergroup`,
        setObjectAccesss,
      );
      // Set the ref to true after the initial mount
      isMounted.current = true;
    }
  }, [memoizedUser, usergroupId]);

  const handleUserGroupSubmit = (newUserGroup) => {
    navigate(`/usergroup/${newUserGroup.objid}`);
  };

  const handleUserSubmit = (newUser) => {
    setUsers((users) => [newUser, ...users]);
    setShowTableUserGroup(true);
  };
  const handleModuleSubmit = (newModule) => {
    setModules((modules) => [newModule, ...modules]);
    setShowTableUserGroup(true);
  };
  const handleObjectAccessSubmit = (newObjectAccess) => {
    setObjectAccesss((objectaccesss) => [newObjectAccess, ...objectaccesss]);
    setShowTableUserGroup(true);
  };
  return (
    <div className="edit-usergroup-container">
      <div className="main-content">
        <h1>Edit UserGroup</h1>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}{" "}
        {errorMessage && <div className="error-message">{errorMessage}</div>}{" "}
        {console.log("Rendering condition:", editLoad, usergroup)}
        {editLoad ? (
          <TableUserGroup
            data={usergroup}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <p>Loading...</p>
        )}
        <UserList users={users} />
        <ModuleList modules={modules} />
        <ObjectAccessList objectaccesss={objectaccesss} />
      </div>
      <RightSidebar
        data={usergroup}
        onUserSubmit={handleUserSubmit}
        onModuleSubmit={handleModuleSubmit}
        onObjectAccessSubmit={handleObjectAccessSubmit}
        onUserGroupSubmit={handleUserGroupSubmit}
      />
    </div>
  );
};

export default EditUserGroup;
