import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import TimesheetFormModal from "./TimesheetFormModal";

const RightSidebar = ({ data, onTimesheetSubmit }) => {
  const [isTimesheetModalOpen, setIsTimesheetModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      const response = await axios.delete(
        `${conf.API_BASE_URL}/timesheets/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Timesheet Deleted:", response.data);
      navigate("/setup/timesheet");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching timesheet:", error);
      }
    }
  };

  const openTimesheetModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsTimesheetModalOpen(true);
  };

  const closeTimesheetModal = () => {
    setIsTimesheetModalOpen(false);
  };
  const handleCustomTimesheetSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeTimesheetModal();
      onTimesheetSubmit(response.data); // Pass the new timesheet to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Timesheet
          </a>
        </li>
        <li>
          <a href="#" onClick={openTimesheetModal}>
            Clone Timesheet
          </a>
        </li>
      </ul>
      <TimesheetFormModal
        isOpen={isTimesheetModalOpen}
        onRequestClose={closeTimesheetModal}
        timesheet={null} // Pass the project data if needed
        onSubmit={handleCustomTimesheetSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
