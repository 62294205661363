import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import "./SearchInstallLicense.css";
import { AuthContext } from "../../context/AuthContext";
import InstallLicenseFormModal from "./InstallLicenseFormModal";
import conf from "../../config/config";

const SearchInstallLicense = ({ setInstallLicenseId }) => {
  const [name, setName] = useState("");
  const [serverip, setServerIp] = useState("");
  const [licensekey, setLicenseKey] = useState("");
  const [liccountkey, setLicCountKey] = useState("");
  const [licensecount, setLicenseCount] = useState("");
  const [wbskey, setWbsKey] = useState("");
  const [wbsno, setWbsNo] = useState("");
  const [expirykey, setExpiryKey] = useState("");
  const [expirydate, setExpiryDate] = useState("");
  const [installlicenses, setInstallLicenses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSearch = async (page = 1) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/installlicenses/search`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: {
            name,
            serverip,
            licensekey,
            liccountkey,
            licensecount,
            wbskey,
            wbsno,
            expirykey,
            expirydate,
            page,
          },
        },
      );
      setInstallLicenses(response.data.installlicenses);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching installlicense:", error);
      }
    }
  };

  useEffect(() => {
    handleSearch(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEditClick = (installlicenseId) => {
    navigate("/setup/editinstalllicense");
    setInstallLicenseId(installlicenseId);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedInstallLicenses = [...installlicenses].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCustomInstallLicenseSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      if (response.data.error) {
        setErrorMessage(
          "InstallLicense creation Failed!" + response.data.error,
        );
      } else {
        setSuccessMessage("InstallLicense created successfully!");
      }
      setInstallLicenses((prevInstallLicenses) => {
        const updatedInstallLicenses = [response.data, ...prevInstallLicenses];
        console.log("Updated installlicenses:", updatedInstallLicenses);
        return updatedInstallLicenses;
      });
      closeModal();
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching installlicense:", error);
      }
    }
  };
  const getSignedUrl = async (key) => {
    try {
      const response = await axios.get(
        `${conf.API_BASE_URL}/docker/generate-signed-url`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
          params: { key },
        },
      );
      return response.data.url;
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching test result URL:", error);
      }
    }
  };

  const handleClick = async (urlKey) => {
    try {
      if (!urlKey) {
        setErrorMessage("No URL");
        return;
      }

      const urls = urlKey.split(";");

      for (const url of urls) {
        try {
          if (url.startsWith("http:") || url.startsWith("https:")) {
            window.open(url, "_blank");
          } else {
            const signedUrl = await getSignedUrl(url);
            window.open(signedUrl, "_blank");
          }
        } catch (error) {
          console.error("Error handling URL click:", error);
        }
      }
    } catch (error) {
      console.error("Error handling URL click:", error);
    }
  };
  return (
    <div className="app-container">
      <div className="header">
        <h1>InstallLicense Search</h1>
      </div>
      <div className="content-container">
        <div className="main-content">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          <div className="search-form">
            {" "}
            <input
              type="text"
              placeholder="Account Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />{" "}
            <input
              type="text"
              placeholder="Server IP"
              value={serverip}
              onChange={(e) => setServerIp(e.target.value)}
            />{" "}
            <input
              type="text"
              placeholder="License Key Supplied"
              value={licensekey}
              onChange={(e) => setLicenseKey(e.target.value)}
            />{" "}
            <input
              type="text"
              placeholder="License Count Key"
              value={liccountkey}
              onChange={(e) => setLicCountKey(e.target.value)}
            />{" "}
            <input
              type="number"
              placeholder="License Count"
              value={licensecount}
              onChange={(e) => setLicenseCount(e.target.value)}
              className="input-field"
            />{" "}
            <input
              type="text"
              placeholder="WBS Key"
              value={wbskey}
              onChange={(e) => setWbsKey(e.target.value)}
            />{" "}
            <input
              type="number"
              placeholder="WBS Count"
              value={wbsno}
              onChange={(e) => setWbsNo(e.target.value)}
              className="input-field"
            />{" "}
            <input
              type="text"
              placeholder="Expiry Key"
              value={expirykey}
              onChange={(e) => setExpiryKey(e.target.value)}
            />{" "}
            <DatePicker
              selected={expirydate}
              onChange={(date) => setExpiryDate(date)}
              placeholderText="Expiry Days"
              dateFormat="MM/dd/yyyy"
              className="input-field"
            />
            <button onClick={() => handleSearch(1)}>Search</button>
          </div>
          <div className="results">
            <h2>Results</h2>
            <table>
              <thead>
                <tr>
                  {" "}
                  <th onClick={() => handleSort("name")}>Account Name</th>
                  <th onClick={() => handleSort("serverip")}>Server IP</th>
                  <th onClick={() => handleSort("licensekey")}>
                    License Key Supplied
                  </th>
                  <th onClick={() => handleSort("liccountkey")}>
                    License Count Key
                  </th>
                  <th onClick={() => handleSort("licensecount")}>
                    License Count
                  </th>
                  <th onClick={() => handleSort("wbskey")}>WBS Key</th>
                  <th onClick={() => handleSort("wbsno")}>WBS Count</th>
                  <th onClick={() => handleSort("expirykey")}>Expiry Key</th>
                  <th onClick={() => handleSort("expirydate")}>Expiry Days</th>
                </tr>
              </thead>
              <tbody>
                {sortedInstallLicenses.map((installlicense) => (
                  <tr key={installlicense.objid}>
                    <td>
                      <Link to={`/installlicense/${installlicense.objid}`}>
                        {installlicense.name || "Unnamed InstallLicense"}
                      </Link>
                    </td>
                    <td>{installlicense.serverip}</td>
                    <td>{installlicense.licensekey}</td>
                    <td>{installlicense.liccountkey}</td>
                    <td>{installlicense.licensecount}</td>
                    <td>{installlicense.wbskey}</td>
                    <td>{installlicense.wbsno}</td>
                    <td>{installlicense.expirykey}</td>
                    <td>
                      {new Date(installlicense.createddate).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>{" "}
        <div className="sidebar">
          <ul>
            <li>
              <a href="#" onClick={openModal}>
                Create InstallLicense
              </a>
            </li>
          </ul>
        </div>
      </div>
      <InstallLicenseFormModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        installlicense={null} // Pass the project data if needed
        onSubmit={handleCustomInstallLicenseSubmit}
      />
    </div>
  );
};

export default SearchInstallLicense;
