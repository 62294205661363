import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "../../config/config";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "../../common/RightSidebar.css";
import AccountFormModal from "./AccountFormModal";
import UserFormModal from "../user/UserFormModal";
import SubscriptionFormModal from "../subscription/SubscriptionFormModal";

const RightSidebar = ({
  data,
  onUserSubmit,
  onSubscriptionSubmit,
  onAccountSubmit,
}) => {
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDelete = async (data) => {
    if (
      user.my_groupuserid !== user.x_groupuserid &&
      user.fullname !== data.createdby
    ) {
      setMessage(
        "You are not authorized to delete this record created by another person.",
      );
      return;
    }
    try {
      // Fetch associated users
      const userResponse = await axios.get(
        `${conf.API_BASE_URL}/search/user?query=${data.objid}&field=user2account`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (userResponse.data.length > 0) {
        // If there are associated users, set the message
        setMessage("This account has associated users and cannot be deleted.");
        return;
      }
      // Fetch associated subscriptions
      const subscriptionResponse = await axios.get(
        `${conf.API_BASE_URL}/search/subscription?query=${data.objid}&field=subscription2account`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );

      if (subscriptionResponse.data.length > 0) {
        // If there are associated subscriptions, set the message
        setMessage(
          "This account has associated subscriptions and cannot be deleted.",
        );
        return;
      }
      const response = await axios.delete(
        `${conf.API_BASE_URL}/accounts/delete/${data.objid}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            x_account_id: `${user.x_account_id}`,
            x_groupuser_id: `${user.x_groupuserid}`,
            x_poolindex: `${user.x_poolindex}`,
          },
        },
      );
      console.log("Account Deleted:", response.data);
      navigate("/setup/account");
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching account:", error);
      }
    }
  };

  const openUserModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsUserModalOpen(true);
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
  };

  const handleCustomUserSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeUserModal();
      onUserSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching user:", error);
      }
    }
  };

  const openSubscriptionModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubscriptionModalOpen(true);
  };

  const closeSubscriptionModal = () => {
    setIsSubscriptionModalOpen(false);
  };

  const handleCustomSubscriptionSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeSubscriptionModal();
      onSubscriptionSubmit(response.data); // Pass the new project to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching subscription:", error);
      }
    }
  };
  const openAccountModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsAccountModalOpen(true);
  };

  const closeAccountModal = () => {
    setIsAccountModalOpen(false);
  };
  const handleCustomAccountSubmit = async (response) => {
    try {
      console.log("Response data:", response.data);
      closeAccountModal();
      onAccountSubmit(response.data); // Pass the new account to the parent component
    } catch (error) {
      if (error.response && error.response.data.error === "Invalid token") {
        logout("Auth Token Expired. Please login again");
      } else {
        console.error("Error fetching testrun:", error);
      }
    }
  };
  return (
    <div className="right-sidebar">
      {message && (
        <div className="message" style={{ color: "red" }}>
          {message}
        </div>
      )}
      <ul>
        <li>
          <a href="#" onClick={() => handleDelete(data)}>
            Delete Account
          </a>
        </li>
        <li>
          <a href="#" onClick={openAccountModal}>
            Clone Account
          </a>
        </li>
        <li>
          <a href="# " onClick={openUserModal}>
            Add App User
          </a>
        </li>
        <li>
          <a href="# " onClick={openSubscriptionModal}>
            Add Subscription
          </a>
        </li>
      </ul>
      <UserFormModal
        isOpen={isUserModalOpen}
        onRequestClose={closeUserModal}
        user={{ user2account: data?.objid }} // Pass the user data if needed
        onSubmit={handleCustomUserSubmit}
        cloneData={null}
      />
      <SubscriptionFormModal
        isOpen={isSubscriptionModalOpen}
        onRequestClose={closeSubscriptionModal}
        subscription={{ subscription2account: data?.objid }} // Pass the subscription data if needed
        onSubmit={handleCustomSubscriptionSubmit}
        cloneData={null}
      />
      <AccountFormModal
        isOpen={isAccountModalOpen}
        onRequestClose={closeAccountModal}
        account={null} // Pass the project data if needed
        onSubmit={handleCustomAccountSubmit}
        cloneData={data}
      />
    </div>
  );
};

export default RightSidebar;
